import ListUsers from "../../../API/Cognito/ListUsers";
import ListUsersInGroup from "../../../API/Cognito/ListUsersInGroup";
import { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../.././../libs/context/authenticatedLib";
import PortalUsersTable from "../../../components/tables/PortalUsersTable";
import UserEditForm from "../../../components/forms/UserEditForm"
import "./CreateUser.css"

export default function ManageUsers() {
  const { isAuthenticated } = useAuthContext()
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState("");
  const [updatedUser, setUpdatedUser] = useState(false);
  const [inEdit, setInEdit] = useState(false)
  const initialFormState = { id: null, Email: "", NtigExec: "", NtigAdmin: "" };
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const refUsers = useRef();
  const refUsersNtigExec = useRef();
  const refUsersAdmin = useRef();
  const refUsersNtigGroups = useRef();

  useEffect(() => {
    async function getUsers() {

      refUsers.current = await ListUsers();

      refUsersNtigExec.current = await ListUsersInGroup("ntig-exec-users");

      refUsersAdmin.current = await ListUsersInGroup("ntig-admin-users");

      refUsersNtigGroups.current = await ListUsersInGroup("ntig-groups-users");

      let userData = [];

      let arrUsersNtigExec = [];
      for (let a in refUsersNtigExec.current) {
        arrUsersNtigExec.push(refUsersNtigExec.current[a].Username);
      }

      let arrUsersAdmin = [];
      for (let b in refUsersAdmin.current) {
        arrUsersAdmin.push(refUsersAdmin.current[b].Username);
      }

      let arrUsersNtigGroups = [];
      for (let c in refUsersNtigGroups.current) {
        arrUsersNtigGroups.push(refUsersNtigGroups.current[c].Username);
      }

      for (let i in refUsers.current) {
        let email = null;
        for (let x in refUsers.current[i].Attributes) {
          if (refUsers.current[i].Attributes[x].Name === "email") {
            email = refUsers.current[i].Attributes[x].Value;
            break;
          }
        }
        let memberNtigExec = arrUsersNtigExec.includes(refUsers.current[i].Username);
        let memberAdmin = arrUsersAdmin.includes(refUsers.current[i].Username);
        let memberNtigGroups = arrUsersNtigGroups.includes(refUsers.current[i].Username);

        userData.push({
          id: i,
          Username: refUsers.current[i].Username,
          AccountStatus: refUsers.current[i].UserStatus,
          Email: email,
          NtigUsers: memberNtigGroups,
          NtigExec: memberNtigExec,
          NtigAdmin: memberAdmin,

        });
      }
      setUserDetails(userData);
      setUpdatedUser(false)
      setIsLoading(false)
    }
    if (isAuthenticated) {
      getUsers();
    }
  }, [isAuthenticated, updatedUser]);

  const reviewRow = (user) => {
    setInEdit(true);
    setCurrentUser({ id: user.id, Email: user.Email, AccountStatus: user.AccountStatus, NtigExec: user.NtigExec, NtigAdmin: user.NtigAdmin });
  };

  const reviewUser = (user) => {
    setInEdit(false)
    setUpdatedUser(true)
  }

  const deletedUser = (id => {
    setUserDetails(userDetails.filter((users) => users.id !== id))
  })

  function render() {
    if (inEdit !== true) {
      return (
        <>
          <h2>Manage Portal Users</h2>
          <PortalUsersTable userDetails={userDetails} reviewRow={reviewRow} isLoading={isLoading} />
        </>
      )
    } else {
      return (
        <>
          <div className="NewUser">
            <UserEditForm setInEdit={setInEdit} currentUser={currentUser} reviewUser={reviewUser} deletedUser={deletedUser} />
          </div>
        </>

      )
    }
  }
  return render()
}