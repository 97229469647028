import API, { graphqlOperation } from "@aws-amplify/api"
import * as mutations from "../../../graphql/mutations"

export default async function notifyTres(tresNotifyJson) {
  let response
  await API.graphql(graphqlOperation(mutations.tresNotify, { input: tresNotifyJson }))
    .then((data) => {
      response = true
    })
    .catch((error) => {
      response = error
    });
  return response
};
