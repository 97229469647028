import "./GroupsMgtTable.css";
import { useUserContext } from "../../libs/context/userLib";
import { useGroupsContext } from "../../libs/context/groupsLib";
import Button from 'react-bootstrap/Button'


function GroupsMgtTable(props) {
  const isLoading = props.isLoading
  const { userContext } = useUserContext();
  const { groupsContext } = useGroupsContext()
  let tableItems

    if (props.container === "master") {
      tableItems = []

      const groupKeys = groupsContext.GroupKeys
      groupKeys.sort()
      //groupKeys.sort((a, b) => a.Name > b.Name ? 1 : -1)

      for (let i in groupKeys) {
        let groupId = groupKeys[i]
        let group = groupsContext.FullGroups[groupKeys[i]].Name
        let status = groupsContext.FullGroups[groupKeys[i]].Status
  
        let  date = "never"
        let  submitterName = ""
        let  submitterEmail = ""
        let  secretaryName = "-none-"
        let  secretaryEmail = "-none-"
        let  secretaryPhone = "-none-"
        let  treasurerName = "-none-"
        let  treasurerEmail = "-none-"
        let  treasurerPhone = "-none-"
        let  gsrName = "-none-"
        let  gsrEmail = "-none-"
        let  gsrPhone = "-none-"
        
        for (let x in props.contacts) {
          switch(props.contacts[x].Id) {
            case groupId:
                date= props.contacts[x].Date
                submitterName= props.contacts[x].SubmitterName
                submitterEmail= props.contacts[x].SubmitterEmail
                secretaryName= props.contacts[x].SecretaryName
                secretaryEmail= props.contacts[x].SecretaryEmail
                secretaryPhone= props.contacts[x].SecretaryPhone
                treasurerName= props.contacts[x].TreasurerName
                treasurerEmail= props.contacts[x].TreasurerEmail
                treasurerPhone= props.contacts[x].TreasurerPhone
                gsrName= props.contacts[x].GSRName
                gsrEmail= props.contacts[x].GSREmail
                gsrPhone= props.contacts[x].GSRPhone
              break
  
            default:
              break
          }
        }
        tableItems.push({
          Id: groupId,
          Group: group,
          Status: status,
          Date: date,
          SubmitterName: submitterName,
          SubmitterEmail: submitterEmail,
          SecretaryName: secretaryName,
          SecretaryEmail: secretaryEmail,
          SecretaryPhone: secretaryPhone,
          TreasurerName: treasurerName,
          TreasurerEmail: treasurerEmail,
          TreasurerPhone: treasurerPhone,
          GSRName: gsrName,
          GSREmail: gsrEmail,
          GSRPhone: gsrPhone
        })
      }

    } else {
      tableItems = props.contacts
    }

  return (
    isLoading ? (
        <div>Loading data.....</div>
    ) : (   
      <table>
      <thead>
        <tr>
          {/* <th>Key</th> */}
          <th>Group</th>
          <th>Status</th>
          <th>Submitted by</th>
          <th>Secretary</th>
          <th>Treasurer</th>
          <th>GSR</th>
        </tr>
      </thead>
      <tbody>
        {props.contacts.length > 0 ? (
          tableItems.map((groupData) => (
            <tr key={groupData.Id}>
              {/* <td>
                {groupData.Id}
              </td> */}
              <td >
                  <Button variant="link" size="sm" onClick={() => {
                    props.reviewRow(groupData);
                  }} disabled={userContext.userIsAdmin !== true}>{groupData.Group}</Button>
                <br />
                Last updated: {groupData.Date}
              </td>
              <td>
                {groupsContext.FullGroups[groupData.Id].Status}
              </td>
              <td>
                {groupData.SubmitterName}
                <br />
                {groupData.SubmitterEmail}
              </td>
              <td>
                {groupData.SecretaryName}
                <br />
                {groupData.SecretaryEmail}
                <br />
                {groupData.SecretaryPhone}
                <br />
              </td>
              <td>
                {groupData.TreasurerName}
                <br />
                {groupData.TreasurerEmail}
                <br />
                {groupData.TreasurerPhone}
                <br />
              </td>
              <td>
                {groupData.GSRName}
                <br />
                {groupData.GSREmail}
                <br />
                {groupData.GSRPhone}
                <br />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5}>There aren't currently group contacts updates to review</td>
          </tr>
        )}
      </tbody>
        </table>
  ));
}
export default GroupsMgtTable;
