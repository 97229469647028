import React from "react";
import DonationsTable from "../tables/DonationsTable";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row'
import InputGroup from "react-bootstrap/InputGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./forms.css"
import ColouredLine from "../shapes/ColouredLine"
import LoaderButton from "../buttons/LoaderButton";
import { useGroupsContext } from "../../libs/context/groupsLib";

export default function EditIncomeForm(props) {
  const { groupsContext } = useGroupsContext()
  var donationList = []
  donationList = Array.from(Object.values(groupsContext.ActiveGroups))
  donationList.sort()
  donationList = donationList.filter(group => group !== "-select your group-")
  donationList.unshift("Other - Please state")
  donationList.unshift("-select income type-")


  var groupsKeys = groupsContext.ActiveKeys
  groupsKeys["-select income type-"] = "00"
  groupsKeys["Other - Please state"] = "-1"

  const amountRegex = /^\d+(\.\d{1,2})?$/;
  const donationDateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
  //"/(0?[1-9]|[12]\d|30|31)[^\w\d\r\n:](0?[1-9]|1[0-2])[^\w\d\r\n:]\d{4}/"


  const validationSchema = Yup.object().shape({
    group: Yup.string().test("groupTest", "You must select a type", (group) => group !== "-select income type-"),
    donation: Yup.string().matches(amountRegex, "Enter a valid amount"),
    donationDate: Yup.string().matches(donationDateRegex, "Format: dd/mm/yyyy"),
    other: Yup.string().min(2, "Minimum two characters").max(30, "Maximum thirty characters"),
  });

  const formik = useFormik({
    initialValues: {
      group: "",
      other: "",
      donation: "",
      donationDate: ""
    },

    validationSchema,
    onSubmit: (values) => {
      var donationObj = {}
      if (formik.values.other) {
        donationObj = {
          Id: props.reportDonations.length,
          Group: formik.values.other,
          Date: formik.values.donationDate,
          Donation: formik.values.donation
        }
      } else {
        donationObj = {
          Id: props.reportDonations.length,
          Group: formik.values.group,
          Date: formik.values.donationDate,
          Donation: formik.values.donation
        }
      }

      props.setReportDonations(reportDonations => [...reportDonations, donationObj])
      props.setIncomeTotal(incomeTot => incomeTot += parseFloat(donationObj.Donation))
    }
  })

  return (
    <>
      <h2>Income</h2>
      <DonationsTable reportDonations={props.reportDonations} editIncome={props.editIncome} incomeTotal={props.incomeTotal} reRenderIncome={props.reRenderIncome} />
      <ColouredLine/>
      <div className="Donation">
        <h3>Add income record</h3>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId="group">
            <Row>
              <Form.Label>Income entry</Form.Label>
              <Form.Control name="group" as="select" value={formik.values.group} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.group && formik.errors.group}>
                {donationList.map((option) => {
                  return (
                    <option value={option} key={groupsKeys[option]}>
                      {/* {groupsContext.ActiveKeys[option]} {option} */}
                      {option}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{formik.errors.group}</Form.Control.Feedback>
            </Row>
          </Form.Group>

          {formik.values.group === "Other - Please state" && (
            <Form.Group controlId="other">
              <Row>
                <Form.Label>Detail</Form.Label>
                <Form.Control name="other" type="input" placeholder="please state" value={formik.values.other} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.other && formik.errors.other}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.other}</Form.Control.Feedback>
              </Row>
            </Form.Group>
          )}

          <Form.Group controlId="donationDate">
            <Row>
              <Col xs={5}>
                <Form.Label>Date of Payment</Form.Label>
                <Form.Control name="donationDate" type="input" placeholder="dd/mm/yyyy" value={formik.values.donationDate} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.donationDate && formik.errors.donationDate}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.donationDate}</Form.Control.Feedback>
              </Col>

              <Col xs={5}>
                <Form.Label>Payment</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>£</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control name="donation" type="float" placeholder="amount" value={formik.values.donation} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.donation && formik.errors.donation}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.donation}</Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Row>
          </Form.Group>

          <Row>
            <LoaderButton size="sm" type="submit" variant="secondary">
              add donation record
            </LoaderButton>
          </Row>
        </Form>
      </div>
      <button size="lg" onClick={() => {
        props.editIncomeCanx(false);
      }} type="button" variant="secondary">
        cancel
      </button>
      <button size="lg" onClick={() => {
        props.setSavedIncomeRender(props.reportDonations)
        props.setEditIncome(false);
      }} type="button" variant="secondary">
        save
      </button>

    </>
  );
}







