import React from "react";
import ExpensesTable from "../tables/ExpensesTable";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row'
import InputGroup from "react-bootstrap/InputGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./forms.css"
import ColouredLine from "../shapes/ColouredLine"
import LoaderButton from "../buttons/LoaderButton";
import { useGroupsContext } from "../../libs/context/groupsLib";

export default function EditExpenseForm(props) {
  const { groupsContext } = useGroupsContext()
  var expenseList = []
  expenseList = Array.from(Object.values(groupsContext.ActiveGroups))
  expenseList.sort()
  expenseList = expenseList.filter(group => group !== "-select your group-")
  expenseList.unshift("Other - Please state")
  expenseList.unshift("-select expense type-")


  var groupsKeys = groupsContext.ActiveKeys
  groupsKeys["-select expense type-"] = "00"
  groupsKeys["Other - Please state"] = "-1"

  const amountRegex = /^\d+(\.\d{1,2})?$/;
  const donationDateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
  //"/(0?[1-9]|[12]\d|30|31)[^\w\d\r\n:](0?[1-9]|1[0-2])[^\w\d\r\n:]\d{4}/"


  const validationSchema = Yup.object().shape({
    group: Yup.string().test("groupTest", "You must select a type", (group) => group !== "-select expense type-"),
    expense: Yup.string().matches(amountRegex, "Enter a valid amount"),
    expenseDate: Yup.string().matches(donationDateRegex, "Format: dd/mm/yyyy"),
    other: Yup.string().min(2, "Minimum two characters").max(30, "Maximum thirty characters"),
  });

  const formik = useFormik({
    initialValues: {
      group: "",
      other: "",
      expense: "",
      expenseDate: ""
    },

    validationSchema,
    onSubmit: (values) => {
      var expenseObj = {}
      if (formik.values.other) {
        expenseObj = {
          Id: props.reportExpenses.length + 1,
          Group: formik.values.other,
          Date: formik.values.expenseDate,
          Expense: formik.values.expense
        }
      } else {
        expenseObj = {
          Id: props.reportExpenses.length + 1,
          Group: formik.values.group,
          Date: formik.values.expenseDate,
          Expense: formik.values.expense
        }
      }

      props.setReportExpenses(reportExpenses => [...reportExpenses, expenseObj])
      props.setExpenseTotal(expenseTot => expenseTot += parseFloat(expenseObj.Expense))
    }
  })

  return (
    <>
      <h2>Expenses</h2>
      <ExpensesTable reportExpenses={props.reportExpenses} editExpense={props.editExpense} expenseTotal={props.expenseTotal} reRenderExpense={props.reRenderExpense} />
      <ColouredLine/>
      <div className="Donation">
        <h3>Add expense record</h3>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId="group">
            <Row>
              <Form.Label>Expense entry</Form.Label>
              <Form.Control name="group" as="select" value={formik.values.group} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.group && formik.errors.group}>
                {expenseList.map((option) => {
                  return (
                    <option value={option} key={groupsKeys[option]}>
                      {/* {groupsContext.ActiveKeys[option]} {option} */}
                      {option}
                    </option>
                  );
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{formik.errors.group}</Form.Control.Feedback>
            </Row>
          </Form.Group>

          {formik.values.group === "Other - Please state" && (
            <Form.Group controlId="other">
              <Row>
                <Form.Label>Detail</Form.Label>
                <Form.Control name="other" type="input" placeholder="please state" value={formik.values.other} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.other && formik.errors.other}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.other}</Form.Control.Feedback>
              </Row>
            </Form.Group>
          )}

          <Form.Group controlId="expenseDate">
            <Row>
              <Col xs={5}>
                <Form.Label>Date of Expense</Form.Label>
                <Form.Control name="expenseDate" type="input" placeholder="dd/mm/yyyy" value={formik.values.expenseDate} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.expenseDate && formik.errors.expenseDate}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.expenseDate}</Form.Control.Feedback>
              </Col>

              <Col xs={5}>
                <Form.Label>Expense</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>£</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control name="expense" type="float" placeholder="amount" value={formik.values.expense} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.expense && formik.errors.expense}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.expense}</Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Row>
          </Form.Group>

          <Row>
            <LoaderButton size="sm" type="submit" variant="secondary">
              add expense record
            </LoaderButton>
          </Row>
        </Form>
      </div>
      <button size="lg" onClick={() => {
        props.editExpenseCanx(false);
      }} type="button" variant="secondary">
        cancel
      </button>
      <button size="lg" onClick={() => {
        props.setSavedExpenseRender(props.reportExpenses)
        props.setEditExpense(false);
      }} type="button" variant="secondary">
        save
      </button>

    </>
  );
}







