import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../../graphql/mutations";
import { onError } from "../../../libs/userFeedback/errorLib";

export default async function updateNtig(data) {
  console.log(data)
  try {
    const apiGroups = await API.graphql(graphqlOperation(mutations.updateNTIG, { input: data }));
    let response = apiGroups.data.updateNTIG;
      return response;
  } catch (error) {
    onError(error.message)
    return false
  }
  //return updateNtig
}
