import React from "react";
import "./Welcome.css";
import BlockButton from "../../components/buttons/BlockButton"
import ColouredLine from "../../components/shapes/ColouredLine"
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../libs/context/userLib"
import FadeIn from "react-fade-in";

export default function Welcome() {
  const navigate = useNavigate();
  const { userContext } = useUserContext()


  function donationClick() {
    navigate("/donation");
  }

  function contactsClick() {
    navigate("/contacts");
  }

  function formClick() {
    navigate("/grouptqrtlyform");
  }

  function manageUsersClick() {
    navigate("/manageusers")
  }

  function createUserClick() {
    navigate("/createuser")
  }

  function igOfficersClick() {
    navigate("/igofficers")
  }
  function igQrtlyReportClick() {
    navigate("/officerqrtrlyform")
  }

  function contactsUpdateClick() {
    navigate("/contactsupdate")
  }

  function groupMgtClick() {
    navigate("/groupsmgt")
  }

  function ntigReportsClick() {
    navigate("/ntigreports")
  }

  function secAdminClick() {
    navigate("/secadmin")
  }

  return (
    <FadeIn transitionDuration="500">
      <div className="WelcomePage">
        <h1>
          What would you like to do?
          <br />
        </h1>
        {userContext.userIsAdmin && (
          <>
            <ColouredLine color="grey" />
            <h2>Admin</h2>
            <BlockButton variant="secondary" onClick={contactsUpdateClick}>
              groups' contacts - submissions
            </BlockButton>
            <BlockButton variant="secondary" onClick={createUserClick}>
              create portal user
            </BlockButton>
            <BlockButton variant="secondary" onClick={manageUsersClick}>
              manage portal users
            </BlockButton>
            <BlockButton variant="secondary" onClick={secAdminClick}>
              secretary admin
            </BlockButton>
          </>
        )}
        {(userContext.userIsAdmin || userContext.userIsNtigExec) && (
          <>
            <ColouredLine color="grey" />
            <h2>Exec</h2>
            {/* <BlockButton variant="secondary" onClick={activeGroupsClick}>
              active groups
            </BlockButton> */}
            <BlockButton variant="secondary" onClick={igOfficersClick}>
              active intergroup officers
            </BlockButton>
            <BlockButton variant="secondary" onClick={igQrtlyReportClick}>
              intergroup officers - submit quarterly report
            </BlockButton>
            <BlockButton variant="secondary" onClick={groupMgtClick}>
              groups management
            </BlockButton>
            <BlockButton variant="secondary" onClick={ntigReportsClick}>
              NTIG reports
            </BlockButton>
          </>
        )}
        <ColouredLine color="grey" />
        <h2>Groups</h2>
        <BlockButton variant="secondary" onClick={donationClick}>
          notify Intergroup of a donation
        </BlockButton>
        <BlockButton variant="secondary" onClick={contactsClick}>
          update your group contact details
        </BlockButton>
        <BlockButton variant="secondary" onClick={formClick}>
          submit a quarterly report
        </BlockButton>
        <ColouredLine color="grey" />
        <BlockButton variant="secondary" onClick={ntigReportsClick}>
              View NTIG reports
            </BlockButton>
      </div>
    </FadeIn>
  );
}
