import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import ColouredLine from "../shapes/ColouredLine";
import DeleteButton from "../buttons/DeleteButton";
import FadeIn from "react-fade-in";
import { useState } from "react";
import AdminAddUserToGroup from "../../API/Cognito/AdminAddUserToGroup";
import AdminRemoveUserFromGroup from "../../API/Cognito/AdminRemoveUserFromGroup";
import AdminDeleteUser from "../../API/Cognito/AdminDeleteUser";
import { useUserContext } from "../../libs/context/userLib";
import { onError } from "../../libs/userFeedback/errorLib";

const UserEditForm = (props) => {
	const { userContext } = useUserContext();
	const [checkNtigExec, setCheckNtigExec] = useState(
		props.currentUser.NtigExec
	);
	const [checkNtigAdmin, setCheckNtigAdmin] = useState(
		props.currentUser.NtigAdmin
	);

	function validateForm() {
		return (
			checkNtigExec === props.currentUser.NtigExec &&
			checkNtigAdmin === props.currentUser.NtigAdmin
		);
	}
  
    async function deleteUser() {
		await AdminDeleteUser(props.currentUser.Email)
			.then((response) => {
				if (response === true) {
					onError("User deleted successfully");
					props.setInEdit(false);
					if (props.deletedUser) props.deletedUser(props.currentUser.id);
				} else {
					onError(response);
				}
			})
			.catch((error) => {
				onError(error.message);
			});
	}
	async function handleSubmit(event) {
		event.preventDefault();
		var updatedUser = {
			id: props.currentUser.id,
			Email: props.currentUser.Email,
			NtigExec: checkNtigExec,
			NtigAdmin: checkNtigAdmin,
		};
		var add_cognito_user_pool_groups = [];
		var del_cognito_user_pool_groups = [];
		if (
			props.currentUser.NtigExec === checkNtigExec &&
			props.currentUser.NtigAdmin === checkNtigAdmin
		) {
			onError("No change");
			props.setInEdit(false);
			return;
		}

		if (props.currentUser.NtigExec !== checkNtigExec) {
			switch (checkNtigExec) {
				case true:
					add_cognito_user_pool_groups.push("ntig-exec-users");
					break;

				case false:
					del_cognito_user_pool_groups.push("ntig-exec-users");
					break;
				default:
			}
		}
		if (props.currentUser.NtigAdmin !== checkNtigAdmin) {
			switch (checkNtigAdmin) {
				case true:
					add_cognito_user_pool_groups.push("ntig-admin-users");
					break;

				case false:
					del_cognito_user_pool_groups.push("ntig-admin-users");
					break;
				default:
			}
		}
		let response = true
		if (add_cognito_user_pool_groups.length !== 0) {
			response = await AdminAddUserToGroup(
				props.currentUser.Email,
				add_cognito_user_pool_groups
			);
		}
		console.log(response, del_cognito_user_pool_groups.length) 
		if (response === true && del_cognito_user_pool_groups.length !== 0) {
			
			response = await AdminRemoveUserFromGroup(
				props.currentUser.Email,
				del_cognito_user_pool_groups
			);
		}

		if(props.reviewUser) props.reviewUser(updatedUser);
	}

	return (
		<>
			<h2>edit portal user</h2>
			<FadeIn>
				<Form onSubmit={handleSubmit}>
					<Form.Group size="lg" controlId="email">
						<Form.Label>email</Form.Label>
						<Form.Control value={props.currentUser.Email} readOnly />
					</Form.Group>

					<ColouredLine color="grey" />
					<h3>additional portal access</h3>
					<Row>
						{["checkbox"].map((type) => (
							<div key={`default-${type}`} className="mb-3">
								<Form.Check
									type={type}
									id={`default-${type}`}
									checked={checkNtigExec}
									onChange={() => setCheckNtigExec((prev) => !prev)}
									label="user is a NTIG officer"></Form.Check>
							</div>
						))}
					</Row>
					<Row>
						{["checkbox"].map((type) => (
							<div key={`default-${type}`} className="mb-3">
								<Form.Check
									type={type}
									id={`default-${type}`}
									checked={checkNtigAdmin}
									onChange={() => setCheckNtigAdmin((prev) => !prev)}
									label="user is portal admin"></Form.Check>
							</div>
						))}
					</Row>
					<ColouredLine color="grey" />
					<Row>
						<Button
							size="lg"
							type="button"
							onClick={() => props.setInEdit(false)}
							variant="secondary">
							cancel
						</Button>
					</Row>
					<div className="divider"></div>
					<Row>
						<Button
							size="lg"
							type="submit"
							variant="success"
							disabled={validateForm()}>
							save
						</Button>
					</Row>
					{props.currentUser.Email !== userContext.user &&
						props.currentUser !== "ecomm.nthames@aamail.org" && (
								<DeleteButton deleteFunction={deleteUser} />
						)}
				</Form>
			</FadeIn>
		</>
	);
};
export default UserEditForm;
