import { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import updateNtig from "../../API/appSync/ntig/updateNtig";

export default function ReportingQuarterSelectForm(props) {
	const arryears = Array.from(Array(new Date().getFullYear() - 2019), (_, i) =>
		(i + 2020).toString()
	);
	const years = arryears.sort(function (a, b) {
		return b - a;
	});

	const quarters = ["Q1", "Q2", "Q3", "Q4"];
	const makeDatestamp = new Date();
	const currentYear = makeDatestamp.getFullYear();
	const currentMonth = makeDatestamp.getMonth() + 1; //months start at 0

	let currentQuarter = "";
	let indexQtr;
	if (currentMonth <= 3) {
		currentQuarter = "Q1";
		indexQtr = 0;
	} else if (currentMonth <= 6) {
		currentQuarter = "Q2";
		indexQtr = 1;
	} else if (currentMonth <= 9) {
		currentQuarter = "Q3";
		indexQtr = 2;
	} else {
		currentQuarter = "Q4";
		indexQtr = 3;
	}
	const [newYear, setNewYear] = useState(currentYear);
	const [newQtr, setNewQtr] = useState(currentQuarter);
	const [newPeriod, setNewPeriod] = useState(
		currentYear + "/" + currentQuarter
	);

	const handleYearChange = (e) => {
		setNewYear(e.target.value);
		setNewPeriod(e.target.value + "/" + newQtr);
	};

	const handleQuarterChange = (e) => {
		setNewQtr(e.target.value);
		setNewPeriod(newYear + "/" + e.target.value);
	};

	const validateForm = () => {
		return newPeriod === props.propPeriod;
	};
	const sendPeriodChange = async () => {
		const sendData = {
			PK: "PortalAdmin#Secretary",
			SK: "ReportingPeriod",
			Period: newPeriod,
		};

		await updateNtig(sendData);
		props.reportingPeriodChange(newPeriod);
		props.setInPeriodEdit(false);
	};

	return (
		<>
			<Form>
				<Row>
					<Form.Group as={Col} controlId="year">
						<Form.Label>Year</Form.Label>
						<Form.Control name="year" as="select" onChange={handleYearChange}>
							{years.map((option) => {
								return (
									<option value={option} key={option}>
										{option}
									</option>
								);
							})}
						</Form.Control>
					</Form.Group>

					<Form.Group as={Col} controlId="quarter">
						<Form.Label>Quarter</Form.Label>
						<Form.Control
							name="quarter"
							as="select"
							onChange={handleQuarterChange}
							defaultValue={quarters[indexQtr]}>
							{quarters.map((option) => {
								return (
									<option value={option} key={option}>
										{option}
									</option>
								);
							})}
						</Form.Control>
					</Form.Group>
				</Row>
				<hr />
				<Row>
					<Button
						size="sm"
						variant="secondary"
						onClick={() => props.setInPeriodEdit(false)}>
						cancel
					</Button>
				</Row>
				<div className="divider"></div>
				<Row>
					<Button
						size="sm"
						variant="success"
						type="button"
						disabled={validateForm()}
						onClick={() => sendPeriodChange()}>
						submit
					</Button>
				</Row>
			</Form>
		</>
	);
}
