import { CognitoIdentityProviderClient, AdminAddUserToGroupCommand } from "@aws-sdk/client-cognito-identity-provider"
import awsmobile from "../../aws-exports";
import { Auth } from "@aws-amplify/auth";
import { onSuccess } from "../../libs/userFeedback/successLib"
import { onError } from "../../libs/userFeedback/errorLib";

//comes from creating user

export default async function AdminAddUserToGroup(email, groups) {
  let result = false
  const creds = await Auth.currentCredentials()
  for (let i = 0; i < groups.length; i++) {
    let params = {
      GroupName: groups[i],
      Username: email,
      UserPoolId: awsmobile.aws_user_pools_id
    };
    try {

      const client = new CognitoIdentityProviderClient({
        region: awsmobile.aws_project_region,
        credentials: creds,
      });

      const command = new AdminAddUserToGroupCommand(params);
      await client.send(command);

      //
      if (i + 1 === groups.length && groups[i] !== "ntig-groups-users") {
        onSuccess("User added successfully")
        return true;
      }
      result = true

    } catch (error) {
      onError(error.message)
      return false;
    }
  }
  return result
}