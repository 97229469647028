import React, { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import loadContext from "../functions/LoadContext"
import { useAuthContext } from "../../libs/context/authenticatedLib";
import { useUserContext } from "../../libs/context/userLib";
import { useGroupsContext } from "../../libs/context/groupsLib";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button'
import LoaderButton from "../buttons/LoaderButton";
import { onError } from "../../libs/userFeedback/errorLib";
import FadeIn from "react-fade-in";
//import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const RenderConfirmationForm = (props) => {
    const { setGroupsContext } = useGroupsContext(false);
    const email = props.email
    const password = props.password
    const [confCode, setConfCode] = useState("")
    const navigate = useNavigate();
    const { userHasAuthenticated } = useAuthContext();
    const { setUserContext } = useUserContext()
    const [isLoading, setIsLoading] = useState(false);

    function handleChange(event) {
        setConfCode(event.target.value);
        if (props.onChange) props.onChange(confCode);
    }
    function validateConfirmationForm() {
        return confCode.length > 0;
    }

    async function resendConfirmationCode() {
        try {
            await Auth.resendSignUp(email);
            onError('code resent successfully');
        } catch (err) {
            onError('error resending code: ', err);
        }
        setConfCode("")
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            let sendConfCode = confCode.trim()
            await Auth.confirmSignUp(email, sendConfCode);
            const user = await Auth.signIn(email, password);

            userHasAuthenticated(true);
            const isAdmin = user.signInUserSession.accessToken.payload['cognito:groups'].includes("ntig-admin-users");
            const isNtigExec = user.signInUserSession.accessToken.payload['cognito:groups'].includes("ntig-exec-users");
            const isPortalUser = user.signInUserSession.accessToken.payload['cognito:groups'].includes("ntig-groups-users");

            setUserContext({ user: user.signInUserSession.idToken.payload.email, userName: user.signInUserSession.idToken.payload.name, userIsAdmin: isAdmin, userIsNtigExec: isNtigExec, userIsPortalUser: isPortalUser });
            const loadGroupContext = await loadContext()
            setGroupsContext(loadGroupContext)


            navigate("/welcome");
        } catch (e) {
            onError(e);
        }
        setIsLoading(false);
    }

    return (
        <FadeIn delay="800">
            <div className="signup">
                <Form onSubmit={handleConfirmationSubmit}>
                    <Form.Group controlId="confirmationCode" size="lg">
                        <Form.Label>please check your email for the confirmation code</Form.Label>
                        <Form.Control autoFocus type="tel" onChange={handleChange} value={confCode} />
                        <Button variant="link" onClick={resendConfirmationCode}>need a new code? click here</Button>
                    </Form.Group>
                    <Row>
                    <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={!validateConfirmationForm()}>
                        verify
                    </LoaderButton>
                    </Row>
                </Form>
            </div>
        </FadeIn>
    );
}

export default RenderConfirmationForm;