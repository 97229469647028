import React from "react";
import "./NotFound.css";
import FadeIn from "react-fade-in";

export default function NotFound() {
  return (
    <FadeIn transitionDuration="500">
      <div className="NotFound text-center">
        <h3>Sorry, page not found!</h3>
      </div>
    </FadeIn>
  );
}
