import "./footer.css";
import ColouredLine from "../shapes/ColouredLine"

function MainFooter() {

  return (
    <>
      <ColouredLine color="grey" />
      <small className="main-footer">Enquiries should be directed to service@ntig.uk</small>
    </>
  )
}

export default MainFooter;
