import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row'
import InputGroup from "react-bootstrap/InputGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import LoaderButton from "../../components/buttons/LoaderButton";
import "./Contacts.css";
import createNtig from "../../API/appSync/ntig/createNtig";
import notifyTres from "../../API/appSync/ntig/notifyTres";
import { useUserContext } from "../../libs/context/userLib"
import { useGroupsContext } from "../../libs/context/groupsLib";
import { useNavigate } from "react-router-dom";
import FadeIn from "react-fade-in/lib/FadeIn";

export default function Donation() {
  const { userContext } = useUserContext()
  const { groupsContext } = useGroupsContext()
  const navigate = useNavigate();
  // const [isLoaded, setIsLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiError, setApiError] = useState(false);
  const [errorLoc, setErrorLoc] = useState("");

  var groupsList = []
  groupsList = Array.from(Object.values(groupsContext.ActiveGroups))
  groupsList.sort()

  function completeSubmit() {
    setIsLoading(false);
    navigate("/submitsuccess");
  }

  function apiCallError(error) {
    setIsLoading(false);
    setApiError(true);
    setErrorMessage(error);
  }

  const amountRegex = /^\d+(\.\d{1,2})?$/;
  const donationDateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
  //"/(0?[1-9]|[12]\d|30|31)[^\w\d\r\n:](0?[1-9]|1[0-2])[^\w\d\r\n:]\d{4}/"

  function validateNotEmpty() {
    return formik.values.group !== "-select your group-" && formik.values.donation.length > 0;
  }

  const validationSchema = Yup.object().shape({
    group: Yup.string().test("groupTest", "You must select a group", (group) => group !== "-select your group-"),
    donation: Yup.string().required("Required").matches(amountRegex, "Enter a valid amount"),
    donationDate: Yup.string().required("Required").matches(donationDateRegex, "Format: dd/mm/yyyy")
  });

  const formik = useFormik({
    initialValues: {
      group: "",
      donation: "",
      donationDate: ""
    },

    validationSchema,
    onSubmit: (values) => {
      // setIsLoaded(false);
      setIsLoading(true);

      var makeDatestamp = new Date();
      var year = makeDatestamp.getFullYear();
      var month = makeDatestamp.getMonth() + 1; //months start at 0
      var day = makeDatestamp.getDate();

      var datestamp = day + "/" + month + "/" + year;

      var paymentQuarter = ""
      var paymentMonth = formik.values.donationDate.split("/")[1]
      if (paymentMonth <= 3) {
        paymentQuarter = "Q1";
      } else if (paymentMonth <= 6) {
        paymentQuarter = "Q2";
      } else if (paymentMonth <= 9) {
        paymentQuarter = "Q3";
      } else {
        paymentQuarter = "Q4";
      }

      var period = formik.values.donationDate.split("/")[2] + "/" + paymentQuarter;
      var submitter = '{"Email": "' + userContext.user + '","Name": "' + userContext.userName + '"}';

      var subDonation = {
        PK: `Donation#${formik.values.donationDate}`,
        SK: `Group#${groupsContext.ActiveKeys[formik.values.group]}`,
        PaymentDate: formik.values.donationDate,
        Date: datestamp,
        Period: period,
        Donation: formik.values.donation,
        Submitter: submitter
      };

      var tresNotifyJson = {
        GroupId: groupsContext.ActiveKeys[formik.values.group],
        Group: formik.values.group,
        PaymentDate: formik.values.donationDate,
        Donation: formik.values.donation,
        Submitter: submitter
      };


      //notify treasurer
      const sendToTres = async () => {
        await notifyTres(tresNotifyJson)
          .then((response) => {
            if (response === true) {
              completeSubmit();
            } else {
              setErrorLoc("Donation - notifyTres()");
              apiCallError(JSON.stringify(response.errors[0].errorType));
            }
          })
        //.catch((e) => { })
      };
      const reportDonation = async () => {
        await createNtig(subDonation)
          .then((response) => {
            if (response === true) {
              sendToTres()
            } else {
              setErrorLoc("Donation - reportDonation()");
              apiCallError(JSON.stringify(response.errors[0].errorType));
            }
          })
        //.catch((e) => { })
      }
      reportDonation();

    }
  });

  function renderApiCallError() {
    function handleClose() {
      setApiError(false);
    }
    return (
      <div className="GroupQtrlyReport">
        <FadeIn>
          <h3>
            Sorry, there's been an error. <br />
            <br />
            We couldn't tell what the error was. If the error persists please notify us. <br />
            <br />
          </h3>
          <div>
            Your report details will be visible, when you close the page, in case you need to keep any details.
            <br />
            <br />
          </div>
          <Form>
            <Form.Label>Error for notification</Form.Label>
            <Form.Control type="input" value={errorLoc} readOnly></Form.Control>
            <Form.Control type="input" value={errorMessage} readOnly></Form.Control>
            <Form.Control type="input" value={userContext.user} readOnly></Form.Control>
            <LoaderButton size="lg" variant="secondary" isLoading={isLoading} onClick={handleClose}>
              close page
            </LoaderButton>
          </Form>
        </FadeIn>
      </div>
    );
  }

  function renderDonationForm() {
    // if (!isLoaded) {
    //   return <div>Loading...</div>;
    // } else {
    return (
      <>
        {/* <h2 style={{ color: "red" }}>{errorMessage}</h2> */}
        <div className="Donation">
          <h2>tell us about your donation</h2>
          <Form onSubmit={formik.handleSubmit}>
          <div className="divider"></div>
            <Form.Group controlId="group">
              <Row>
                <Form.Label>Your group</Form.Label>
                <Form.Control name="group" as="select" value={formik.values.group} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.group && formik.errors.group}>
                  {groupsList.map((option) => {
                    return (
                      <option value={option} key={groupsContext.ActiveKeys[option]}>
                        {/* {groupsContext.ActiveKeys[option]} {option} */}
                        {option}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.group}</Form.Control.Feedback>
              </Row>
            </Form.Group>
            <div className="divider"></div>
            <Form.Group controlId="name">
              <Row>
                <Form.Label>Your details</Form.Label>
                <Form.Control name="name" type="input" value={userContext.userName} readOnly></Form.Control>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}</Row> */}
            </Form.Group>

            <Form.Group controlId="email">
              <Row>
                <Form.Control name="email" type="input" value={userContext.user} readOnly></Form.Control>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}</Row> */}
            </Form.Group>
            <div className="divider"></div>
            <Form.Group controlId="donationDate">
              <Row>
                <Col xs={5}>
                  <Form.Label>Date of Donation</Form.Label>
                  <Form.Control name="donationDate" type="input" placeholder="dd/mm/yyyy" value={formik.values.donationDate} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.donationDate && formik.errors.donationDate}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.donationDate}</Form.Control.Feedback>
                </Col>

                <Col xs={5}>
                  <Form.Label>Donation</Form.Label>
                  <InputGroup>
                      <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
                    <Form.Control name="donation" type="float" placeholder="amount" value={formik.values.donation} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.donation && formik.errors.donation}></Form.Control>
                    <Form.Control.Feedback type="invalid">{formik.errors.donation}</Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
            </Form.Group>
            <div className="divider"></div>
            <Row>
              <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={!validateNotEmpty()}>
                submit
              </LoaderButton>
            </Row>
          </Form>
        </div>
      </>
    );
  }
  // }
  return <div className="GroupQtrlyReport">{apiError === true ? renderApiCallError() : renderDonationForm()}</div>;
}