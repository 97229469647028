import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../../graphql/queries";

export default async function listNtigs(filterPK, type, filterData) {
	let filterSK = "";
	switch (type) {
		case "contacts":
			filterSK = "Group#";
			break;

		case "Group Reports":
			filterSK = "Group#";
			break;

		case "Officer Reports":
			filterSK = "Officer#";
			break;

		case "minutesViewer":
			filterSK = filterData;
			break;

		default:
			return;
	}

	try {
		let apiData = await API.graphql(
			graphqlOperation(queries.listNTIGS, {
				filter: { PK: { eq: filterPK }, SK: { beginsWith: filterSK } },
			})
		);
		//let receivedGroups = await apiData.data.listNTIGS.items;
		let data = [];
		let receivedItems = {};

		switch (type) {
			case "contacts":
				for (let i in apiData.data.listNTIGS.items) {
					let listItem = await apiData.data.listNTIGS.items[i];
					let id = await apiData.data.listNTIGS.items[i].SK.split("#")[1];
					let Group = filterData[id].Name;
					let submitterJson = await JSON.parse(
						apiData.data.listNTIGS.items[i].Submitter
					);
					let secJson = await JSON.parse(
						apiData.data.listNTIGS.items[i].Secretary
					);
					let tresJson = await JSON.parse(
						apiData.data.listNTIGS.items[i].Treasurer
					);
					let gsrJson = await JSON.parse(apiData.data.listNTIGS.items[i].GSR);

					data.push({
						Id: id,
						Group: Group,
						Date: listItem.Date,
						SubmitterName: submitterJson.Name,
						SubmitterEmail: submitterJson.Email,
						SecretaryName: secJson.Name,
						SecretaryEmail: secJson.Email,
						SecretaryPhone: secJson.Phone,
						TreasurerName: tresJson.Name,
						TreasurerEmail: tresJson.Email,
						TreasurerPhone: tresJson.Phone,
						GSRName: gsrJson.Name,
						GSREmail: gsrJson.Email,
						GSRPhone: gsrJson.Phone,
					});
				}
				receivedItems.data = data;
				break;

			case "Group Reports":
				for (let i in apiData.data.listNTIGS.items) {
					let listItem = await apiData.data.listNTIGS.items[i];
					let id = await apiData.data.listNTIGS.items[i].SK.split("#")[1];
					let Name = await filterData[id].Name;
					let submitterJson = await JSON.parse(
						apiData.data.listNTIGS.items[i].Submitter
					);

					data.push({
						Id: id,
						Group: Name,
						Date: listItem.Date,
						SubmitterName: submitterJson.Name,
						SubmitterEmail: submitterJson.Email,
						Detail: listItem.Detail,
						Donation: listItem.Donation,
					});
				}
				receivedItems.data = data;
				break;

			case "Officer Reports":
				for (let i in apiData.data.listNTIGS.items) {
					let listItem = await apiData.data.listNTIGS.items[i];
					let id = await apiData.data.listNTIGS.items[i].SK.split("#")[1];
					let Name = await apiData.data.listNTIGS.items[i].SK.split("#")[1];
					let submitterJson = await JSON.parse(
						apiData.data.listNTIGS.items[i].Submitter
					);
					if (Name === "Treasurer") {
						var financeJson = await JSON.parse(
							apiData.data.listNTIGS.items[i].Finance
						);
						data.push({
							Id: id,
							Officer: Name,
							Date: listItem.Date,
							SubmitterName: submitterJson.Name,
							SubmitterEmail: submitterJson.Email,
							Detail: listItem.Detail,
							IncomeTotal: financeJson.IncomeTotal,
							ExpenseTotal: financeJson.ExpenseTotal,
							QuarterBalance: financeJson.QuarterBalance,
						});
					} else {
						data.push({
							Id: id,
							Officer: Name,
							Date: listItem.Date,
							SubmitterName: submitterJson.Name,
							SubmitterEmail: submitterJson.Email,
							Detail: listItem.Detail,
						});
					}
				}
				receivedItems.data = data;
				break;

			case "minutesViewer":
				for (let i in apiData.data.listNTIGS.items) {
					let listItem = await apiData.data.listNTIGS.items[i];
					let submitterJson = await JSON.parse(
						apiData.data.listNTIGS.items[i].Submitter
					);
					data.push({
						Id: i,
						ReportPeriod: listItem.Period,
						Detail: listItem.Detail,
						SubmitterName: submitterJson.Name,
						SubmitterEmail: submitterJson.Email,
						Date: listItem.Date,
					});
				}
				receivedItems.data = data;
				break;

			default:
				console.warn("listNtigs: default case");
		}
		return receivedItems.data;
	} catch (err) {}
}
