import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

export default function ReportingQuarterForm(props) {
	return (
		<Form>
			<Row>
				<Form.Group as={Col} controlId="year">
					<Form.Label>Year</Form.Label>
					<Form.Control
						name="year"
						as="input"
						placeholder={props.reportingYr}
						readOnly></Form.Control>
				</Form.Group>

				<Form.Group as={Col} controlId="quarter">
					<Form.Label>Quarter</Form.Label>
					<Form.Control
						name="quarter"
						as="input"
						placeholder={props.reportingQtr}
						readOnly></Form.Control>
				</Form.Group>
			</Row>
			<hr />
			<Row>
				<Button
				
					variant="secondary"
					onClick={() => props.setInPeriodEdit(true)}>
					change reporting period
				</Button>
			</Row>
			<Row>
				<div style={{ textAlign: "center" }}>
					change the reporting quarter before sending the intergroup meeting
					invite
				</div>
			</Row>
		</Form>
	);
}
