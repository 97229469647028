import API, { graphqlOperation } from "@aws-amplify/api"
import * as mutations from "../../../graphql/mutations"

export default async function notifySec(secNotifyJson) {
  let response
  await API.graphql(graphqlOperation(mutations.secNotify, { input: secNotifyJson }))
    .then((data) => {
      response = true
    })
    .catch((error) => {
      response = error
    });
  return response
};
