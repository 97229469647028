import { useEffect, useState, useRef } from "react";
import { useGroupsContext } from "../../../libs/context/groupsLib";
import GroupsMgtTable from "../../../components/tables/GroupsMgtTable";
import GroupNamePageHeader from "../../../components/pageNav/headers/GroupNamePageHeader"
import ReviewContactForm from "../../../components/forms/ReviewContactForm";
import listNtigs from "../../../API/appSync/ntig/listNtigs";
import getNtig from "../../../API/appSync/ntig/getNtig";

export default function ContactsUpdate() {
  const { groupsContext } = useGroupsContext()
  const filterPK = "Officers#1";
  const [isLoading, setIsLoading] = useState(true);
  const [inReview, setInReview] = useState(false); //const [editing, setEditing] = useState(false)
  const initialFormState = { id: null, Date: "", SecretaryName: "", SecretaryEmail: "", SecretaryPhone: "", TreasurerName: "", TreasurerEmail: "", TreasurerPhone: "", GSRName: "", GSREmail: "", GSRPhone: "" };
  const [currentContact, setCurrentContact] = useState(initialFormState);
  const containter = "proposed";
  const [contacts, setContacts] = useState([]);
  const dbContacts = useRef(false)

  useEffect(() => {
    (async () => {
        const data = await listNtigs(filterPK, "contacts", groupsContext.FullGroups);
        setContacts(data)
        setIsLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })()
}, [groupsContext]);

  const reviewRow = async (contact) => {
    //edit row
    dbContacts.current = await getNtig("Officers#2", "Group#" + contact.Id, "contact")
    setInReview(true);
    setCurrentContact({ id: contact.Id, Group: contact.Group, Date: contact.Date, SubmitterName: contact.SubmitterName, SubmitterEmail: contact.SubmitterEmail, SecretaryName: contact.SecretaryName, SecretaryEmail: contact.SecretaryEmail, SecretaryPhone: contact.SecretaryPhone, TreasurerName: contact.TreasurerName, TreasurerEmail: contact.TreasurerEmail, TreasurerPhone: contact.TreasurerPhone, GSRName: contact.GSRName, GSREmail: contact.GSREmail, GSRPhone: contact.GSRPhone });
  };

 
  const reviewContact = (id) => {
    //const updateUser
    setInReview(false);
    setContacts(contacts.filter((contact) => contact.Id !== id));
  };
  return (
    <div className="flex-large">
      {inReview ? (
        <div>
          <GroupNamePageHeader group={currentContact.Group} />
          <ReviewContactForm setInReview={setInReview} dbContacts={dbContacts.current} currentContact={currentContact} reviewContact={reviewContact} />
        </div>
      ) : (
        <div className="flex-row">
          <div className="flex-large">
            <h2>Contact List Submissions</h2>
            <h4>These are updates submitted by groups. Click review to update the Intergroup contact list</h4>
            <GroupsMgtTable contacts={contacts} reviewRow={reviewRow} container={containter} isLoading={isLoading} />
          </div>
        </div>
      )}
    </div>
  );
}
