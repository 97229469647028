import { useState } from "react";import { Auth } from "@aws-amplify/auth";
import { useUserContext } from "../../libs/context/userLib";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import LoaderButton from "../../components/buttons/LoaderButton";
import Button from "react-bootstrap/Button";
import { onError } from "../../libs/userFeedback/errorLib";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function ManageProfile() {
	const { userContext } = useUserContext();
	const [isLoading, setIsLoading] = useState(false);
	const [needCode, setNeedCode] = useState(false);
	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.min(2, "Minimum two characters")
			.max(15, "Maximum fifteen characters"),
		email: Yup.string().email("Invalid email format"),
	});

	const formik = useFormik({
		initialValues: {
			email: "",
			name: "",
			confCode: "",
		},

		validationSchema,
		onSubmit: (values) => {
			setIsLoading(true);
			handleSignupSubmit(values);
		},
	});

	async function handleSignupSubmit(values) {
		let userParams = {};
		if (formik.values.email !== "") {
			userParams.email = formik.values.email;
			setNeedCode(true);
		}
		if (formik.values.name !== "") userParams.name = formik.values.name;

		let user = await Auth.currentAuthenticatedUser();
		let result = await Auth.updateUserAttributes(user, userParams);
		console.log(result); // SUCCESS
		setIsLoading(false);
	}

	function validateName() {
		return formik.values.name.length > 0;
	}

	function validateConfirmationForm() {
		return formik.values.confCode.length > 0;
	}

	async function resendConfirmationCode() {
		try {
			await Auth.resendSignUp(formik.values.email);
			setNeedCode(true);
			onError("code resent successfully");
		} catch (err) {
			onError("error resending code: ", err);
		}
	}

	async function handleConfSubmit() {
		setIsLoading(true);
		try {
			let result = await Auth.verifyCurrentUserAttributeSubmit(
				"email",
				formik.values.confCode.trim()
			);
			console.log(result);
			setNeedCode(false);
		} catch (err) {
			onError(err);
		}
		setIsLoading(false);
	}

	const ConfirmationForm = () => {
		return (
			<Form onSubmit={handleConfSubmit}>
				<Form.Group controlId='conCode' size='lg'>
					<Form.Label>
						please check your email for the confirmation code
					</Form.Label>
					<Form.Control
						autoFocus
						type='tel'
						value={formik.values.confCode}
						onChange={formik.handleChange}
					/>
				</Form.Group>
				<LoaderButton
					size='lg'
					type='submit'
					variant='secondary'
					isLoading={isLoading}
					disabled={!validateConfirmationForm()}>
					verify
				</LoaderButton>
				<Button variant='link' onClick={resendConfirmationCode}>
					need a new code? click here
				</Button>
			</Form>
		);
	};

	const ProfileForm = () => {
		return (
			<>
				<h2>change your details</h2>
				<Form onSubmit={formik.handleSubmit}>
					{/* <Form.Group controlId="emailcurrent">
                            <Row>
                                <Form.Label>your current email</Form.Label>
                                <Form.Control type="text" value={userContext.user} readOnly></Form.Control>
                            </Row>
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Row>
                                <Form.Label>change email to</Form.Label>
                                <Form.Control name="email" type="input" placeholder="-no change-" value={formik.values.email} onChange={formik.handleChange}></Form.Control>
                            </Row>
                        </Form.Group>
                        <br /> */}
					<Form.Group className='mb-3' controlId='namecurrent'>
						<Row>
							<Form.Label>your name</Form.Label>
							<Form.Control
								type='text'
								value={userContext.userName}
								readOnly></Form.Control>
						</Row>
					</Form.Group>
					<Form.Group className='mb-3' controlId='name'>
						<Row>
							<Form.Label>change your name to</Form.Label>
							<Form.Control
								name='name'
								type='input'
								placeholder='-no change-'
								value={formik.values.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isInvalid={
									formik.touched.name && formik.errors.name
								}></Form.Control>
							<Form.Control.Feedback type='invalid'>
								{formik.errors.name}
							</Form.Control.Feedback>
						</Row>
					</Form.Group>
					<Row>
						<LoaderButton
							className='mb-3'
							size='lg'
							type='submit'
							variant='success'
							isLoading={isLoading}
							disabled={!validateName()}>
							submit
						</LoaderButton>
					</Row>
				</Form>
			</>
		);
	};
	return (
		<div className='ntig'>
			{needCode ? <ConfirmationForm /> : <ProfileForm />}
		</div>
	);
}
