import "./styles.scss";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ColouredLine from "../shapes/ColouredLine";
import parse from "html-react-parser";

export default function HTMLviewer(props) {
	const EditButton = () => {
		return (
			<>
				<Row>
					<Button
						size="lg"
						onClick={() => props.setInMinsEdit(true)}
						variant="secondary">
						edit minutes
					</Button>
				</Row>
				<div className="divider"></div>
			</>
		);
	};

	return (
		<div className="EditorSetup">
			<div className="sticky">
				<ColouredLine color="blue" />
				<h1>NTIG Meeting Minutes</h1>
				<h2>Reporting Period {props.viewReportPeriod} </h2>
				<Form>
					<div className="divider"></div>
					<Row>
						<EditButton />
					</Row>
				</Form>

				<hr />
			</div>
			{parse(props.editorDocument)}
			<ColouredLine color="blue" />
		</div>
	);
}
