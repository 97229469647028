import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from 'react-bootstrap/Row'
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState, } from "react";
import LoaderButton from "../buttons/LoaderButton";
import Button from "react-bootstrap/Button";
import ColouredLine from "../shapes/ColouredLine"
import "./forms.css";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";
import deleteNtig from "../../API/appSync/ntig/deleteNtig"
import FadeIn from "react-fade-in";
import { onError } from "../../libs/userFeedback/errorLib";

export default function OfficerQuarterlyReportForm(props) {
    const [checkDeleteEnable, setCheckDeleteEnable] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const title = "you are editing"
    const currentOfficerReport = props.currentOfficerReport

    const amountRegex = /^\d+(\.\d{1,2})?$/;
    const validationSchema = Yup.object().shape({
        reportText: Yup.string().required("Required"),
        // incomeTotal: Yup.string().when('officer', {
        //     is: 'Treasurer',
        //     then: Yup.string().required("Required").matches(amountRegex, "Enter a valid amount"),
        //     otherwise: Yup.string()
        // }),
        // expenseTotal: Yup.string().when('officer', {
        //     is: 'Treasurer',
        //     then: Yup.string().required("Required").matches(amountRegex, "Enter a valid amount"),
        //     otherwise: Yup.string()
        // }),
        // quarterBalance: Yup.string().when('officer', {
        //     is: 'Treasurer',
        //     then: Yup.string().required("Required").matches(amountRegex, "Enter a valid amount"),
        //     otherwise: Yup.string()
        // })
    });

    async function deleteReport() {
        await deleteNtig(props.PK, `Officer#${currentOfficerReport.Officer}`)
            .then((response) => {
                if (response === true) {
                    onError("Report deleted successfully")
                    props.setInOfficerEdit(false)
                    props.deletedReport(props.currentOfficerReport.Id)
                } else {
                    onError(response)
                }
            })
            .catch((error) => {
                onError(error.message)
            })
    }


    function completeSubmit() {
        setIsLoading(false);
        onError("Report updated successfully")
        props.setInOfficerEdit(false)
        props.updateReports()
    }

    const formik = useFormik({
        initialValues: {
            reportText: currentOfficerReport.Detail,
            incomeTotal: currentOfficerReport.IncomeTotal,
            expenseTotal: currentOfficerReport.ExpenseTotal,
            quarterBalance: currentOfficerReport.QuarterBalance

        },

        validationSchema,
        onSubmit: (values) => {
            setIsLoading(true);



            //create JSON for graphql createNtig submits group report
            var newOfficerReport = {}
            switch (currentOfficerReport.Officer) {
                case "Treasurer":
                    newOfficerReport = {
                        PK: props.PK,
                        SK: `Officer#${currentOfficerReport.Officer}`,

                        Detail: formik.values.reportText,
                        Finance: { IncomeTotal: formik.values.incomeTotal, ExpenseTotal: formik.values.expenseTotal, QuarterBalance: formik.values.quarterBalance }
                    };
                    break
                default:
                    newOfficerReport = {
                        PK: props.PK,
                        SK: `Officer#${currentOfficerReport.Officer}`,

                        Detail: formik.values.reportText,
                    };
                    break
            }

            
            // submit group report with a #1 status
            const reportSubmission = async () => {
                await API.graphql(graphqlOperation(mutations.updateNTIG, { input: newOfficerReport }))
                    .then((data) => {
                        completeSubmit()
                    })
                    .catch((error) => {
                        onError(error);
                        setIsLoading(false);
                    });
            };

            reportSubmission();
        }
    });

    function validateChanges() {
        switch (currentOfficerReport.Officer) {
            case "Treasurer":
                return !formik.isValid || (formik.values.reportText === currentOfficerReport.Detail && currentOfficerReport.IncomeTotal === formik.values.incomeTotal && currentOfficerReport.ExpenseTotal === formik.values.expenseTotal && currentOfficerReport.QuarterBalance === formik.values.quarterBalance)
            default:
                return !formik.isValid || formik.values.reportText === currentOfficerReport.Detail;
        }
    }

    return (
        <>
            <FadeIn transitionDuration="500">
                <div className="GroupQtrlyReport">
                    <h2>{title}</h2>
                    <div className="divider"></div>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group controlId="officer">
                            <Row>
                                <Form.Label>Officer</Form.Label>
                                <Form.Control name="officer" type="input" value={currentOfficerReport.Officer} readOnly></Form.Control>
                            </Row>
                        </Form.Group>
                        <div className="divider"></div>
                        <Form.Group controlId="name">
                            <Row>
                                <Form.Label>Submitter details</Form.Label>
                                <Form.Control name="name" type="input" value={currentOfficerReport.SubmitterName} readOnly></Form.Control>
                            </Row>
                            {/* <Row style={{ color: "red" }}>{formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}</Row> */}
                        </Form.Group>

                        <Form.Group controlId="email">
                            <Row>
                                <Form.Control name="email" type="input" value={currentOfficerReport.SubmitterEmail} readOnly></Form.Control>
                            </Row>
                            {/* <Row style={{ color: "red" }}>{formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}</Row> */}
                        </Form.Group>

                        {currentOfficerReport.Officer === "Treasurer" && (
                            <>
                            <div className="divider"></div>
                                <Form.Label>Income Total</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>£</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control name="incomeTotal" type="float" value={formik.values.incomeTotal} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.incomeTotal && formik.errors.incomeTotal}></Form.Control>
                                    <Form.Control.Feedback type="invalid">{formik.errors.incomeTotal}</Form.Control.Feedback>
                                </InputGroup>

                                <Form.Label>Expense Total</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>£</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control name="expenseTotal" type="float" value={formik.values.expenseTotal} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.expenseTotal && formik.errors.expenseTotal}></Form.Control>
                                    <Form.Control.Feedback type="invalid">{formik.errors.expenseTotal}</Form.Control.Feedback>
                                </InputGroup>

                                <Form.Label>Quarter Balance</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>£</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control name="quarterBalance" type="float" value={formik.values.quarterBalance} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.quarterBalance && formik.errors.quarterBalance}></Form.Control>
                                    <Form.Control.Feedback type="invalid">{formik.errors.quarterBalance}</Form.Control.Feedback>
                                </InputGroup>
                            </>
                        )}
                        <div className="divider"></div>
                        <Form.Group controlId="reportText">
                            <Row>
                                <Form.Label>Officer's report</Form.Label>
                                <Form.Control name="reportText" as="textarea" rows={8} value={formik.values.reportText} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.reportText && formik.errors.reportText}></Form.Control>
                                <Form.Control.Feedback type="invalid">{formik.errors.reportText}</Form.Control.Feedback>
                                <br />
                            </Row>
                            <Row>
                                <Button variant="outline-secondary" type="button" onClick={() => { formik.setFieldValue("reportText", currentOfficerReport.Detail, false); formik.setFieldValue("incomeTotal", currentOfficerReport.IncomeTotal, false); formik.setFieldValue("expenseTotal", currentOfficerReport.ExpenseTotal, false); formik.setFieldValue("quarterBalance", currentOfficerReport.QuarterBalance, false) }} disabled={validateChanges()}>revert</Button>
                            </Row>
                            {/* <Row style={{ color: "red" }}>{formik.touched.reportText && formik.errors.reportText ? <div>{formik.errors.reportText}</div> : null}</Row> */}
                        </Form.Group>
                        <ColouredLine color="grey" />
                        <Row>
                            <Button size="lg" onClick={() => props.setInOfficerEdit(false)} variant="secondary">
                                cancel
                            </Button>
                            </Row>
                            <div className="divider"></div>
                            <Row>
                            <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={validateChanges()}>
                                submit
                            </LoaderButton>
                        </Row>
                        <ColouredLine color="grey" />
                        <Row>
                        <LoaderButton size="lg" type="button" variant="danger" isLoading={isLoading} disabled={!checkDeleteEnable} onClick={() => deleteReport()}  >
                                delete
                            </LoaderButton>
                        </Row>
                        <Row>
				{["checkbox"].map((type) => (
					<div key={`default-${type}`} className="mb-3">
						<Form.Check
							type={type}
							id={`default-${type}`}
							checked={checkDeleteEnable}
							onChange={() => setCheckDeleteEnable((prev) => !prev)}
							label="tick for delete"></Form.Check>
					</div>
				))}
			</Row>
                    </Form>
                </div>
            </FadeIn>
        </>
    );
}

