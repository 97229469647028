import FadeIn from "react-fade-in";
import "./SubmitSuccess.css";
export default function SubmitSuccess() {
  return (
    <FadeIn delay="600">
      <>
        <h1 className="Thanks">
          Thank you,
          <br />
          your submission is complete
          <br />
        </h1>
        <h3>You may close the page or choose a new option from the NTIG Groups' Portal menu</h3>
        <br /> <br />
      </>
    </FadeIn>
  );
}
