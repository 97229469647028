import React from "react";
import DonationsTable from "../tables/DonationsTable";
import ExpensesTable from "../tables/ExpensesTable";
import { Chart } from "react-google-charts";
import "bootstrap/dist/css/bootstrap.min.css";
import "./forms.css"
import ColouredLine from "../../components/shapes/ColouredLine"


export default function IgTreasurerReportForm(props) {



  const data = [
    ["Period", "Income", "Expenses", "Balance"],
    [props.reportPeriod, props.incomeTotal, props.expenseTotal, parseFloat(props.startingBalance) + parseFloat(props.incomeTotal) - parseFloat(props.expenseTotal)]
  ];

  const options = {
    chart: {
      title: "NTIG Finance",
      subtitle: "Income, Expenses, and Balance: " + props.reportPeriod,
    },
  };


  return (
    <>
      <ColouredLine />
      <h2>Income</h2>
      <button type="button"
        onClick={() => {
          props.setEditIncome(true);
        }}
        className="button muted-button"
      >
        edit income
      </button>

      <DonationsTable reportDonations={props.reportDonations} editIncome={props.editIncome} incomeTotal={props.incomeTotal} reRenderIncome={props.reRenderIncome} />

      <ColouredLine />
      <h2>Expenses</h2>
      <button type="button"
        onClick={() => {
          props.setEditExpense(true);
        }}
        className="button muted-button"
      >
        edit expenses
      </button>
      <ExpensesTable reportExpenses={props.reportExpenses} editExpense={props.editExpense} expenseTotal={props.expenseTotal} reRenderExpense={props.reRenderExpense} />

      <ColouredLine />
      <h2>Balance</h2>
      <br />
      <h5>Quarter balance - £ {+(parseFloat(props.startingBalance) + parseFloat(props.incomeTotal) - parseFloat(props.expenseTotal).toPrecision(2))}</h5>


      <ColouredLine />
      <Chart
      chartType="Bar"
      width="90%"
      height="400px"
      data={data}
      options={options}

      
    />
    </>
  );
}




