import { useEffect, useState, useRef } from "react";
import getNtig from "../../API/appSync/ntig/getNtig";
import UpdateNtig from "../../API/appSync/ntig/updateNtig";
import IgOfficersTable from "../../components/tables/IgOfficersTable";
import EditOfficerForm from "../../components/forms/EditOfficerForm";


export default function Contacts() {
  const [isLoading, setIsLoading] = useState(true);
  const varPK = "Officers#2";
  const varSK = "Intergroup#NTIG";
  const type = "intergroupofficers";
  const [inEdit, setInEdit] = useState(); //const [editing, setEditing] = useState(false)
  const initialFormState = { id: null, Role: "", Name: "", Email: "", Phone: "", Start: "", End: "" };
  const [currentOfficer, setCurrentOfficer] = useState(initialFormState);
  const [officers, setOfficers] = useState([]);
  const refRoles = useRef(["Chairperson", "Vice Chair", "Treasurer", "Secretary", "Public Information", "Probation", "Health", "Employment", "ECLO", "Telephone Liaison Officer", "YPLO", "Armed Forces", "Share Magazine", "Archivist", "Literature"]);
  const refOfficers = useRef([]);

  useEffect(() => {
    (async () => {
      const apiOfficers = await getNtig(varPK, varSK, type);
      setOfficers(apiOfficers);
      setIsLoading(false)
    
    })()
  }, []);

  const reviewRow = (officer) => {
    //edit row
    setInEdit(true);

    setCurrentOfficer({ id: officer.id, Role: officer.Role, Name: officer.Name, Email: officer.Email, Phone: officer.Phone, Start: officer.Start, End: officer.End });
  };

  const reviewOfficer = (id, updatedOfficer) => {
    //const updateUser
    setOfficers(officers.map((officer) => (officer.id === id ? updatedOfficer : officer)));
    refOfficers.current = officers.map((officer) => (officer.id === id ? updatedOfficer : officer));

    let roles = [];
    let arrOfficers = [];
    //must set new array otherwise it cocks up the table

    //create list of roles
    for (let i in refOfficers.current) {
      roles.push(refOfficers.current[i].Role);
    }
    //update roles ref with list from updated officers
    refRoles.current = roles;

    //create new array to start creating officers JSON
    for (let i in refOfficers.current) {
      arrOfficers.push(refOfficers.current[i]);
    }

    function groupBy(objectArray, property) {
      return objectArray.reduce(function (acc, obj) {
        let key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    }

    let newresult = groupBy(arrOfficers, "Role");

    //create values for api send
    let officersJson = {};
    for (let i in refRoles.current) {
      officersJson[refRoles.current[i]] = newresult[refRoles.current[i]][0];
    }

    let subIntergroup = {
      PK: varPK,
      SK: varSK,
      Roles: refRoles.current,
      Officers: JSON.stringify(officersJson)
    };
    //alert(JSON.stringify(officersJson));
    UpdateNtig(subIntergroup);
    setInEdit(false);
  };
  return (
    <div className="flex-large">
      {inEdit ? (
        <div>
          <EditOfficerForm setInEdit={setInEdit} currentOfficer={currentOfficer} reviewOfficer={reviewOfficer} />
        </div>
      ) : (
        <div className="flex-row">
          <div className="flex-large">
            <h2>Intergroup Officers</h2>

            <IgOfficersTable officers={officers} reviewRow={reviewRow} isLoading={isLoading} />
          </div>
        </div>
      )}
    </div>
  );
}
