import Button from 'react-bootstrap/Button'
import "bootstrap/dist/css/bootstrap.min.css";
import "./BlockButton.css";

export default function BlockButton(props) {
  return (
    <>
      <Button style={{ width: '60%' }} {...props}>
        {props.children}
      </Button>
      <br />
      <br />
    </>
  );
}