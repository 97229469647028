/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNTIG = /* GraphQL */ `
  query GetNTIG($PK: String!, $SK: String!) {
    getNTIG(PK: $PK, SK: $SK) {
      PK
      SK
      Group
      Groups
      FullGroups
      Date
      Detail
      Donation
      Roles
      Period
      GSR
      Secretary
      Treasurer
      Submitter
      PaymentDate
      Officers
      Finance
    }
  }
`;
export const listNTIGS = /* GraphQL */ `
  query ListNTIGS(
    $filter: TableNTIGFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNTIGS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        PK
        SK
        Group
        Groups
        FullGroups
        Date
        Detail
        Donation
        Roles
        Period
        GSR
        Secretary
        Treasurer
        Submitter
        PaymentDate
        Officers
        Finance
      }
      nextToken
    }
  }
`;
