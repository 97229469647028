import { useUserContext } from "../../libs/context/userLib";

function ActiveGroupsList(props) {
  const { userContext } = useUserContext();
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Key</th>
            <th>Group</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.activeGroups.length > 0 ? (
            props.activeGroups.map((group) => (
              <tr key={props.activeKeys[group]}>
                <td>
                  {props.activeKeys[group]}
                </td>
                <td>
                  {group}
                </td>
                {userContext.userIsAdmin && (
                   <td>
                  <button
                    onClick={() => {
                      props.editRow(group)
                    }}
                    className="button muted-button"
                  >
                    edit
                  </button>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>We couldn't find any groups</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
export default ActiveGroupsList;
