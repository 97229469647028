import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";

export default function SecAdminCopyFnc(props) {
	return (
		<>
			<h3>mail functions</h3>
			<Row>
				<Button
					size="sm"
					variant="secondary"
					onClick={() => props.setSecCopyOpen(false)}>
					close mail functions
				</Button>
			</Row>
			<hr />
			<div style={{ textAlign: "center", fontWeight: "bold" }}>
				with a default email app set you can open an email directly
			</div>
			<div style={{ textAlign: "center" }}>
				otherwise, copy group contacts to your clipboard
			</div>
			<div style={{ textAlign: "center" }}>
				paste your clipboard contents directly into an email (use bcc where
				appropriate)
			</div>
			<hr />
			<Row>
				{/* <Form.Group as={Col}> */}
				<Dropdown as={ButtonGroup}>
					<Button
						as={Col}
						variant="outline-secondary"
						onClick={() =>
							(window.location = `mailto:?bcc=${props.addressLists.gsr}`)
						}>
						email GSRs
					</Button>
					<Dropdown.Toggle split variant="outline-secondary" />
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => {
								navigator.clipboard.writeText(props.addressLists.gsr);
							}}>
							copy GSRs to clipboard
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				{/* </Form.Group> */}
				{/* <Form.Group as={Col}> */}
				<div style={{ textAlign: "center", fontSize: "small" }}>
					GSR email addresses for active and temporarily closed groups. if no
					GSR is found the Secretary or the Treasurer address will be
					substituted.
				</div>
				{/* </Form.Group> */}
			</Row>
			<hr />
			<Row>
				{/* <Form.Group as={Col}> */}
				<Dropdown as={ButtonGroup}>
					<Button
						as={Col}
						variant="outline-secondary"
						onClick={() =>
							(window.location = `mailto:?bcc=${props.addressLists.tres}`)
						}>
						email Treasurers
					</Button>
					<Dropdown.Toggle split variant="outline-secondary" />
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => {
								navigator.clipboard.writeText(props.addressLists.tres);
							}}>
							copy Treasurers to clipboard
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				{/* </Form.Group> */}
				{/* <Form.Group as={Col}> */}
				<div style={{ textAlign: "center", fontSize: "small" }}>
					Treasurer email addresses for active and temporarily closed groups. if
					no Treasurer is found the GSR or the Secretary address will be
					substituted.
				</div>
				{/* </Form.Group> */}
			</Row>
			<hr />
			<Row>
				<Dropdown as={ButtonGroup}>
					<Button
						as={Col}
						variant="outline-secondary"
						onClick={() =>
							(window.location = `mailto:?bcc=${props.addressLists.sec}`)
						}>
						email Secretaries
					</Button>
					<Dropdown.Toggle split variant="outline-secondary" />
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => {
								navigator.clipboard.writeText(props.addressLists.sec);
							}}>
							copy Secretaries to clipboard
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				<div style={{ textAlign: "center", fontSize: "small" }}>
					Secretary email addresses for active and temporarily closed groups. if
					no Secretary is found the GSR or the Treasurer address will be
					substituted.
				</div>
			</Row>
		</>
	);
}
