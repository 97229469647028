import "./IgOfficersTable.css";

function DonationsTable(props) {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Group</th>
            <th>Payment Date</th>
            <th>Amount</th>
            <th></th>
            {props.editIncome && (<td></td>)}
          </tr>
        </thead>
        <tbody>
          {props.reportDonations.length > 0 ? (
            props.reportDonations.map((donation) => (
              <tr key={donation.Id}>
                {/* <td>{donation.Id}</td> */}
                <td>{donation.Group}</td>
                <td>{donation.Date}</td>
                <td>£ {donation.Donation}</td>
                {props.editIncome && (
                  <td> <button type="button"
                    onClick={() => {
                      props.reRenderIncome(donation);
                    }}
                    className="button muted-button"
                  >
                    delete
                  </button>
                  </td>)}
              </tr>
            )
            )
          ) : (
            <tr>
              <td colSpan={5}>Couldn't find any donations</td>
            </tr>
          )}
        </tbody>
      </table>
      {props.reportDonations.length > 0 && (
        <h5>
          <br />
          Total Income - £ {props.incomeTotal}
        </h5>
      )}
    </>
  );
}

export default DonationsTable;
