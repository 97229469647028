import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import { useState } from "react";
import { object, string } from 'yup'

const EditOfficerForm = (props) => {
  let officer = useState(props.currentOfficer);

  // useEffect(() => {
  //   setOfficer(props.currentOfficer);
  // }, [props]);

  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const donationDateRegex = /(0?[1-9]|[12]\d|30|31)[^\w\d\r\n:](0?[1-9]|1[0-2])[^\w\d\r\n:]\d{4}/;
  const validationSchema = object().shape({
    officerName: string().min(2, "Minimum two characters").max(15, "Maximum fifteen characters"),
    officerEmail: string().email("Invalid email format"),
    officerPhone: string().matches(phoneRegex, "Enter a valid phone number"),
    officerStart: string().matches(donationDateRegex, "Format: dd/mm/yyyy"),
    officerEnd: string().matches(donationDateRegex, "Format: dd/mm/yyyy")
  });

  const formik = useFormik({
    initialValues: {
      officerName: props.currentOfficer.Name,
      officerEmail: props.currentOfficer.Email,
      officerPhone: props.currentOfficer.Phone,
      officerStart: props.currentOfficer.Start,
      officerEnd: props.currentOfficer.End
    },

    validationSchema,
    onSubmit: (values) => {
      officer = { id: props.currentOfficer.id, Role: props.currentOfficer.Role, Name: formik.values.officerName, Email: formik.values.officerEmail, Phone: formik.values.officerPhone, Start: formik.values.officerStart, End: formik.values.officerEnd };
      //let updatedOfficer = { id: props.currentOfficer.id, Role: props.currentOfficer.Role, Name: formik.values.officerName, Email: formik.values.officerEmail, Phone: formik.values.officerPhone, Start: formik.values.officerStart, End: formik.values.officerEnd };

      props.reviewOfficer(props.currentOfficer.id, officer);
    }
  });

  return (
    <>
      <div className="EditOfficerForm">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Form.Group>
              <h2>Update the selected officer's details</h2>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} sm={5}>
              <label>Position</label>
              <Form.Control type="input" name="offRole" size="sm" value={props.currentOfficer.Role || ""} readOnly />
            </Form.Group>

            <Form.Group controlId="officerStart" as={Col} sm={2}>
              <label>Date commenced</label>
              <Form.Control name="officerStart" type="input" size="sm" value={formik.values.officerStart || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.officerStart && formik.errors.officerStart} />
              <Form.Control.Feedback type="invalid">{formik.errors.officerStart}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="officerEnd" as={Col} sm={2}>
              <label>Date ending</label>
              <Form.Control name="officerEnd" type="input" size="sm" value={formik.values.officerEnd || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.officerEnd && formik.errors.officerEnd} />
              <Form.Control.Feedback type="invalid">{formik.errors.officerEnd}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group controlId="officerName" as={Col}>
              <label>Name</label>
              <Form.Control name="officerName" type="input" size="sm" value={formik.values.officerName || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.officerName && formik.errors.officerName} />
              <Form.Control.Feedback type="invalid">{formik.errors.officerName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="officerEmail" as={Col}>
              <label>Email</label>
              <Form.Control name="officerEmail" type="email" size="sm" value={formik.values.officerEmail || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.officerEmail && formik.errors.officerEmail} />
              <Form.Control.Feedback type="invalid">{formik.errors.officerEmail}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="officerPhone" as={Col}>
              <label>Phone Number</label>
              <Form.Control name="officerPhone" type="input" size="sm" value={formik.values.officerPhone || ""} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.officerPhone && formik.errors.officerPhone} />
              <Form.Control.Feedback type="invalid">{formik.errors.officerPhone}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <hr />
          <Button variant="success" type="submit" >Save changes</Button>
          
          {/* <button size="lg" type="submit" variant="secondary">
            Save changes
          </button> */}
          <div className="divider"></div>
          <Button variant="secondary" type="submit" onClick={() => props.setInEdit(false)}>Cancel</Button>
          {/* <button className="button muted-button" onClick={() => props.setInEdit(false)}>
            Cancel
          </button> */}
        </Form>
      </div>
    </>
  );
};

export default EditOfficerForm;
