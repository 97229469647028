import { useState } from "react"
import { useGroupsContext } from "../../libs/context/groupsLib";
import ActiveGroupsList from "../../components/tables/ActiveGroupsTable";
import ActiveGroupAmendForm from "../../components/forms/ActiveGroupAmendForm";
import AddGroupForm from "../../components/forms/AddGroupForm";
import API, { graphqlOperation } from "@aws-amplify/api"
import * as queries from "../../graphql/queries"
import { onError } from "../../libs/userFeedback/errorLib";
import ColouredLine from "../../components/shapes/ColouredLine"

export default function ActiveGroups() {
  const [inEdit, setInEdit] = useState(false)
  const initialFormState = { id: null, Name: "", State: "Active" };
  const [currentGroup, setCurrentGroup] = useState(initialFormState);
  const { groupsContext } = useGroupsContext()
  const { setGroupsContext } = useGroupsContext()
  var groupsList = []
  for (let i in groupsContext.FullGroups) {
    if (groupsContext.FullGroups[i].Status === "Active") {
      groupsList.push(groupsContext.FullGroups[i].Name)
    }
  }
  groupsList.sort()

  async function updateGroupsContext() {
    try {
      const apiGroups = await API.graphql(graphqlOperation(queries.getNTIG, { PK: "Status", SK: "Test" }));
      const fullGroups = JSON.parse(apiGroups.data.getNTIG.FullGroups)

      const groupKeys = Object.keys(JSON.parse(apiGroups.data.getNTIG.FullGroups))
      const activeGroups = {}
      const activeKeys = {}
      const inactiveGroups = {}
      const inactiveKeys = {}
      for (let i in groupKeys) {
        if (fullGroups[groupKeys[i]].Status === "Active" || fullGroups[groupKeys[i]].Status === "Temporarily Closed") {
          activeGroups[groupKeys[i]] = fullGroups[groupKeys[i]].Name
          activeKeys[fullGroups[groupKeys[i]].Name] = groupKeys[i]
        }
        if (fullGroups[groupKeys[i]].Status === "Closed") {
          inactiveGroups[groupKeys[i]] = fullGroups[groupKeys[i]].Name
          inactiveKeys[fullGroups[groupKeys[i]].Name] = groupKeys[i]
        }
        activeGroups["00"] = "-select your group-"
        activeKeys["-select your group"] = "00"
      }
      setGroupsContext({ GroupKeys: groupKeys, FullGroups: fullGroups, ActiveGroups: activeGroups, InactiveGroups: inactiveGroups, ActiveKeys: activeKeys, InactiveKeys: inactiveKeys })

    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }
    window.location.reload(true)
  }


  const editGroup = (id) => {
    setInEdit(false);
    updateGroupsContext()
  }



  const editRow = (group) => {
    setInEdit(true)
    setCurrentGroup({ id: groupsContext.ActiveKeys[group], Group: group, State: "Active" })
  }

  return (
    <div className="flex-large">
      {inEdit ? (
        <ActiveGroupAmendForm setInEdit={setInEdit} currentGroup={currentGroup} editGroup={editGroup} />
      ) : (
        <>
          <AddGroupForm />
          <ColouredLine color="grey"/>
          <h1>Active Groups</h1>
          <ActiveGroupsList activeGroups={groupsList} activeKeys={groupsContext.ActiveKeys} editRow={editRow} />
        </>
      )}
    </div>
  )
}
