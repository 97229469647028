import ColouredLine from "../../shapes/ColouredLine"

export default function ActiveGroupAmendForm(props) {
    const group = props.group
    return (
        <>
            <div className="ActiveGroupAmend">
                <h2>{group}</h2>
                <ColouredLine color="grey" />
            </div>
        </>
    )
}

