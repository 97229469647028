import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import LoaderButton from "../../components/buttons/LoaderButton";
import "./Contacts.css";
import TosAlert from "../../libs/userFeedback/libTosAlert";
import createNtig from "../../API/appSync/ntig/createNtig";
import { useGroupsContext } from "../../libs/context/groupsLib";
import { useUserContext } from "../../libs/context/userLib";
import { useNavigate } from "react-router-dom";
import FadeIn from "react-fade-in";

export default function Contacts() {
  const navigate = useNavigate();
  const { userContext } = useUserContext()
  const { groupsContext } = useGroupsContext()
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiError, setApiError] = useState(false);
  const [errorLoc, setErrorLoc] = useState("");

  var groupsList = []
  groupsList = Array.from(Object.values(groupsContext.ActiveGroups))
  groupsList.sort()

  function completeSubmit() {
    setIsLoading(false);
    navigate("/submitsuccess");
  }

  function apiCallError(error) {
    setIsLoading(false);
    setApiError(true);
    setErrorMessage(error);
    //onError(JSON.stringify(error.errors[0].errorType));
  }


  function validateNotEmpty() {
    return formik.values.group !== "-select your group-" && (formik.values.secName.length > 0 || formik.values.secEmail.length > 0 || formik.values.secPhone.length > 0 || formik.values.tresName.length > 0 || formik.values.tresEmail.length > 0 || formik.values.tresPhone.length > 0 || formik.values.gsrName.length > 0 || formik.values.gsrEmail.length > 0 || formik.values.gsrPhone.length > 0);
  }

  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const validationSchema = Yup.object().shape({
    group: Yup.string().test("groupTest", "You must select a group", (group) => group !== "-select your group-"),
    secName: Yup.string().min(2, "Minimum two characters").max(15, "Maximum fifteen characters"),
    secEmail: Yup.string().email("Invalid email format"),
    secPhone: Yup.string().matches(phoneRegex, "Enter a valid phone number"),
    tresName: Yup.string().min(2, "Minimum two characters").max(15, "Maximum fifteen characters"),
    tresEmail: Yup.string().email("Invalid email format"),
    tresPhone: Yup.string().matches(phoneRegex, "Enter a valid phone number"),
    gsrName: Yup.string().min(2, "Minimum two characters").max(15, "Maximum fifteen characters"),
    gsrEmail: Yup.string().email("Invalid email format"),
    gsrPhone: Yup.string().matches(phoneRegex, "Enter a valid phone number"),
    tos: Yup.bool().oneOf([true], "You must accept the terms of service")
  });

  const formik = useFormik({
    initialValues: {
      group: "-select your group-",
      secName: "",
      secEmail: "",
      secPhone: "",
      tresName: "",
      tresEmail: "",
      tresPhone: "",
      gsrName: "",
      gsrEmail: "",
      gsrPhone: "",
      tos: false
    },

    validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      // console.log(JSON.stringify(values, null, 2));

      var sendSecName = formik.values.secName;
      var sendSecEmail = formik.values.secEmail;
      var sendSecPhone = formik.values.secPhone;
      var sendTresName = formik.values.tresName;
      var sendTresEmail = formik.values.tresEmail;
      var sendTresPhone = formik.values.tresPhone;
      var sendGsrName = formik.values.gsrName;
      var sendGsrEmail = formik.values.gsrEmail;
      var sendGsrPhone = formik.values.gsrPhone;
      var makeDatestamp = new Date();
      var year = makeDatestamp.getFullYear();
      var month = makeDatestamp.getMonth() + 1; //months start at 0
      var day = makeDatestamp.getDate();

      var datestamp = day + "/" + month + "/" + year;

      var submitter = '{"Email": "' + userContext.user + '","Name": "' + userContext.userName + '"}';

      //make null entries consistent
      if (formik.values.secName === "secretary's name" || formik.values.secName === "") {
        sendSecName = "-none-";
      } else {
        sendSecName = formik.values.secName;
      }
      if (formik.values.secEmail === "secretary's email" || formik.values.secEmail === "") {
        sendSecEmail = "-none-";
      } else {
        sendSecEmail = formik.values.secEmail;
      }
      if (formik.values.secPhone === "secretary's phone" || formik.values.secEmail === "") {
        sendSecPhone = "-none-";
      } else {
        sendSecPhone = formik.values.secPhone;
      }
      if (formik.values.tresName === "Treasurer's name" || formik.values.tresName === "") {
        sendTresName = "-none-";
      } else {
        sendTresName = formik.values.tresName;
      }
      if (formik.values.tresEmail === "Treasurer's email" || formik.values.tresEmail === "") {
        sendTresEmail = "-none-";
      } else {
        sendTresEmail = formik.values.tresEmail;
      }
      if (formik.values.tresPhone === "Treasurer's phone" || formik.values.tresPhone === "") {
        sendTresPhone = "-none-";
      } else {
        sendTresPhone = formik.values.tresPhone;
      }
      if (formik.values.gsrName === "gsr's name" || formik.values.gsrName === "") {
        sendGsrName = "-none-";
      } else {
        sendGsrName = formik.values.gsrName;
      }
      if (formik.values.gsrEmail === "gsr's email" || formik.values.gsrEmail === "") {
        sendGsrEmail = "-none-";
      } else {
        sendGsrEmail = formik.values.gsrEmail;
      }
      if (formik.values.gsrPhone === "gsr's phone" || formik.values.gsrPhone === "") {
        sendGsrPhone = "-none-";
      } else {
        sendGsrPhone = formik.values.gsrPhone;
      }

      var secJson = '{"Email": "' + sendSecEmail + '","Name": "' + sendSecName + '","Phone": "' + sendSecPhone + '"}';
      var gsrJson = '{"Email": "' + sendGsrEmail + '","Name": "' + sendGsrName + '","Phone": "' + sendGsrPhone + '"}';
      var tresJson = '{"Email": "' + sendTresEmail + '","Name": "' + sendTresName + '","Phone": "' + sendTresPhone + '"}';

      //create JSON for graphql updateNtig submits contact details
      var subContact = {
        PK: "Officers#1",
        SK: `Group#${groupsContext.ActiveKeys[formik.values.group]}`,
        Date: datestamp,
        GSR: gsrJson,
        Secretary: secJson,
        Treasurer: tresJson,
        Submitter: submitter
      };

      //submit contacts with a #1 status
      const contactSubmission = async () => {
        await createNtig(subContact)
          .then((response) => {
            if (response === true) {
              completeSubmit();
            } else {
              setErrorLoc("Contacts - contactSubmission()");
              apiCallError(JSON.stringify(response.errors[0].errorType));
            }
          })
        //.catch((e) => { })
      }
      contactSubmission();
    }
  })

  function renderApiCallError() {
    function handleClose() {
      setApiError(false);
    }
    return (
      <div className="GroupQtrlyReport">
        <FadeIn>
          <h3>
            Sorry, there's been an error. <br />
            <br />
            We couldn't tell what the error was. If the error persists please notify us. <br />
            <br />
          </h3>
          <div>
            Your entries will be visible when you close the page, in case you need to keep any details.
            <br />
            <br />
          </div>
          <Form>
            <Form.Label>Error for notification</Form.Label>
            <Form.Control type="input" value={errorLoc} readOnly></Form.Control>
            <Form.Control type="input" value={errorMessage} readOnly></Form.Control>
            <Form.Control type="input" value={userContext.user} readOnly></Form.Control>
            <LoaderButton size="lg" variant="secondary" isLoading={isLoading} onClick={handleClose}>
              close page
            </LoaderButton>
          </Form>
        </FadeIn>
      </div>
    );
  }

  function renderContactsForm() {
    // if (hasError) {
    //   return <div>Error: {errorMessage}</div>;
    // } else if (isLoading) {
    //   return <div>Loading...</div>;
    // } else {
    return (
      <FadeIn transitionDuration="500">
        <div className="Contacts">
          <h2>tell us the changes</h2>
          <Form onSubmit={formik.handleSubmit}>
          <div className="divider"></div>
            <Form.Group controlId="group">
              <Row>
                <Form.Label>Your group</Form.Label>
                <Form.Control name="group" as="select" value={formik.values.group} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.group && formik.errors.group}>
                  {groupsList.map((option) => {
                    return (
                      <option value={option} key={groupsContext.ActiveKeys[option]}>
                        {option}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.group}</Form.Control.Feedback>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.group && formik.errors.group ? <div>{formik.errors.group}</div> : null}</Row> */}
            </Form.Group>
            <div className="divider"></div>
            <Form.Group controlId="name">
              <Row>
                <Form.Label>Your details</Form.Label>
                <Form.Control name="name" type="input" value={userContext.userName} readOnly></Form.Control>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}</Row> */}
            </Form.Group>

            <Form.Group controlId="email">
              <Row>
                <Form.Control name="email" type="input" value={userContext.user} readOnly></Form.Control>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}</Row> */}
            </Form.Group>
            <div className="divider"></div>
            <Form.Group controlId="secName">
              <Row>
                <Form.Label>Group Secretary's details</Form.Label>
                <Form.Control name="secName" type="input" placeholder="secretary's name" value={formik.values.secName} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.secName && formik.errors.secName}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.secName}</Form.Control.Feedback>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.secName && formik.errors.secName ? <div>{formik.errors.secName}</div> : null}</Row> */}
            </Form.Group>
            
            <Form.Group controlId="secEmail">
              <Row>
                <Form.Control name="secEmail" type="email" placeholder="secretary's email" value={formik.values.secEmail} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.secEmail && formik.errors.secEmail}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.secEmail}</Form.Control.Feedback>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.secEmail && formik.errors.secEmail ? <div>{formik.errors.secEmail}</div> : null}</Row> */}
            </Form.Group>
            
            <Form.Group controlId="secPhone">
              <Row>
                <Form.Control name="secPhone" type="phone" placeholder="secretary's phone number" value={formik.values.secPhone} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.secPhone && formik.errors.secPhone}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.secPhone}</Form.Control.Feedback>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.secPhone && formik.errors.secPhone ? <div>{formik.errors.secPhone}</div> : null}</Row> */}
            </Form.Group>
            <div className="divider"></div>
            <Form.Group controlId="tresName">
              <Row>
                <Form.Label>Group Treasurer's details</Form.Label>
                <Form.Control name="tresName" type="input" placeholder="treasurer's name" value={formik.values.tresName} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.tresName && formik.errors.tresName}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.tresName}</Form.Control.Feedback>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.tresName && formik.errors.tresName ? <div>{formik.errors.tresName}</div> : null}</Row> */}
            </Form.Group>

            <Form.Group controlId="tresEmail">
              <Row>
                <Form.Control name="tresEmail" type="email" placeholder="treasurer's email" value={formik.values.tresEmail} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.tresEmail && formik.errors.tresEmail}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.tresEmail}</Form.Control.Feedback>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.tresEmail && formik.errors.tresEmail ? <div>{formik.errors.tresEmail}</div> : null}</Row> */}
            </Form.Group>

            <Form.Group controlId="tresPhone">
              <Row>
                <Form.Control name="tresPhone" type="phone" placeholder="treasurer's phone number" value={formik.values.tresPhone} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.tresPhone && formik.errors.tresPhone}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.tresPhone}</Form.Control.Feedback>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.tresPhone && formik.errors.tresPhone ? <div>{formik.errors.tresPhone}</div> : null}</Row> */}
            </Form.Group>
            <div className="divider"></div>
            <Form.Group controlId="gsrName">
              <Row>
                <Form.Label>GSR's details</Form.Label>
                <Form.Control name="gsrName" type="input" placeholder="gsr's name" value={formik.values.gsrName} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.gsrName && formik.errors.gsrName}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.gsrName}</Form.Control.Feedback>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.gsrName && formik.errors.gsrName ? <div>{formik.errors.gsrName}</div> : null}</Row> */}
            </Form.Group>

            <Form.Group controlId="gsrEmail">
              <Row>
                <Form.Control name="gsrEmail" type="email" placeholder="gsr's email" value={formik.values.gsrEmail} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.gsrEmail && formik.errors.gsrEmail}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.gsrEmail}</Form.Control.Feedback>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.gsrEmail && formik.errors.gsrEmail ? <div>{formik.errors.gsrEmail}</div> : null}</Row> */}
            </Form.Group>

            <Form.Group controlId="gsrPhone">
              <Row>
                <Form.Control name="gsrPhone" type="phone" placeholder="gsr's phone number" value={formik.values.gsrPhone} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.gsrPhone && formik.errors.gsrPhone}></Form.Control>
                <Form.Control.Feedback type="invalid">{formik.errors.gsrPhone}</Form.Control.Feedback>
              </Row>
              {/* <Row style={{ color: "red" }}>{formik.touched.gsrPhone && formik.errors.gsrPhone ? <div>{formik.errors.gsrPhone}</div> : null}</Row> */}
            </Form.Group>
            <div className="divider"></div>
            <Form.Group controlId="tos">
              <Row>
                {["radio"].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check
                      name="tos"
                      type={type}
                      id={`default-${type}`}
                      checked={formik.values.tos}
                      value="1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={
                        <>
                          I accept the <Button variant="link" onClick={TosAlert}>Terms of Service</Button>
                          {/* I accept the <Link onClick={TosAlert}>{"Terms of Service"}</Link> */}
                        </>
                      }
                    ></Form.Check>

                  </div>
                ))}
              </Row>
              <Row style={{ color: "red" }}>{formik.touched.tos && formik.errors.tos ? <div>{formik.errors.tos}</div> : null}</Row>
            </Form.Group>

            <Row>
              <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={!validateNotEmpty()}>
                submit
              </LoaderButton>
            </Row>
          </Form>
        </div>
      </FadeIn>
    );
  }
  //}
  return <div className="GroupQtrlyReport">{apiError === true ? renderApiCallError() : renderContactsForm()}</div>;
}