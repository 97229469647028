import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import InputGroup from "react-bootstrap/InputGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState, useRef, useEffect } from "react";
import LoaderButton from "../../components/buttons/LoaderButton";
import "./GroupQtrlyForm.css";
import TosAlert from "../../libs/userFeedback/libTosAlert";
import getNtig from "../../API/appSync/ntig/getNtig";
import createNtig from "../../API/appSync/ntig/createNtig";
import notifySec from "../../API/appSync/ntig/notifySec"
import { useUserContext } from "../../libs/context/userLib";
import { useNavigate } from "react-router-dom";
import FadeIn from "react-fade-in";
import { useGroupsContext } from "../../libs/context/groupsLib";

export default function GroupQuarterlyReportForm() {
  const navigate = useNavigate();
  const { userContext } = useUserContext()
  const { groupsContext } = useGroupsContext()
  const [copyReport, setCopyReport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorLoc, setErrorLoc] = useState("");
	const [title, setTitle] = useState("complete your report");
	const reportingPeriod = useRef()

  useEffect(() => {
	(async () => {
		const data = await getNtig("PortalAdmin#Secretary", "ReportingPeriod", "adminSecQtr");
  reportingPeriod.current = data
	})();
}, []);
	
	
  var groupsList = []
  groupsList = Array.from(Object.values(groupsContext.ActiveGroups))
  groupsList.sort()


  function changeCopyReport() {
    if (copyReport === false) {
      setCopyReport(true);
    } else {
      setCopyReport(false);
    }
  }

  function formValidate() {
    return formik.values.group !== "-select your group-" && formik.values.reportText.length > 0 && formik.values.donation.length > 0 && formik.isValid;
  }

  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const amountRegex = /^\d+(\.\d{1,2})?$/;
  const validationSchema = Yup.object().shape({
    group: Yup.string().test("groupTest", "You must select a group", (group) => group !== "-select your group-"),
    secName: Yup.string().min(2, "Minimum two characters").max(15, "Maximum fifteen characters"),
    secEmail: Yup.string().email("Invalid email format"),
    secPhone: Yup.string().matches(phoneRegex, "Enter a valid phone number"),
    tresName: Yup.string().min(2, "Minimum two characters").max(15, "Maximum fifteen characters"),
    tresEmail: Yup.string().email("Invalid email format"),
    tresPhone: Yup.string().matches(phoneRegex, "Enter a valid phone number"),
    gsrName: Yup.string().min(2, "Minimum two characters").max(15, "Maximum fifteen characters"),
    gsrEmail: Yup.string().email("Invalid email format"),
    gsrPhone: Yup.string().matches(phoneRegex, "Enter a valid phone number"),
    reportText: Yup.string().required("Required"),
    donation: Yup.string().required("Required").matches(amountRegex, "Enter a valid amount"),
    tos: Yup.bool().oneOf([true], "You must accept the terms of service")
  });

  function completeSubmit() {
    setIsLoading(false);
    navigate("/submitsuccess");
  }

  function apiCallError(error) {
    setIsLoading(false);
    setApiError(true);
    setErrorMessage(error);
    setTitle("Your Report");
    //onError(JSON.stringify(error.errors[0].errorType));
  }

  const formik = useFormik({
    initialValues: {
      group: "",
      secName: "",
      secEmail: "",
      secPhone: "",
      tresName: "",
      tresEmail: "",
      tresPhone: "",
      gsrName: "",
      gsrEmail: "",
      gsrPhone: "",
      reportText: "",
      donation: "",
      tos: false
    },

    validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);

      var sendSecName = formik.values.secName;
      var sendSecEmail = formik.values.secEmail;
      var sendSecPhone = formik.values.secPhone;
      var sendTresName = formik.values.tresName;
      var sendTresEmail = formik.values.tresEmail;
      var sendTresPhone = formik.values.tresPhone;
      var sendGsrName = formik.values.gsrName;
      var sendGsrEmail = formik.values.gsrEmail;
      var sendGsrPhone = formik.values.gsrPhone;

      var makeDatestamp = new Date();
      var year = makeDatestamp.getFullYear();
      var month = makeDatestamp.getMonth() + 1; //months start at 0
      var day = makeDatestamp.getDate();

      var datestamp = day + "/" + month + "/" + year;
      var submitter = '{"Email": "' + userContext.user + '","Name": "' + userContext.userName + '"}';

      //make null entries consistent
      if (formik.values.secName === "secretary's name" || formik.values.secName === "") {
        sendSecName = "-none-";
      } else {
        sendSecName = formik.values.secName;
      }
      if (formik.values.secEmail === "secretary's email" || formik.values.secEmail === "") {
        sendSecEmail = "-none-";
      } else {
        sendSecEmail = formik.values.secEmail;
      }
      if (formik.values.secPhone === "secretary's phone" || formik.values.secEmail === "") {
        sendSecPhone = "-none-";
      } else {
        sendSecPhone = formik.values.secPhone;
      }
      if (formik.values.tresName === "Treasurer's name" || formik.values.tresName === "") {
        sendTresName = "-none-";
      } else {
        sendTresName = formik.values.tresName;
      }
      if (formik.values.tresEmail === "Treasurer's email" || formik.values.tresEmail === "") {
        sendTresEmail = "-none-";
      } else {
        sendTresEmail = formik.values.tresEmail;
      }
      if (formik.values.tresPhone === "Treasurer's phone" || formik.values.tresPhone === "") {
        sendTresPhone = "-none-";
      } else {
        sendTresPhone = formik.values.tresPhone;
      }
      if (formik.values.gsrName === "gsr's name" || formik.values.gsrName === "") {
        sendGsrName = "-none-";
      } else {
        sendGsrName = formik.values.gsrName;
      }
      if (formik.values.gsrEmail === "gsr's email" || formik.values.gsrEmail === "") {
        sendGsrEmail = "-none-";
      } else {
        sendGsrEmail = formik.values.gsrEmail;
      }
      if (formik.values.gsrPhone === "gsr's phone" || formik.values.gsrPhone === "") {
        sendGsrPhone = "-none-";
      } else {
        sendGsrPhone = formik.values.gsrPhone;
      }

      var secJson = '{"Email": "' + sendSecEmail + '","Name": "' + sendSecName + '","Phone": "' + sendSecPhone + '"}';
      var gsrJson = '{"Email": "' + sendGsrEmail + '","Name": "' + sendGsrName + '","Phone": "' + sendGsrPhone + '"}';
      var tresJson = '{"Email": "' + sendTresEmail + '","Name": "' + sendTresName + '","Phone": "' + sendTresPhone + '"}';

      //create JSON for graphql createNtig submits group report
      var subReport = {
        PK: `Report#${reportingPeriod.current}#2`,
        SK: `Group#${groupsContext.ActiveKeys[formik.values.group]}`,
        // SK: `Report#2020/Q3#2`,

		Date: datestamp,
        Period: reportingPeriod.current,
        Detail: formik.values.reportText,
        Donation: formik.values.donation,
        Submitter: submitter
      };

      //create JSON for graphql createNtig to notify sec
      var secNotifyJson = {
        Type: "groupqtrcall",
        GroupId: groupsContext.ActiveKeys[formik.values.group],
        Group: formik.values.group,
        Date: datestamp,
        Detail: formik.values.reportText,
        Donation: formik.values.donation,
        Period: reportingPeriod.current,
        GSR: gsrJson,
        Secretary: secJson,
        Treasurer: tresJson,
        Submitter: submitter,
        CopyReport: copyReport
      };

      //create JSON for graphql updateNtig submits contact details
      var subContact = {
        PK: "Officers#1",
        SK: `Group#${groupsContext.ActiveKeys[formik.values.group]}`,
        Date: datestamp,
        GSR: gsrJson,
        Secretary: secJson,
        Treasurer: tresJson,
        Submitter: submitter
      };

      //submit contacts with a #1 status
      const contactsSubmission = async () => {
        await createNtig(subContact)
          .then((contactsSub) => {
            if (contactsSub === true) {
              completeSubmit()
            } else {
              setErrorLoc("Qrtly Report - contactsSubmission()");
              apiCallError(JSON.stringify(contactsSub.errors[0].errorType));
            }
          })
        //.catch((e) => { })
      }

      //notify secretary
      const sendToSec = async () => {
        await notifySec(secNotifyJson)
          .then((notSec) => {
            if (notSec === true) {
              if (formik.values.secName !== "" && formik.values.secEmail !== "" && formik.values.secPhone !== "" && formik.values.tresName !== "" && formik.values.tresEmail !== "" && formik.values.tresPhone !== "" && formik.values.gsrName !== "" && formik.values.gsrEmail !== "" && formik.values.gsrPhone !== "") {
                contactsSubmission();
              } else {
                completeSubmit();

              }
            } else {
              setErrorLoc("Qrtly Report - notifySec()");
              apiCallError(JSON.stringify(notSec.errors[0].errorType));
            }
          })
        //.catch((e) => { })
      };

      // submit group report
      const reportSubmission = async () => {
        await createNtig(subReport)
          .then((reportSub) => {
            if (reportSub === true) {
              sendToSec()
            } else {
              setErrorLoc("Qrtly Report - reportSubmission()");
              apiCallError(JSON.stringify(reportSub.errors[0].errorType));
            }
          })
        //.catch((e) => { })
      }
      reportSubmission();
    }
  });


  function renderApiCallError() {
    function handleClose() {
      setApiError(false);
    }
    return (
      <div className="GroupQtrlyReport">
        <FadeIn>
          <h3>
            Sorry, there's been an error. <br />
            <br />
          </h3>
        </FadeIn>
        {errorMessage === '"DynamoDB:ConditionalCheckFailedException"' ? (
          <FadeIn>
            <h3>
              This is likely because we already have a report, for your group, for this quarter. <br />
              <br />
            </h3>
            <div>
              You may close this page. Your report details will be visible in case you need to keep any details.
              <br />
              <br />
            </div>
          </FadeIn>
        ) : (
          <FadeIn>
            <h3>
              We couldn't tell what the error was. If the error persists please notify us. <br />
              <br />
            </h3>
            <div>
              Your report details will be visible when you close the page, in case you need to keep any details.
              <br />
              <br />
            </div>
          </FadeIn>
        )}
        <FadeIn>
          <Form>
            <Form.Label>Error for notification</Form.Label>
            <Form.Control type="input" value={errorLoc} readOnly></Form.Control>
            <Form.Control type="input" value={errorMessage} readOnly></Form.Control>
            <Form.Control type="input" value={userContext.user} readOnly></Form.Control>
            <LoaderButton size="lg" variant="secondary" isLoading={isLoading} onClick={handleClose}>
              close page
            </LoaderButton>
          </Form>
        </FadeIn>
      </div>
    );
  }

  function renderQrtReportForm() {
    return (
      <>
        <FadeIn transitionDuration="500">
          <div className="GroupQtrlyReport">
            <h2>{title}</h2>
            <Form onSubmit={formik.handleSubmit}>
            <div className="divider"></div>
              <Form.Group controlId="group">
                <Row>
                  <Form.Label>Your group</Form.Label>
                  <Form.Control autoFocus name="group" as="select" value={formik.values.group} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.group && formik.errors.group}>
                    {groupsList.map((option) => {
                      return (
                        <option value={option} key={groupsContext.ActiveKeys[option]}>
                          {option}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.group}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.group && formik.errors.group ? <div>{formik.errors.group}</div> : null}</Row> */}
              </Form.Group>
              <div className="divider"></div>
              <Form.Group controlId="name">
                <Row>
                  <Form.Label>Your details</Form.Label>
                  <Form.Control name="name" type="input" value={userContext.userName} readOnly></Form.Control>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}</Row> */}
              </Form.Group>

              <Form.Group controlId="email">
                <Row>
                  <Form.Control name="email" type="input" value={userContext.user} readOnly></Form.Control>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}</Row> */}
              </Form.Group>

              <Row>
                {["checkbox"].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check type={type} id={`default-${type}`} checked={copyReport} onChange={changeCopyReport} label="Tick to receive a copy of your report"></Form.Check>
                  </div>
                ))}
              </Row>

              <Form.Group controlId="secName">
                <Row>
                  <Form.Label>Group Secretary's details</Form.Label>
                  <Form.Control name="secName" type="input" placeholder="secretary's name" value={formik.values.secName} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.secName && formik.errors.secName}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.secName}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.secName && formik.errors.secName ? <div>{formik.errors.secName}</div> : null}</Row> */}
              </Form.Group>

              <Form.Group controlId="secEmail">
                <Row>
                  <Form.Control name="secEmail" type="email" placeholder="secretary's email" value={formik.values.secEmail} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.secEmail && formik.errors.secEmail}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.secEmail}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.secEmail && formik.errors.secEmail ? <div>{formik.errors.secEmail}</div> : null}</Row> */}
              </Form.Group>

              <Form.Group controlId="secPhone">
                <Row>
                  <Form.Control name="secPhone" type="phone" placeholder="secretary's phone number" value={formik.values.secPhone} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.secPhone && formik.errors.secPhone}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.secPhone}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.secPhone && formik.errors.secPhone ? <div>{formik.errors.secPhone}</div> : null}</Row> */}
              </Form.Group>
              <div className="divider"></div>
              <Form.Group controlId="tresName">
                <Row>
                  <Form.Label>Group Treasurer's details</Form.Label>
                  <Form.Control name="tresName" type="input" placeholder="treasurer's name" value={formik.values.tresName} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.tresName && formik.errors.tresName}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.tresName}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.tresName && formik.errors.tresName ? <div>{formik.errors.tresName}</div> : null}</Row> */}
              </Form.Group>

              <Form.Group controlId="tresEmail">
                <Row>
                  <Form.Control name="tresEmail" type="email" placeholder="treasurer's email" value={formik.values.tresEmail} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.tresEmail && formik.errors.tresEmail}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.tresEmail}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.tresEmail && formik.errors.tresEmail ? <div>{formik.errors.tresEmail}</div> : null}</Row> */}
              </Form.Group>

              <Form.Group controlId="tresPhone">
                <Row>
                  <Form.Control name="tresPhone" type="phone" placeholder="treasurer's phone number" value={formik.values.tresPhone} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.tresPhone && formik.errors.tresPhone}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.tresPhone}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.tresPhone && formik.errors.tresPhone ? <div>{formik.errors.tresPhone}</div> : null}</Row> */}
              </Form.Group>
              <div className="divider"></div>
              <Form.Group controlId="gsrName">
                <Row>
                  <Form.Label>GSR's details</Form.Label>
                  <Form.Control name="gsrName" type="input" placeholder="gsr's name" value={formik.values.gsrName} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.gsrName && formik.errors.gsrName}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.gsrName}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.gsrName && formik.errors.gsrName ? <div>{formik.errors.gsrName}</div> : null}</Row> */}
              </Form.Group>

              <Form.Group controlId="gsrEmail">
                <Row>
                  <Form.Control name="gsrEmail" type="email" placeholder="gsr's email" value={formik.values.gsrEmail} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.gsrEmail && formik.errors.gsrEmail}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.gsrEmail}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.gsrEmail && formik.errors.gsrEmail ? <div>{formik.errors.gsrEmail}</div> : null}</Row> */}
              </Form.Group>

              <Form.Group controlId="gsrPhone">
                <Row>
                  <Form.Control name="gsrPhone" type="phone" placeholder="gsr's phone number" value={formik.values.gsrPhone} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.gsrPhone && formik.errors.gsrPhone}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.gsrPhone}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.gsrPhone && formik.errors.gsrPhone ? <div>{formik.errors.gsrPhone}</div> : null}</Row> */}
              </Form.Group>
              <div className="divider"></div>
              <Form.Group controlId="reportText">
                <Row>
                  <Form.Label>Your group's report</Form.Label>
                  <Form.Control name="reportText" as="textarea" rows={8} placeholder="type the report" value={formik.values.reportText} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.reportText && formik.errors.reportText}></Form.Control>
                  <Form.Control.Feedback type="invalid">{formik.errors.reportText}</Form.Control.Feedback>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.reportText && formik.errors.reportText ? <div>{formik.errors.reportText}</div> : null}</Row> */}
              </Form.Group>
              <div className="divider"></div>
              <Form.Group controlId="donation">
                <Row>
                  <Form.Label>Your group's donation this quarter</Form.Label>
                  <InputGroup>
                      <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
                    <Form.Control name="donation" type="float" placeholder="amount" value={formik.values.donation} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.donation && formik.errors.donation}></Form.Control>
                    <Form.Control.Feedback type="invalid">{formik.errors.donation}</Form.Control.Feedback>
                  </InputGroup>
                </Row>
                {/* <Row style={{ color: "red" }}>{formik.touched.donation && formik.errors.donation ? <div>{formik.errors.donation}</div> : null}</Row> */}
              </Form.Group>

              <Form.Group controlId="tos">
                <Row>
                  {["radio"].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check
                        name="tos"
                        type={type}
                        id={`default-${type}`}
                        checked={formik.values.tos}
                        value="1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label={
                          <>
                            I accept the <Button variant="link" onClick={TosAlert}>Terms of Service</Button>
                            {/* I accept the <Link onClick={TosAlert}>{"Terms of Service"}</Link> */}
                          </>
                        }
                      ></Form.Check>
                    </div>
                  ))}
                </Row>
                <Row style={{ color: "red" }}>{formik.touched.tos && formik.errors.tos ? <div>{formik.errors.tos}</div> : null}</Row>
              </Form.Group>

              <Row>
                <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={!formValidate()}>
                  submit report
                </LoaderButton>
              </Row>
            </Form>
          </div>
        </FadeIn>
      </>
    );
  }

  return <div className="GroupQtrlyReport">{apiError === true ? renderApiCallError() : renderQrtReportForm()}</div>;
}
