
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../../graphql/queries";


export default async function getDonations(filterVar, FullGroups) {
   
    try {
        const apiDonations = await API.graphql(graphqlOperation(queries.listNTIGS, { filter: { PK: { beginsWith: "Donation#" }, Period: { eq: filterVar } } }));
        let data = [];
        let receivedItems = {}

        for (let i in apiDonations.data.listNTIGS.items) {
            let listItem = await apiDonations.data.listNTIGS.items[i];
            let id = i
            let groupId = await apiDonations.data.listNTIGS.items[i].SK.split("#")[1]
            let donationGroupName = await FullGroups[groupId].Name;
            
            data.push({
                Id: id,
                GroupId: groupId,
                Group: donationGroupName,
                Date: listItem.PaymentDate,
                Donation: listItem.Donation,
                Period: listItem.Period
            });
        }
        receivedItems.data = data;
        return receivedItems.data

    } catch (err) { }
    
    
}