import "./styles.scss";
import { useState, useRef, useEffect } from "react";
import { useUserContext } from "../../libs/context/userLib";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import updateNtig from "../../API/appSync/ntig/updateNtig";
import getNtig from "../../API/appSync/ntig/getNtig";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ColouredLine from "../shapes/ColouredLine";
import { onError } from "../../libs/userFeedback/errorLib";
//import button from "react-bootstrap/button";

const MenuBar = ({ editor }) => {
	if (!editor) {
		return null;
	}

	return (
		<>
			<button
				onClick={() => editor.chain().focus().toggleBold().run()}
				disabled={!editor.can().chain().focus().toggleBold().run()}
				className={editor.isActive("bold") ? "is-active" : ""}>
				bold
			</button>
			<button
				onClick={() => editor.chain().focus().toggleItalic().run()}
				disabled={!editor.can().chain().focus().toggleItalic().run()}
				className={editor.isActive("italic") ? "is-active" : ""}>
				italic
			</button>
			<button
				onClick={() => editor.chain().focus().toggleStrike().run()}
				disabled={!editor.can().chain().focus().toggleStrike().run()}
				className={editor.isActive("strike") ? "is-active" : ""}>
				strike
			</button>
			<button
				onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
				className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}>
				h1
			</button>
			<button
				onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
				className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}>
				h2
			</button>
			<button
				onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
				className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}>
				h3
			</button>
			<button
				onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
				className={editor.isActive("heading", { level: 5 }) ? "is-active" : ""}>
				h5
			</button>
			<button
				onClick={() => editor.chain().focus().setParagraph().run()}
				className={editor.isActive("paragraph") ? "is-active" : ""}>
				paragraph
			</button>
			<button
				onClick={() => editor.chain().focus().toggleBulletList().run()}
				className={editor.isActive("bulletList") ? "is-active" : ""}>
				bullet list
			</button>
			<button
				onClick={() => editor.chain().focus().toggleOrderedList().run()}
				className={editor.isActive("orderedList") ? "is-active" : ""}>
				ordered list
			</button>
			<button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
				horizontal line
			</button>
			<button
				onClick={() => editor.chain().focus().undo().run()}
				disabled={!editor.can().chain().focus().undo().run()}>
				undo
			</button>
			<button
				onClick={() => editor.chain().focus().redo().run()}
				disabled={!editor.can().chain().focus().redo().run()}>
				redo
			</button>
		</>
	);
};

export default function TextEditor(props) {
	const { userContext } = useUserContext();
	const makeDatestamp = new Date();
	const currentYear = makeDatestamp.getFullYear();
	const currentMonth = makeDatestamp.getMonth() + 1; //months start at 0
	const currentDay = makeDatestamp.getDate();
	const document = useRef(false);
	const [isReport, setIsReport] = useState(false);

	useEffect(() => {
		(async () => {
			const data = await getNtig(
				"Minutes#Intergroup",
				`${props.reportingPeriod}#NTIG`,
				"minsExist"
			);
			setIsReport(data);
		})();
	}, [props.reportingPeriod]);

	const editor = useEditor({
		extensions: [StarterKit],
		autofocus: "start",
		editable: true,
		content: props.editorDocument,
		onUpdate: ({ editor }) => {
			const json = editor.getJSON();
			document.current = json;
		},
	});
	// useEffect(() => {
	// 	if (props.editorDocument) {
	// 		editor.setEditable(false);
	// 		editor.setContent(props.editorDocument);
	// 	}
	// }, [editor, props.editorDocument]);

	function validateForm() {
		//onError(JSON.stringify(document.current));
		return JSON.stringify(document.current) === "false";
	}

	const saveDocument = async () => {
		const submitter =
			'{"Email": "' +
			userContext.user +
			'","Name": "' +
			userContext.userName +
			'"}';
		const datestamp = currentDay + "/" + currentMonth + "/" + currentYear;

		const sendData = {
			PK: "Minutes#Intergroup",
			SK: `${props.reportingPeriod}#NTIG`,
			Period: props.reportingPeriod,
			Submitter: submitter,
			Detail: editor.getHTML(),
			Date: datestamp,
		};
		const response = await updateNtig(sendData);
		if (response) {
			onError("Minutes record updated successfully");
			props.setInMinsEdit(false);
		} else {
			onError(response.message);
		}
	};

	function handleCancel() {
		//props.setMeetingMinsOpen(false);
		props.setInMinsEdit(false);
	}

	return (
		<>
			<div className="EditorSetup">
				<>
					<div className="sticky">
						<div className="divider"></div>
						<h2>Editing Minutes</h2>

						<ColouredLine color="blue" />
						<h1>NTIG Meeting Minutes</h1>
						<h2>Reporting Period {props.reportingPeriod}</h2>
						<MenuBar editor={editor} />
						<hr />
					</div>
					<EditorContent editor={editor} />
					<div className="stickyBottom">
						<ColouredLine color="blue" />
						<Form>
							<Row>
								<Button
									size="lg"
									onClick={() => handleCancel()}
									variant="secondary">
									cancel
								</Button>
							</Row>
							<div className="divider"></div>
							<Row>
								<Button
									size="lg"
									variant="success"
									onClick={() => saveDocument()}
									disabled={validateForm()}>
									submit
								</Button>
							</Row>
						</Form>
					</div>
				</>
				{!isReport && <ColouredLine color="grey" />}
			</div>
		</>
	);
}
