import React from "react";
import "./ReportsTable.css";
import Button from 'react-bootstrap/Button'

export default function OfficerReportsTable(props) {
  const isLoading = props.isLoading
  return (
    isLoading ? (
      <>
      <div className="divider"></div>
        <div>Loading data.....</div>
        </>
  ) : (
    <>
      <table>
        <thead>
          <tr>
            <th>Officer</th>
                {(props.userIsAdmin || props.userIsExec) && (<th>Submitted by</th>)}
            <th>Financal Data</th>
            <th style={{ width: "800px" }}>Report</th>
          </tr>
        </thead>
        <tbody>
          {props.reports.length > 0 ? (
            props.reports.map((report) => (
              <tr key={report.Id}>
                <td style={{ textAlign: "centre" }}>
                  <Button variant="link" onClick={() => {
                    props.reviewOfficerReportRow(report);
                  }} disabled={props.userIsAdmin !== true}>{report.Officer}</Button>
                </td>
                {(props.userIsAdmin || props.userIsExec) && (
                  <td>
                    report.SubmitterName
                    < br />
                    {report.SubmitterEmail}
                    < br />
                    Date submitted: {report.Date}
                  </td>)}
                <>
                  <td>{report.Officer === "Treasurer" && (<div>Income: £ {report.IncomeTotal}
                    <br />
                    Expense: £ {report.ExpenseTotal}
                    <br />
                    Balance: £ {report.QuarterBalance}
                  </div>)}</td>
                </>
                <td>{report.Detail.split("\n").map((value, index) => {
                return (
                  <span key={index}>
                    {value}
                    <br />
                  </span>
                )
              })}</td>
                {/* <td>
                <button className="button muted-button">Review</button>
              </td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>There aren't officer reports for the selected quarter</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  ))
}
