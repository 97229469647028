import React, { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import loadContext from "../functions/LoadContext";
import { useAuthContext } from "../../libs/context/authenticatedLib";
import { useUserContext } from "../../libs/context/userLib";
import { useGroupsContext } from "../../libs/context/groupsLib";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import { useFormik } from "formik";
import * as Yup from "yup";
import LoaderButton from "../buttons/LoaderButton";
import { onError } from "../../libs/userFeedback/errorLib";
import FadeIn from "react-fade-in";
import { useNavigate } from "react-router-dom";
import "./RegSignupForm.css"


const RenderRegSignupForm = (props) => {
  const user = props.user
  const { userHasAuthenticated } = useAuthContext();
  const { setUserContext } = useUserContext(false);
  const { setGroupsContext } = useGroupsContext(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required").min(2, "Minimum two characters").max(15, "Maximum fifteen characters"),
    email: Yup.string().email("Invalid email format"),
    phone: Yup.string().matches(phoneRegex, "Enter a valid phone number"),
    newPassword: Yup.string().required("Required"),
    confPassword: Yup.string().required("Required").oneOf([Yup.ref('newPassword'), null], "Passwords must match")
  });


  const formik = useFormik({
    initialValues: {
      name: "",
      email: props.email,
      phone: "",
      newPassword: "",
      confPassword: ""
    },

    validationSchema,
    onSubmit: (values) => {
      handleSignupSubmit(values)
    }
  })


  async function handleSignupSubmit(event) {
    setIsLoading(true)
    async function groupsContext() {
      const loadGroupContext = await loadContext()
      setGroupsContext(loadGroupContext)
    navigate("/");
  }




    const phone_number = formik.values.phone.replace("0", "+44");


    await Auth.completeNewPassword(
      user, // the Cognito User Object
      formik.values.newPassword, // the new password
      //{ address: address, phone_number: phone_number, name: fields.name }
      {
        phone_number: phone_number,
        name: formik.values.name
      }
    )
      .then((user) => {
        userHasAuthenticated(true);
        const isAdmin = user.signInUserSession.accessToken.payload['cognito:groups'].includes("ntig-admin-users");
        const isNtigExec = user.signInUserSession.accessToken.payload['cognito:groups'].includes("ntig-exec-users");
        const isPortalUser = user.signInUserSession.accessToken.payload['cognito:groups'].includes("ntig-groups-users");
        setUserContext({ user: user.signInUserSession.idToken.payload.email, userName: user.signInUserSession.idToken.payload.name, userIsAdmin: isAdmin, userIsNtigExec: isNtigExec, userIsPortalUser: isPortalUser });
        groupsContext()
       
      })
      .catch((e) => {
        onError(e);
        navigate("/");
        console.log(e);
      });
    setIsLoading(false)
  }

  return (
    <>
      <h2>Please complete your details</h2>
      <FadeIn delay="800">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group size="lg" controlId="newPassword">
            <Form.Label>new password</Form.Label>
            <Form.Control autoFocus type="password" value={formik.values.newPassword} onChange={formik.handleChange} />
          </Form.Group>
          <Form.Group size="lg" controlId="confPassword">
            <Form.Label>confirm new password</Form.Label>
            <Form.Control type="password" value={formik.values.confPassword} onChange={formik.handleChange} />
          </Form.Group>

          <Form.Group size="lg" controlId="name">
            <Form.Label>name</Form.Label>
            <Form.Control type="text" placeholder="name" value={formik.values.name} onChange={formik.handleChange} />
          </Form.Group>

          <Form.Group size="lg" controlId="phone">
            <Form.Label>phone number</Form.Label>
            <Form.Control type="tel" placeholder="phone number" value={formik.values.phone} onChange={formik.handleChange} />
          </Form.Group>



          {/* <Form.Group size="lg" controlId="addressFirstLine">
              <Form.Label>address</Form.Label>
              <Form.Control type="input" placeholder="first line of address" value={fields.addressFirstLine} onChange={formik.handleChange} />
            </Form.Group>

            <Form.Group size="lg" controlId="addressTown">
              <Form.Control type="input" placeholder="town" value={fields.addressTown} onChange={handleFieldChange} />
            </Form.Group>

            <Form.Group size="lg" controlId="addressCounty">
              <Form.Control type="input" placeholder="county" value={fields.addressCounty} onChange={handleFieldChange} />
            </Form.Group>

            <Form.Group size="lg" controlId="addressPostcode">
              <Form.Control type="input" placeholder="post code" value={fields.addressPostcode} onChange={handleFieldChange} />
            </Form.Group> */}

          {/* <Row>
              {["checkbox"].map((type) => (
                <div key={`default-${type}`} className="mb-3">
                  <Form.Check type={type} id={`default-${type}`} checked={checkVisible} onChange={changeCheckVisible} label="Phone number visible on rota"></Form.Check>
                </div>
              ))}
            </Row>

            <Row>
              {["checkbox"].map((type) => (
                <div key={`default-${type}`} className="mb-3">
                  <Form.Check type={type} id={`default-${type}`} checked={checkWhatsApp} onChange={changeCheckWhatsApp} label="I use WhatsApp"></Form.Check>
                </div>
              ))}
            </Row> */}
          <div className="divider"></div>
          <Row>
          <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} >
            confirm
          </LoaderButton>
          </Row>
        </Form>
      </FadeIn>
    </>
  );
}
export default RenderRegSignupForm;