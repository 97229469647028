import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../../graphql/mutations";

export default async function createNtig(data) {
  let response
  await API.graphql(graphqlOperation(mutations.createNTIG, { input: data }))
    .then((data) => {
      response = true
    })
    .catch((error) => {
      response = error
    });
  return response
};





