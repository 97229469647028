import { CognitoIdentityProviderClient, ListUsersCommand } from "@aws-sdk/client-cognito-identity-provider";
import awsmobile from "../../aws-exports";
import { Auth } from "@aws-amplify/auth";

export default async function ListUsers() {
  let users = []
  let response = {}
  try {
    const data = await Auth.currentCredentials();
    const client = new CognitoIdentityProviderClient({
      region: awsmobile.aws_project_region,
      credentials: data,
    });
    const params = {
      UserPoolId: awsmobile.aws_user_pools_id,
      AttributesToGet: ['email']
    };
    const command = new ListUsersCommand(params);
    response = await client.send(command);
    users = [...users, ...response.Users]

    while (typeof response.PaginationToken !== 'undefined') {
      params.PaginationToken = response.PaginationToken
      response = await client.send(command);
      users = [...users, ...response.Users]
    }
    return users;

  } catch (error) {
    console.log(error);
  }
}









