import { useUserContext } from "../../libs/context/userLib";
import Button from "react-bootstrap/Button";
import "./IgOfficersTable.css";

function IgOfficersTable(props) {
  const { userContext } = useUserContext();
  const isLoading = props.isLoading
  return (
    isLoading ? (
      <>
      <div className="divider"></div>
        <div>Loading data.....</div>
        </>
  ) : (
    <table>
      <thead>
        <tr>
          <th>Role</th>
          <th>Details</th>
          <th>Date commenced</th>
          <th>Date to end</th>
          {userContext.userIsAdmin && (
            <th></th>)}
        </tr>
      </thead>
      <tbody>
        {props.officers.length > 0 ? (
          props.officers.map((officer) => (
            <tr key={officer.id}>
              <td>{officer.Role}</td>
              <td>
                {officer.Name}
                <br />
                {officer.Email}
                <br />
                {officer.Phone}
              </td>
              <td>{officer.Start}</td>
              <td>{officer.End}</td>
                {userContext.userIsAdmin && (
                   <td>
                    <Button size="sm" variant="outline-secondary" type="button" onClick={() => {props.reviewRow(officer)}}>edit</Button>
                  {/* <button
                    onClick={() => {
                      props.reviewRow(officer);
                    }}
                    className="button muted-button"
                  >
                    edit
                  </button> */}
                  </td>
                )}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5}>Couldn't find the Intergroup Officers</td>
          </tr>
        )}
      </tbody>
    </table>
  ));
}
export default IgOfficersTable;
