import { useState } from "react";

import TextEditor from "../../../components/Editor/RichTextEditor";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ColouredLine from "../../../components/shapes/ColouredLine";
import Form from "react-bootstrap/Form";
import ReportingQuarterSelectForm from "../../../components/forms/ReportingQuarterSelectForm";

export default function MeetingMinutesCreate(props) {
	const [inPeriodEdit, setInPeriodEdit] = useState(false);

	return (
		<>
			{!inPeriodEdit ? (
				<>
					<Form>
						<Row>
							<Button
								size='sm'
								variant='secondary'
								onClick={() => setInPeriodEdit(true)}>
								change reporting period
							</Button>
						</Row>
					</Form>
					<ColouredLine colour='grey' />
				</>
			) : (
				<>
					<ReportingQuarterSelectForm
						reportingPeriodChange={props.reportingPeriodChange}
						setInPeriodEdit={setInPeriodEdit}
						propPeriod={props.propPeriod}
					/>
					<ColouredLine colour='grey' />
				</>
			)}

			<h3>create intergroup meeting minutes</h3>
			<header>
				<TextEditor
					reportingPeriod={props.propPeriod}
					setMinsCreateOpen={props.setMinsCreateOpen}
				/>
			</header>
		</>
	);
}
