import React, { useState } from "react";import { Auth } from "@aws-amplify/auth";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../../components/buttons/LoaderButton";
import { onError } from "../../libs/userFeedback/errorLib";
import { useFormFields } from "../../libs/hooksLib";

export default function ChangePassword() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [fields, handleFieldChange] = useFormFields({
		password: "",
		newPassword: "",
		confirmPassword: "",
	});

	function validateForm() {
		return (
			fields.confirmPassword.length > 0 &&
			fields.newPassword.length > 0 &&
			fields.password.length > 0 &&
			fields.confirmPassword === fields.newPassword
		);
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		await Auth.currentAuthenticatedUser()
			.then((user) => {
				return Auth.changePassword(user, fields.password, fields.newPassword);
			})
			.then(() => {
				setIsLoading(false);
				navigate("/");
			})
			.catch((err) => {
				onError(err);
				setIsLoading(false);
				console.log(err);
			});
	}

	const ChangePasswordForm = () => {
		return (
			<>
				<h2>change your password</h2>
				<Form onSubmit={handleSubmit}>
					<Form.Group className='mb-3' size='lg' controlId='password'>
						<Form.Label>current password</Form.Label>
						<Form.Control
							type='password'
							value={fields.password}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group className='mb-2' size='lg' controlId='newPassword'>
						<Form.Label>new password</Form.Label>
						<Form.Control
							type='password'
							value={fields.newPassword}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group className='mb-3' size='lg' controlId='confirmPassword'>
						<Form.Label>confirm new password</Form.Label>
						<Form.Control
							type='password'
							value={fields.confirmPassword}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Row>
						<LoaderButton
							size='lg'
							type='submit'
							variant='success'
							isLoading={isLoading}
							disabled={!validateForm()}>
							submit
						</LoaderButton>
					</Row>
				</Form>
			</>
		);
	};

	return (
		<div className='ntig'>
			<ChangePasswordForm />
		</div>
	);
}
