import { CognitoIdentityProviderClient, ListUsersInGroupCommand } from "@aws-sdk/client-cognito-identity-provider";
import awsmobile from "../../aws-exports";
import { Auth } from "@aws-amplify/auth";

export default async function ListUsersInGroup(group) {
  let users = []
  let response = {}
  try {
    const data = await Auth.currentCredentials();
    const client = new CognitoIdentityProviderClient({
      region: awsmobile.aws_project_region,
      credentials: data
    });

    let params = {
      UserPoolId: awsmobile.aws_user_pools_id,
      GroupName: group
    };

    const command = new ListUsersInGroupCommand(params);
    response = await client.send(command);
    users = [...users, ...response.Users]
    while (typeof response.NextToken !== 'undefined') {
      params.NextToken = response.NextToken
      response = await client.send(command);
      users = [...users, ...response.Users]

    }
    return users;

  } catch (error) {
    console.log(error);
  }
}