import { CognitoIdentityProviderClient, AdminCreateUserCommand } from "@aws-sdk/client-cognito-identity-provider"
import awsmobile from "../../aws-exports";
import { Auth } from "@aws-amplify/auth";
import { onError } from "../../libs/userFeedback/errorLib";
import AdminAddUserToGroup from "./AdminAddUserToGroup";

export default async function AdminCreateUser(email, cognito_user_pool_groups) {
  const data = await Auth.currentCredentials();
  const client = new CognitoIdentityProviderClient({
    region: awsmobile.aws_project_region,
    credentials: data,
  });
  const params = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: email,
    UserAttributes: [
      {
        Name: "email",
        Value: email
      },
      {
        Name: "email_verified",
        Value: "true"
      },
    ]
  };
  const command = new AdminCreateUserCommand(params);
  try {
    await client.send(command);
  } catch (error) {
    onError(error.message);
    return
  }
  let response = AdminAddUserToGroup(email, cognito_user_pool_groups);
  return response
}