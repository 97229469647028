import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { useGroupsContext } from "../../libs/context/groupsLib";
import "./ActiveGroupAmendForm.css"


export default function ActiveGroupAmendForm(props) {
    const { groupsContext } = useGroupsContext()
    const [newGroupName, setNewGroupName] = useState(props.currentGroup.Group)
    const [selectedStatus, setSelectedStatus] = useState(props.currentGroup.Status)

    const group = props.currentGroup;
    
    const varPK = "Status";
    const varSK = "Test";

    const handleChange = event => {
        setSelectedStatus(event.target.value);
      };
      
      function noSave() {
        return newGroupName === group.Group && selectedStatus === group.Status
    }
    

    return (
        <>
            <div className="ActiveGroupAmend">
                <Form
                    onSubmit={(event) => {
                        event.preventDefault();
                        const groupKeys = Object.keys(groupsContext.FullGroups)
                        var objGroups = ""
                        for (let key in groupKeys) {
                            if (groupKeys[key] !== group.id) objGroups = objGroups + '"' + groupKeys[key] + '": {"Name": "' + groupsContext.FullGroups[groupKeys[key]].Name + '","Status":"' + groupsContext.FullGroups[groupKeys[key]].Status + '"},'
                            //if (groupKeys[key] === group.id) oldStatus = groupsContext.FullGroups[groupKeys[key]].Status
                        }
                      
                        objGroups = objGroups + '"' + group.id + '": {"Name": "' + newGroupName + '","Status": "' + selectedStatus + '"}'
                        var objFullGroups = '{' + objGroups + '}'

                        const sendData = {
                            PK: varPK,
                            SK: varSK,
                            FullGroups: objFullGroups
                        };
                        
                        //const sendData = JSONify(FullGroups)
                        //const sendData = dataToItem(objData)
                        let updatedGroup = {
                            Id: group.id,
                            Name: newGroupName,
                            Status: selectedStatus
                        }
                        
                       
                            //loadGroups()
                            props.editGroup(sendData, updatedGroup);
                            //props.setInEdit(false)
                        
                    }
                    }
                >
                    <h3>Group Name & Status</h3>
                    <hr />
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Control type="input" name="groupname" placeholder={group.Group} value={newGroupName} onChange={(e) => setNewGroupName(e.target.value)} />
                        </Form.Group>
                    </Row>
                

                    <Row>
                    <Form.Check
                        inline
                        type="radio"
                        id="Active"
                        name="status"
                        value="Active"
                        label="Active"
                        checked={selectedStatus === 'Active'}
                        onChange={handleChange}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        id="Temp Closed"
                        name="status"
                        value="Temporarily Closed"
                        label="Temporarily Closed"
                        checked={selectedStatus === 'Temporarily Closed'}
                        onChange={handleChange}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        id="Closed"
                        name="status"
                        value="Closed"
                        label="Closed"
                        checked={selectedStatus === 'Closed'}
                        onChange={handleChange}
                    />
                    </Row>
                        <div className="divider"></div>
                        <Row>
                    <Button onClick={() => props.setInEdit(false)} variant="secondary">
                        Cancel
                    </Button>
                    </Row>
                    <div className="divider"></div>
                    <Row>

                    <Button type="submit" variant="success" disabled={noSave()}>
                        Save changes
                        </Button>
                        </Row>
                </Form>
            </div>
        </>
    );
};

