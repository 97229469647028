import { useEffect, useState, useRef } from "react";
import { useGroupsContext } from "../../libs/context/groupsLib";
import { useUserContext } from "../../libs/context/userLib";
import updateNtig from "../../API/appSync/ntig/updateNtig";
import AddGroupForm from "../../components/forms/AddGroupForm"
import GroupNamePageHeader from "../../components/pageNav/headers/GroupNamePageHeader"
import GroupsMgtTable from "../../components/tables/GroupsMgtTable";
import ReviewContactForm from "../../components/forms/ReviewContactForm";
import ActiveGroupAmendForm from "../../components/forms/ActiveGroupAmendForm";
import listNtigs from "../../API/appSync/ntig/listNtigs";
import getNtig from "../../API/appSync/ntig/getNtig";
import FadeIn from "react-fade-in/lib/FadeIn";
import ColouredLine from "../../components/shapes/ColouredLine"
import loadContext from "../../components/functions/LoadContext";

export default function GroupsMgt() {
  const { groupsContext, setGroupsContext } = useGroupsContext()
  const { userContext } = useUserContext();
  const filterPK = "Officers#2";
  const [isLoading, setIsLoading] = useState(true);
  //const proposed = ListNtigs(filterPK, filterSK, "contacts"); //const [users, setUsers] = useState(usersData)
  const [inReview, setInReview] = useState(false); //const [editing, setEditing] = useState(false)
  const initialGroupFormState = { id: null, Name: "", Status: "" };
  const [currentGroup, setCurrentGroup] = useState(initialGroupFormState);
  const initialFormState = { id: null, Date: "", SecretaryName: "", SecretaryEmail: "", SecretaryPhone: "", TreasurerName: "", TreasurerEmail: "", TreasurerPhone: "", GSRName: "", GSREmail: "", GSRPhone: "" };
  const [currentContact, setCurrentContact] = useState(initialFormState);
  const containter = "master";
  const [contacts, setContacts] = useState([]);
  const dbContacts = useRef(false)

  useEffect(() => {
    (async () => {
      const data = await listNtigs(filterPK, "contacts", groupsContext.FullGroups);
      setContacts(data)
      setIsLoading(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    })()
}, [groupsContext]);

 
  const reviewRow = async (contact) => {
    dbContacts.current = await getNtig("Officers#2", "Group#" + contact.Id, "contact")
    setInReview(true);
    setCurrentContact({ id: contact.Id, Group: contact.Group, Date: contact.Date, SubmitterName: contact.SubmitterName, SubmitterEmail: contact.SubmitterEmail, SecretaryName: contact.SecretaryName, SecretaryEmail: contact.SecretaryEmail, SecretaryPhone: contact.SecretaryPhone, TreasurerName: contact.TreasurerName, TreasurerEmail: contact.TreasurerEmail, TreasurerPhone: contact.TreasurerPhone, GSRName: contact.GSRName, GSREmail: contact.GSREmail, GSRPhone: contact.GSRPhone });
    setCurrentGroup({ id: contact.Id, Group: contact.Group, Status: contact.Status })
  };

  const editGroup = async (sendData, updatedGroup) => {
    const result = await updateNtig(sendData)
    if (result) {
      setGroupsContext(await loadContext())
    }
    let updatedGroups = contacts
    for (let item in updatedGroups) {
      if (updatedGroups[item].Id === updatedGroup.Id) {
        updatedGroups[item].Name = updatedGroup.Name//groupsContext.Fullgroups[updatedGroup.id].Name
        updatedGroups[item].Status = updatedGroup.Status//groupsContext.Fullgroups[updatedGroup.id].Status
        setInReview(false)
        return
      }
    }
  }


  const reviewContact = (id, updatedContact) => {
    setInReview(false);
    window.scrollTo(0, 0)
    var updatedContacts = contacts
    updatedContacts = contacts.filter((contact) => contact.Id !== id);
    updatedContacts.unshift(updatedContact)
    setContacts(updatedContacts)
  }

    const addGroup = async (sendData, newGroup) => {
      const result = await updateNtig(sendData)
      if (result) {
        setGroupsContext(await loadContext())
      }

      let updatedGroups = contacts
      updatedGroups.unshift(newGroup)
      setContacts(updatedGroups)
      setInReview(false);
      window.scrollTo(0, 0)
    }


 
  return (
    <FadeIn transitionDuration="1000">
      <div className="flex-large">
        {inReview ? (
          <div>
            <GroupNamePageHeader group={currentGroup.Group}/>
            <ActiveGroupAmendForm setInEdit={setInReview} currentGroup={currentGroup} editGroup={editGroup} />
            <ReviewContactForm setInReview={setInReview} currentContact={currentContact} dbContacts={dbContacts.current} reviewContact={reviewContact} />
          </div>
        ) : (
          <div className="flex-row">
              <div className="flex-large">
                {userContext.userIsAdmin && (
                  <>
                  <AddGroupForm addGroup={addGroup} />
                  <ColouredLine color="grey" />
                  </>
                  )}
                <h2>NTIG Groups Management</h2>
                <div className="divider"></div>
              <GroupsMgtTable contacts={contacts} reviewRow={reviewRow} container={containter} isLoading={isLoading} />
            </div>
          </div>
        )}
      </div>
    </FadeIn>
  );
}
