import { useState, useRef, useEffect } from "react";
import listNtigs from "../../../API/appSync/ntig/listNtigs";
import HTMLviewer from "../../../components/Editor/ParserHTML";
import TextEditor from "../../../components/Editor/RichTextViewer";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ColouredLine from "../../../components/shapes/ColouredLine";
import "./Mins.css";

import { onError } from "../../../libs/userFeedback/errorLib";

export default function MeetingMinutesView(props) {
	const [inMinsEdit, setInMinsEdit] = useState(false);
	const documents = useRef({});
	const [editorDocument, setEditorDocument] = useState(false);
	const [viewReportYear, setViewReportYear] = useState(
		new Date().getFullYear()
	);
	const [viewReportPeriod, setViewReportPeriod] = useState();
	const [minutes, setMinutes] = useState([]);

	useEffect(() => {
		(async () => {
			const data = await listNtigs(
				`Minutes#Intergroup`,
				"minutesViewer",
				viewReportYear
			);
			setMinutes(data);
			//onError(minutes.current);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewReportYear]);

	useEffect(() => {
		for (let item in minutes) {
			documents.current[minutes[item].ReportPeriod.split("/")[1]] =
				minutes[item];
		}
		//documents.current = minutes.map((item) => documents.current[item.ReportPeriod.split("/")[1]] = item)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minutes]);

	const handleQtrButton = (key) => {
		setViewReportPeriod(viewReportYear + "/" + key);
		const detail = documents.current[key].Detail;
		setEditorDocument(detail);
		return;
	};

	const QtrSelectButtons = () => {
		const buttonList = minutes.map((item) => item.ReportPeriod.split("/")[1]);

		return (
			<>
				<div style={{ textAlign: "center " }}>select report from quarter</div>

				<ButtonGroup className='mb-2'>
					{buttonList.map((key) => (
						<>
							<Button
								className='mb-2'
								key={key}
								id={key}
								variant='secondary'
								onClick={() => handleQtrButton(key)}>
								{key}
							</Button>
							<Button variant='link' key={key.split[1] + 1} disabled></Button>
						</>
					))}
				</ButtonGroup>
			</>
		);
	};

	const handleYearChange = (e) => {
		setViewReportYear(e.target.value);
		setEditorDocument(false);
	};

	const arryears = Array.from(Array(new Date().getFullYear() - 2020), (_, i) =>
		(i + 2021).toString()
	);
	const years = arryears.sort(function (a, b) {
		return b - a;
	});

	return (
		<>
			{!inMinsEdit ? (
				<>
					<ColouredLine color='grey' />
					<Form>
						<h3>view intergroup meeting minutes</h3>
						<Row>
							<Button
								size='sm'
								variant='secondary'
								onClick={() => props.setMeetingMinsOpen(false)}>
								close meeting minutes viewer
							</Button>
						</Row>
						<div className='divider'></div>
						<Row>
							<Form.Group style={{ textAlign: "center" }} controlId='year'>
								<Form.Label>select year</Form.Label>
								<Form.Control
									style={{ textAlign: "center" }}
									name='year'
									as='select'
									onChange={handleYearChange}>
									{years.map((option) => {
										return (
											<option value={option} key={option}>
												{option}
											</option>
										);
									})}
								</Form.Control>
							</Form.Group>
						</Row>
						<QtrSelectButtons />
					</Form>
					{editorDocument && (
						<>
							<HTMLviewer
								viewReportPeriod={viewReportPeriod}
								editorDocument={editorDocument}
								setInMinsEdit={setInMinsEdit}
								setMeetingMinsOpen={props.setMeetingMinsOpen}
							/>
						</>
					)}
				</>
			) : (
				<TextEditor
					reportingPeriod={viewReportPeriod}
					editorDocument={editorDocument}
					setInMinsEdit={setInMinsEdit}
				/>
			)}
		</>
	);
}
