import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../../graphql/queries";
import { onError } from "../../../libs/userFeedback/errorLib";

export default async function getNtig(varPK, varSK, type) {
	try {
		const apiGroups = await API.graphql(
			graphqlOperation(queries.getNTIG, { PK: varPK, SK: varSK })
		);
		let receivedItems = {};
		let data = [];

		switch (type) {
			case "adminSecQtr":
				receivedItems = await apiGroups.data.getNTIG.Period;
				break;

			case "contact":
				let listItem = await apiGroups.data.getNTIG;
				let Group = await apiGroups.data.getNTIG.SK.split("#")[1];
				let secJson = await JSON.parse(apiGroups.data.getNTIG.Secretary);
				let tresJson = await JSON.parse(apiGroups.data.getNTIG.Treasurer);
				let gsrJson = await JSON.parse(apiGroups.data.getNTIG.GSR);
				let id = 1;

				receivedItems = {
					Id: id,
					Date: listItem.Date,
					Group: Group,
					SecretaryName: secJson.Name,
					SecretaryEmail: secJson.Email,
					SecretaryPhone: secJson.Phone,
					TreasurerName: tresJson.Name,
					TreasurerEmail: tresJson.Email,
					TreasurerPhone: tresJson.Phone,
					GSRName: gsrJson.Name,
					GSREmail: gsrJson.Email,
					GSRPhone: gsrJson.Phone,
				};
				break;

			case "intergroupofficers":
				let roles = [];
				let officersJson = await JSON.parse(apiGroups.data.getNTIG.Officers);
				for (let i in apiGroups.data.getNTIG.Roles) {
					roles.push(apiGroups.data.getNTIG.Roles[i]);
				}

				for (let i in roles) {
					let id = i;
					let position = roles[i];
					let igName = officersJson[position].Name;
					let igEmail = officersJson[position].Email;
					let igPhone = officersJson[position].Phone;
					let igStart = officersJson[position].Start;
					let igEnd = officersJson[position].End;

					data.push({
						id: id,
						Role: position,
						Name: igName,
						Email: igEmail,
						Phone: igPhone,
						Start: igStart,
						End: igEnd,
					});
				}
				receivedItems = data;
				break;

			case "activeGroups":
				receivedItems = apiGroups.data.getNTIG.Groups;
				break;

			case "minsExist":
				receivedItems = apiGroups.data.getNTIG;
				break;

			default:
				console.warn("getNtig: defaultCase");
		}
		return receivedItems;
	} catch (error) {
		onError(error.message);
	}
}
