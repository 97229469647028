import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ReviewContactForm.css";
import { useState, useEffect } from "react";
//import GetNtig from "../../API/appSync/ntig/getNtig";
import updateNtig from "../../API/appSync/ntig/updateNtig";
import deleteNtig from "../../API/appSync/ntig/deleteNtig";
import createNtig from "../../API/appSync/ntig/createNtig";


const ReviewContactForm = (props) => {
	//console.log(props.currentContact)
	//const type = "contact";
	const contact = props.currentContact;
	//const varPK = "Officers#2";
	//const varSK = "Group#" + contact.id;
	//const dbContact = GetNtig(varPK, varSK, type);
	const dbContact = props.dbContacts
	const [dbSecName, setDbSecName] = useState("");
	const [dbSecEmail, setDbSecEmail] = useState("");
	const [dbSecPhone, setDbSecPhone] = useState("");
	const [dbTresName, setDbTresName] = useState("");
	const [dbTresEmail, setDbTresEmail] = useState("");
	const [dbTresPhone, setDbTresPhone] = useState("");
	const [dbGsrName, setDbGsrName] = useState("");
	const [dbGsrEmail, setDbGsrEmail] = useState("");
	const [dbGsrPhone, setDbGsrPhone] = useState("");
	const [proposedSecName, setProposedSecName] = useState("");
	const [proposedSecEmail, setProposedSecEmail] = useState("");
	const [proposedSecPhone, setProposedSecPhone] = useState("");
	const [proposedTresName, setProposedTresName] = useState("");
	const [proposedTresEmail, setProposedTresEmail] = useState("");
	const [proposedTresPhone, setProposedTresPhone] = useState("");
	const [proposedGsrName, setProposedGsrName] = useState("");
	const [proposedGsrEmail, setProposedGsrEmail] = useState("");
	const [proposedGsrPhone, setProposedGsrPhone] = useState("");

	

	useEffect(() => {
		window.scrollTo(0 ,0) 
		async function getDbContact() {
			setDbSecName(dbContact.SecretaryName);
			setDbSecEmail(dbContact.SecretaryEmail);
			setDbSecPhone(dbContact.SecretaryPhone);
			setDbTresName(dbContact.TreasurerName);
			setDbTresEmail(dbContact.TreasurerEmail);
			setDbTresPhone(dbContact.TreasurerPhone);
			setDbGsrName(dbContact.GSRName);
			setDbGsrEmail(dbContact.GSREmail);
			setDbGsrPhone(dbContact.GSRPhone);
			setProposedSecName(contact.SecretaryName);
			setProposedSecEmail(contact.SecretaryEmail);
			setProposedSecPhone(contact.SecretaryPhone);
			setProposedTresName(contact.TreasurerName);
			setProposedTresEmail(contact.TreasurerEmail);
			setProposedTresPhone(contact.TreasurerPhone);
			setProposedGsrName(contact.GSRName);
			setProposedGsrEmail(contact.GSREmail);
			setProposedGsrPhone(contact.GSRPhone);
		}
		getDbContact();
	}, [
		dbContact.SecretaryName,
		dbContact.SecretaryEmail,
		dbContact.SecretaryPhone,
		dbContact.TreasurerName,
		dbContact.TreasurerEmail,
		dbContact.TreasurerPhone,
		dbContact.GSRName,
		dbContact.GSREmail,
		dbContact.GSRPhone,
		contact.SecretaryName,
		contact.SecretaryEmail,
		contact.SecretaryPhone,
		contact.TreasurerName,
		contact.TreasurerEmail,
		contact.TreasurerPhone,
		contact.GSRName,
		contact.GSREmail,
		contact.GSRPhone,
	]);

	function acceptSecName(e) {
		e.preventDefault();
		setDbSecName(proposedSecName);
	}

	function revertSecName(e) {
		e.preventDefault();
		setDbSecName(dbContact.SecretaryName);
	}

	function acceptSecEmail(e) {
		e.preventDefault();
		setDbSecEmail(proposedSecEmail);
	}

	function revertSecEmail(e) {
		e.preventDefault();
		setDbSecEmail(dbContact.SecretaryEmail);
	}
	function acceptSecPhone(e) {
		e.preventDefault();
		setDbSecPhone(proposedSecPhone);
	}

	function revertSecPhone(e) {
		e.preventDefault();
		setDbSecPhone(dbContact.SecretaryPhone);
	}
	function acceptTresName(e) {
		e.preventDefault();
		setDbTresName(proposedTresName);
	}
	function revertTresName(e) {
		e.preventDefault();
		setDbTresName(dbContact.TreasurerName);
	}
	function acceptTresEmail(e) {
		e.preventDefault();
		setDbTresEmail(proposedTresEmail);
	}
	function revertTresEmail(e) {
		e.preventDefault();
		setDbTresEmail(dbContact.TreasurerEmail);
	}
	function acceptTresPhone(e) {
		e.preventDefault();
		setDbTresPhone(proposedTresPhone);
	}
	function revertTresPhone(e) {
		e.preventDefault();
		setDbTresPhone(dbContact.TreasurerPhone);
	}
	function acceptGsrName(e) {
		e.preventDefault();
		setDbGsrName(proposedGsrName);
	}
	function revertGsrName(e) {
		e.preventDefault();
		setDbGsrName(dbContact.GSRName);
	}
	function acceptGsrEmail(e) {
		e.preventDefault();
		setDbGsrEmail(proposedGsrEmail);
	}
	function revertGsrEmail(e) {
		e.preventDefault();
		setDbGsrEmail(dbContact.GSREmail);
	}
	function acceptGsrPhone(e) {
		e.preventDefault();
		setDbGsrPhone(proposedGsrPhone);
	}
	function revertGsrPhone(e) {
		e.preventDefault();
		setDbGsrPhone(dbContact.GSRPhone);
	}

	function updateAll(e) {
		e.preventDefault();
		acceptSecName(e);
		acceptSecEmail(e);
		acceptSecPhone(e);
		acceptTresName(e);
		acceptTresEmail(e);
		acceptTresPhone(e);
		acceptGsrName(e);
		acceptGsrEmail(e);
		acceptGsrPhone(e);
	}
	function revertAll(e) {
		e.preventDefault();
		revertSecName(e);
		revertSecEmail(e);
		revertSecPhone(e);
		revertTresName(e);
		revertTresEmail(e);
		revertTresPhone(e);
		revertGsrName(e);
		revertGsrEmail(e);
		revertGsrPhone(e);
	}

	
	return (
		<>
			<div className="ReviewContactForm">
				<Form
					onSubmit={(event) => {
						event.preventDefault();
						var updatedContact = {
							Id: contact.id,
							Group: contact.Group,
							Date: contact.Date,
							SubmitterName: contact.SubmitterName,
							SubmitterEmail: contact.SubmitterEmail,
							SecretaryName: dbSecName,
							SecretaryEmail: dbSecEmail,
							SecretaryPhone: dbSecPhone,
							TreasurerName: dbTresName,
							TreasurerEmail: dbTresEmail,
							TreasurerPhone: dbTresPhone,
							GSRName: dbGsrName,
							GSREmail: dbGsrEmail,
							GSRPhone: dbGsrPhone,
						};
						props.reviewContact(contact.id, updatedContact);

						const submitterJson =
							'{"Name": "' +
							contact.SubmitterName +
							'","Email": "' +
							contact.SubmitterEmail +
							'"}';
						const dbSecJson =
							'{"Name": "' +
							dbSecName +
							'","Email": "' +
							dbSecEmail +
							'","Phone": "' +
							dbSecPhone +
							'"}';
						const dbTresJson =
							'{"Name": "' +
							dbTresName +
							'","Email": "' +
							dbTresEmail +
							'","Phone": "' +
							dbTresPhone +
							'"}';
						const dbGsrJson =
							'{"Name": "' +
							dbGsrName +
							'","Email": "' +
							dbGsrEmail +
							'","Phone": "' +
							dbGsrPhone +
							'"}';

						const newContact = {
							PK: "Officers#2",
							SK: "Group#" + contact.id,
							Date: contact.Date,
							Submitter: submitterJson,
							Secretary: dbSecJson,
							Treasurer: dbTresJson,
							GSR: dbGsrJson
						};

						if (Object.entries(dbContact).length === 0) {
							createNtig(newContact);
						} else {
							updateNtig(newContact);
						}

						const varPK = "Officers#1";
						const varSK = "Group#" + contact.id;
						deleteNtig(varPK, varSK);

						props.setInReview(false);
					}}>
					<Row>
						<h3>Group contacts update</h3>
						<hr />
						<Form.Group as={Col} className="mb-2">
							<label>Proposed</label>
						</Form.Group>
						<Form.Group as={Col} className="mb-2">
							<label>Current</label>
						</Form.Group>
					</Row>
					<Row>Secretary's Name</Row>
					<Row>
						<Form.Group as={Col} className="mb-2">
							<Form.Control
								type="input"
								name="1secname"
								placeholder={contact.SecretaryName}
								value={proposedSecName}
								onChange={(e) => setProposedSecName(e.target.value)}
							/>
						</Form.Group>
						<Form.Group as={Col} className="mb-2">
							<Form.Control
								type="input"
								name="2secname"
								value={dbSecName || ""}
								readOnly
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Button variant="outline-success" onClick={acceptSecName}>
								Accept proposed
							</Button>

							<div className="divider" />

							<Button
								variant="outline-secondary"
								size="sm"
								onClick={revertSecName}>
								Revert
							</Button>
						</Form.Group>
					</Row>

					<Row>Secretary's Email</Row>
					<Row>
						<Form.Group as={Col} controlId="currentSecEmail" className="mb-2">
							<Form.Control
								type="input"
								name="1secEmail"
								placeholder={contact.SecretaryEmail}
								value={proposedSecEmail}
								onChange={(e) => setProposedSecEmail(e.target.value)}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="dbSecEmail" className="mb-2">
							<Form.Control
								type="input"
								name="2secEmail"
								value={dbSecEmail || ""}
								readOnly
							/>
            </Form.Group>
            <Form.Group as={Col}>
						<Button
							variant="outline-success"
							onClick={acceptSecEmail}>
							Accept proposed
						</Button>
						<div className="divider" />
						<Button
							variant="outline-secondary"
							size="sm"
							onClick={revertSecEmail}>
							Revert
              </Button>
              </Form.Group>
					</Row>

					<Row>Secretary's Phone Number</Row>
					<Row>
						<Form.Group as={Col} controlId="currentSecPhone" className="mb-2">
							<Form.Control
								type="input"
								name="1secPhone"
								placeholder={contact.SecretaryPhone}
								value={proposedSecPhone}
								onChange={(e) => setProposedSecPhone(e.target.value)}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="dbSecPhone" className="mb-2">
							<Form.Control
								type="input"
								name="2secPhone"
								value={dbSecPhone || ""}
								readOnly
							/>
            </Form.Group>
            <Form.Group as={Col}>
						<Button
							variant="outline-success"
							onClick={acceptSecPhone}>
							Accept proposed
						</Button>
						<div className="divider" />
						<Button
							variant="outline-secondary"
							size="sm"
							onClick={revertSecPhone}>
							Revert
              </Button>
              </Form.Group>
					</Row>
					<hr />

					<Row>
						<Form.Group as={Col}>
							<label>Proposed</label>
						</Form.Group>
						<Form.Group as={Col}>
							<label>Current</label>
						</Form.Group>
					</Row>

					<Row>Treasurer's Name</Row>
					<Row>
						<Form.Group as={Col} controlId="currentTresName" className="mb-2">
							<Form.Control
								type="input"
								name="1tresName"
								placeholder={contact.TreasurerName}
								value={proposedTresName}
								onChange={(e) => setProposedTresName(e.target.value)}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="dbTresName" className="mb-2">
							<Form.Control
								type="input"
								name="2tresName"
								value={dbTresName || ""}
								readOnly
							/>
            </Form.Group>
            <Form.Group as={Col}>
						<Button
							variant="outline-success"
							onClick={acceptTresName}>
							Accept proposed
						</Button>
						<div className="divider" />
						<Button
							variant="outline-secondary"
							size="sm"
							onClick={revertTresName}>
							Revert
              </Button>
              </Form.Group>
					</Row>

					<Row>Treasurer's Email</Row>
					<Row>
						<Form.Group as={Col} controlId="currentTresEmail" className="mb-2">
							<Form.Control
								type="input"
								name="1tresEmail"
								placeholder={contact.TreasurerEmail}
								value={proposedTresEmail}
								onChange={(e) => setProposedTresEmail(e.target.value)}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="dbTresEmail" className="mb-2">
							<Form.Control
								type="input"
								name="2tresEmail"
								value={dbTresEmail || ""}
								readOnly
							/>
            </Form.Group>
            <Form.Group as={Col}>
						<Button
							variant="outline-success"
							onClick={acceptTresEmail}>
							Accept proposed
						</Button>
						<div className="divider" />
						<Button
							variant="outline-secondary"
							size="sm"
							onClick={revertTresEmail}>
							Revert
              </Button>
              </Form.Group>
					</Row>

					<Row>Treasurer's Phone Number</Row>
					<Row>
						<Form.Group as={Col} controlId="currentTresPhone" className="mb-2">
							<Form.Control
								type="input"
								name="1secPhone"
								placeholder={contact.TreasurerPhone}
								value={proposedTresPhone}
								onChange={(e) => setProposedTresPhone(e.target.value)}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="dbTresPhone" className="mb-2">
							<Form.Control
								type="input"
								name="2secPhone"
								value={dbTresPhone || ""}
								readOnly
							/>
            </Form.Group>
            <Form.Group as={Col}>
						<Button
							variant="outline-success"
							onClick={acceptTresPhone}>
							Accept proposed
						</Button>
						<div className="divider" />
						<Button
							variant="outline-secondary"
							size="sm"
							onClick={revertTresPhone}>
							Revert
              </Button>
              </Form.Group>
					</Row>
					<hr />

					<Row>
						<Form.Group as={Col}>
							<label>Proposed</label>
						</Form.Group>
						<Form.Group as={Col}>
							<label>Current</label>
						</Form.Group>
					</Row>

					<Row>GSR's Name</Row>
					<Row>
						<Form.Group as={Col} controlId="currentGSRName" className="mb-2">
							<Form.Control
								type="input"
								name="1GSRName"
								placeholder={contact.GSRName}
								value={proposedGsrName}
								onChange={(e) => setProposedGsrName(e.target.value)}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="dbGSRName" className="mb-2">
							<Form.Control
								type="input"
								name="2GSRName"
								label="Current"
								value={dbGsrName || ""}
								readOnly
							/>
            </Form.Group>
            <Form.Group as={Col}>
						<Button variant="outline-success" onClick={acceptGsrName}>
							Accept proposed
						</Button>
						<div className="divider" />
						<Button
							variant="outline-secondary"
							size="sm"
							onClick={revertGsrName}>
							Revert
              </Button>
              </Form.Group>
					</Row>

					<Row>GSR's Email</Row>
					<Row>
						<Form.Group as={Col} controlId="currentGSREmail" className="mb-2">
							<Form.Control
								type="input"
								name="1GSREmail"
								placeholder={contact.GSREmail}
								value={proposedGsrEmail}
								onChange={(e) => setProposedGsrEmail(e.target.value)}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="dbGSREmail" className="mb-2">
							<Form.Control
								type="input"
								name="2GSREmail"
								label="Current"
								value={dbGsrEmail || ""}
								readOnly
							/>
            </Form.Group>
            <Form.Group as={Col}>
						<Button
							variant="outline-success"
							onClick={acceptGsrEmail}>
							Accept proposed
						</Button>
						<div className="divider" />
						<Button
							variant="outline-secondary"
							size="sm"
							onClick={revertGsrEmail}>
							Revert
              </Button>
              </Form.Group>
          </Row>
         

					<Row>GSR's Phone Number</Row>
					<Row>
						<Form.Group as={Col} controlId="currentGSRPhone" className="mb-2">
							<Form.Control
								type="input"
								name="1GSRPhone"
								placeholder={contact.GSRPhone}
								value={proposedGsrPhone}
								onChange={(e) => setProposedGsrPhone(e.target.value)}
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="dbGSRPhone" className="mb-2">
							<Form.Control
								type="input"
								name="2GSRPhone"
								label="Current"
								value={dbGsrPhone || ""}
								readOnly
							/>
          </Form.Group>
          <Form.Group as={Col}>
						<Button
							variant="outline-success"
							onClick={acceptGsrPhone}>
							Accept proposed
						</Button>
						<div className="divider" />
						<Button
							variant="outline-secondary"
							size="sm"
							onClick={revertGsrPhone}>
							Revert
            </Button>
            </Form.Group>
					</Row>
					<hr />
					<Button size="lg" onClick={updateAll} variant="outline-success">
						Update all
					</Button>
					<div className="divider"></div>
					<Button onClick={revertAll} variant="outline-secondary">
						Revert all
					</Button>
					<div className="divider"></div>
					<Button size="lg" type="submit" variant="success">
						Save changes
					</Button>
					<div className="divider"></div>
					<Button size="lg"
						onClick={() => props.setInReview(false)}
						variant="secondary">
						Cancel
					</Button>
				</Form>
			</div>
		</>
	);
};

export default ReviewContactForm;
