import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ColouredLine from "../shapes/ColouredLine";
import { useState } from "react";

export default function DeleteButton(props) {
	const [checkDeleteEnable, setCheckDeleteEnable] = useState(false);

	return (
		<>
			<ColouredLine color="grey" />
			<Row>
				<Button
					size="lg"
					type="button"
					variant="danger"
					disabled={!checkDeleteEnable}
					onClick={() => props.deleteFunction()}>
					delete
				</Button>
			</Row>

			<Row>
				{["checkbox"].map((type) => (
					<div key={`default-${type}`} className="mb-3">
						<Form.Check
							type={type}
							id={`default-${type}`}
							checked={checkDeleteEnable}
							onChange={() => setCheckDeleteEnable((prev) => !prev)}
							label="tick for delete"></Form.Check>
					</div>
				))}
			</Row>
		</>
	);
}
