import { useEffect, useState, useRef } from "react";
import { useGroupsContext } from "../../../libs/context/groupsLib";
import getNtig from "../../../API/appSync/ntig/getNtig";
import listNtigs from "../../../API/appSync/ntig/listNtigs";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ColouredLine from "../../../components/shapes/ColouredLine";
import "bootstrap/dist/css/bootstrap.min.css";
import "./cssIntergroupAdmin.css";
import ReportingQuarterForm from "../../../components/forms/ReportingQuarterForm";
import ReportingQuarterSelectForm from "../../../components/forms/ReportingQuarterSelectForm";
import SecAdminCopyFnc from "../../../components/functions/SecAdminCopyFnc";
import MeetingMinutesCreate from "./MeetingMinsCreate";
import MeetingMinutesView from "./MeetingMinutesView";

export default function SecAdmin() {
	const { groupsContext } = useGroupsContext();
	const [inPeriodEdit, setInPeriodEdit] = useState(false);
	const [secCopyOpen, setSecCopyOpen] = useState(false);
	const [igAdminOpen, setIgAdminOpen] = useState(false);
	const [minsCreateOpen, setMinsCreateOpen] = useState(false);
	const [meetingMinsOpen, setMeetingMinsOpen] = useState(false);

	const periodPK = "PortalAdmin#Secretary";
	const periodSK = "ReportingPeriod";
	const periodType = "adminSecQtr";
	const [reportingPeriod, setReportingPeriod] = useState([]);
	const [reportingYr, setReportingYr] = useState();
	const [reportingQtr, setReportingQtr] = useState();
	const [propPeriod, setPropPeriod] = useState();
	const contactsPK = "Officers#2";
	const contactsType = "contacts";
	const contacts = useRef([]);
	const addressLists = useRef();

	useEffect(() => {
		(async () => {
			const data = await getNtig(periodPK, periodSK, periodType);
			setPropPeriod(data);
			const dbPeriod = data.split("/");
			setReportingYr(dbPeriod[0]);
			setReportingQtr(dbPeriod[1]);
		})();
	}, [reportingPeriod]);

	function createContactsArrays() {
		let gsrStrContacts = "";
		let tresStrContacts = "";
		let secStrContacts = "";
		for (let item in contacts.current) {
			//gsrs
			if (
				groupsContext.FullGroups[contacts.current[item].Id].Status === "Closed"
			) {
			} else {
				if (contacts.current[item].GSREmail.includes("@")) {
					gsrStrContacts =
						gsrStrContacts + contacts.current[item].GSREmail + "; ";
				} else if (contacts.current[item].SecretaryEmail.includes("@")) {
					gsrStrContacts =
						gsrStrContacts + contacts.current[item].SecretaryEmail + "; ";
				} else if (contacts.current[item].TreasurerEmail.includes("@")) {
					gsrStrContacts =
						gsrStrContacts + contacts.current[item].TreasurerEmail + "; ";
				}

				//treasurers
				if (contacts.current[item].TreasurerEmail.includes("@")) {
					tresStrContacts =
						tresStrContacts + contacts.current[item].TreasurerEmail + "; ";
				} else if (contacts.current[item].GSREmail.includes("@")) {
					tresStrContacts =
						tresStrContacts + contacts.current[item].GSREmail + "; ";
				} else if (contacts.current[item].SecretaryEmail.includes("@")) {
					tresStrContacts =
						tresStrContacts + contacts.current[item].SecretaryEmail + "; ";
				}

				if (contacts.current[item].SecretaryEmail.includes("@")) {
					secStrContacts =
						secStrContacts + contacts.current[item].SecretaryEmail + "; ";
				} else if (contacts.current[item].GSREmail.includes("@")) {
					secStrContacts =
						secStrContacts + contacts.current[item].GSREmail + "; ";
				} else if (contacts.current[item].TreasurerEmail.includes("@")) {
					secStrContacts =
						secStrContacts + contacts.current[item].TreasurerEmail + "; ";
				}
			}
		}
		addressLists.current = {
			gsr: gsrStrContacts,
			tres: tresStrContacts,
			sec: secStrContacts,
		};
	}

	useEffect(() => {
		(async () => {
			const data = await listNtigs(
				contactsPK,
				contactsType,
				groupsContext.FullGroups
			);
			contacts.current = data;
			createContactsArrays();
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const reportingPeriodChange = (newPeriod) => {
		setReportingPeriod(newPeriod);
		setInPeriodEdit(false);
	};

	return (
		<>
			<div className='divider'></div>
			<h2>Secretary Admin</h2>

			<ColouredLine color='grey' />
			{!igAdminOpen ? (
				<div className='IntergroupAdmin'>
					{!inPeriodEdit ? (
						<>
							<h3>current reporting period</h3>
							<ReportingQuarterForm
								reportingYr={reportingYr}
								reportingQtr={reportingQtr}
								setInPeriodEdit={setInPeriodEdit}
							/>
						</>
					) : (
						<>
							<h3>select reporting period</h3>
							<ReportingQuarterSelectForm
								reportingPeriodChange={reportingPeriodChange}
								setInPeriodEdit={setInPeriodEdit}
								propPeriod={propPeriod}
							/>
						</>
					)}

					<ColouredLine color='grey' />

					<Form>
						{!secCopyOpen ? (
							<Row>
								<Button
									variant='secondary'
									onClick={() => setSecCopyOpen(true)}>
									open group contacts mail functions
								</Button>
							</Row>
						) : (
							<SecAdminCopyFnc
								addressLists={addressLists.current}
								setSecCopyOpen={setSecCopyOpen}
							/>
						)}
					</Form>
					<ColouredLine color='grey' />
					<Form>
						<Row>
							<Button variant='secondary' onClick={() => setIgAdminOpen(true)}>
								open intergroup meeting admin
							</Button>
						</Row>
					</Form>
				</div>
			) : (
				<div className='IntergroupAdmin'>
					<h3>intergroup meeting admin</h3>
					<Form>
						<Row>
							<Button
								size='sm'
								variant='secondary'
								onClick={() => setIgAdminOpen(false)}>
								close intergroup meeting admin
							</Button>
						</Row>
					</Form>
					<ColouredLine color='grey' />

					{!minsCreateOpen ? (
						<>
							<Form>
								<Row>
									<Button
										variant='secondary'
										onClick={() => setMinsCreateOpen(true)}>
										create intergroup meeting minutes
									</Button>
								</Row>
							</Form>
						</>
					) : (
						<MeetingMinutesCreate
							setMinsCreateOpen={setMinsCreateOpen}
							reportingPeriodChange={reportingPeriodChange}
							propPeriod={propPeriod}
						/>
					)}

					<div className='divider'></div>

					{!meetingMinsOpen ? (
						<>
							<Form>
								<Row>
									<Button
										variant='secondary'
										onClick={() => setMeetingMinsOpen(true)}>
										view intergroup meeting minutes
									</Button>
								</Row>
							</Form>
						</>
					) : (
						<MeetingMinutesView
							setMeetingMinsOpen={setMeetingMinsOpen}
							reportingPeriod={propPeriod}
						/>
					)}
				</div>
			)}
		</>
	);
}
