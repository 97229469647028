
import { CognitoIdentityProviderClient, AdminDeleteUserCommand } from "@aws-sdk/client-cognito-identity-provider"
import awsmobile from "../../aws-exports";
import { Auth } from "@aws-amplify/auth";

export default async function AdminDeleteUser(username) {

    const data = await Auth.currentCredentials();
    const client = new CognitoIdentityProviderClient({
        region: awsmobile.aws_project_region,
        credentials: data,
    });
    const params = {
        UserPoolId: awsmobile.aws_user_pools_id,
        Username: username,
    };
    const command = new AdminDeleteUserCommand(params);
    try {
        await client.send(command);
        return true
    } catch (error) {
        // onError(error.message);
        return error
    }
}
