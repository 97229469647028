import "./IgOfficersTable.css";

function ExpensesTable(props) {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Group</th>
            <th>Payment Date</th>
            <th>Amount</th>
            {props.editExpense && (<td></td>)}
          </tr>
        </thead>
        <tbody>
          {props.reportExpenses.length > 0 ? (
            props.reportExpenses.map((expense) => (
              <tr key={expense.Id}>
                <td>
                  {expense.Group}
                </td>
                <td>{expense.Date}</td>
                <td>£ {expense.Expense}</td>
                {props.editExpense && (
                  <td> <button type="button"
                    onClick={() => {
                      props.reRenderExpense(expense);
                    }}
                    className="button muted-button"
                  >
                    delete
                  </button>
                  </td>)}
              </tr>
            )
            )
          ) : (
            <tr>
              <td colSpan={5}>Couldn't find any expenses</td>
            </tr>
          )}
        </tbody>
      </table>
      {props.reportExpenses.length > 0 && (
        <h5>
          <br />
          Total Expense - £ {props.expenseTotal}
        </h5>
      )}
    </>
  );
}

export default ExpensesTable;
