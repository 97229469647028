import React, { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import loadContext from "../../../components/functions/LoadContext";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import RenderConfirmationForm from "../../../components/forms/ConfCodeForm"
import RenderRegSignupForm from "../../../components/forms/RegSignupForm"
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderButton from "../../../components/buttons/LoaderButton";
import { useAuthContext } from "../../../libs/context/authenticatedLib";
import { useUserContext } from "../../../libs/context/userLib";
import { useGroupsContext } from "../../../libs/context/groupsLib";
import { useFormFields } from "../../../libs/hooksLib";
import { onError } from "../../../libs/userFeedback/errorLib";
import "./Login.css";
//import { Link } from 'react-router-dom';
import FadeIn from "react-fade-in";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userHasAuthenticated } = useAuthContext();
  const { setUserContext } = useUserContext(false);
  const { setGroupsContext } = useGroupsContext(false);
  const [newUser, setNewUser] = useState(null);
  const [isNeedNewPass, setIsNeedNewPass] = useState(false);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    async function groupsContext() {
      const loadGroupContext = await loadContext()
      setGroupsContext(loadGroupContext)
      setIsLoading(false);

      if (location.state?.from) {
        navigate(location.state.from)
      } else {
        navigate("/welcome");
      }
    }



    try {
      await Auth.signIn(fields.email, fields.password).then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          // the array of required attributes, e.g ['email', 'phone_number']
          setUser(user);
          setIsNeedNewPass(true);
        } else {
          userHasAuthenticated(true);

          //userIsAdmin(user.signInUserSession.accessToken.payload["cognito:groups"].includes("tlo-officers"));
          const isAdmin = user.signInUserSession.accessToken.payload['cognito:groups'].includes("ntig-admin-users");
          const isNtigExec = user.signInUserSession.accessToken.payload['cognito:groups'].includes("ntig-exec-users");
          const isPortalUser = user.signInUserSession.accessToken.payload['cognito:groups'].includes("ntig-groups-users");

          setUserContext({ user: user.signInUserSession.idToken.payload.email, userName: user.signInUserSession.idToken.payload.name, userIsAdmin: isAdmin, userIsNtigExec: isNtigExec, userIsPortalUser: isPortalUser })
          groupsContext()
        }
      });
    } catch (e) {
      onError(e);
      if (e.message === "User is not confirmed.") {
        setNewUser(true)
      }
      setIsLoading(false);
    }
  }

  function signupLink() {
    navigate("/signup");
  }
  function resetPassLink() {
    navigate("/forgotpassword");
  }

  function renderForm() {
    return (
      <FadeIn delay="800">
        <div className="Login">
          <Form onSubmit={handleSubmit}>
            <Form.Group size="lg" controlId="email">
              <Form.Label>email</Form.Label>
              <Form.Control autoFocus type="email" value={fields.email} onChange={handleFieldChange} />
            </Form.Group>
            <Form.Group size="lg" controlId="password">
              <Form.Label>password</Form.Label>
              <Form.Control type="password" value={fields.password} onChange={handleFieldChange} />
              <Button variant="link" onClick={resetPassLink}>forgot your password?</Button>
              {/* <Link className="Link" onClick={resetPassLink}>
              forgot your password?
            </Link> */}
            <div className="divider"></div>
            </Form.Group>
            <Row>
            <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={!validateForm()}>
              login
            </LoaderButton>
            </Row>
            <Button variant="link" onClick={signupLink}>no account? signup here</Button>
            {/* <div className="Link">
            <Link className="Link" onClick={signupLink}>
              no account? signup here
            </Link>
          </div> */}
          </Form>
        </div>
      </FadeIn>
    );
  }

  function render() {
    if (isNeedNewPass) {
      return <div className="Signup"> <RenderRegSignupForm user={user} /></div>;
    }
    return <div className="Signup">{newUser === null ? renderForm() : <RenderConfirmationForm email={fields.email} password={fields.password} />}</div>;
  }

  return render()

}

