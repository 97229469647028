import "./IgOfficersTable.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button"
import { useUserContext } from "../../libs/context/userLib";

function PortalUsersTable(props) {
  const { userContext } = useUserContext();
  const isLoading = props.isLoading
  return (
    isLoading ? (
      <>
      <div className="divider"></div>
        <div>Loading data.....</div>
        </>
  ) : (
    <table>
      <thead>
        <tr>
          {/* <th>User Name</th> */}
          <th>Email</th>
          <th>Account Status</th>
          <th>Groups users</th>
          <th>Exec users</th>
          <th>Admin users</th>
          {userContext.userIsAdmin &&  (
            <th></th>)}
        </tr>
      </thead>
      <tbody>
        {props.userDetails.length > 0 ? (
          props.userDetails.map((user) => (
            <tr key={user.id}>
              {/* <td>{user.Username}</td> */}
              <td>{user.Email}</td>
              <td>{user.AccountStatus}</td>
              <td>
                <Form.Check  type="radio" aria-label="radio 1" checked={user.NtigUsers} disabled />
              </td>
              <td>
                <Form.Check type="radio" aria-label="radio 1" checked={user.NtigExec} disabled />
              </td>
              <td>
                <Form.Check type="radio" aria-label="radio 1" checked={user.NtigAdmin} disabled />
              </td>
              <td>
                {userContext.userIsAdmin && user.Email !== userContext.user && user.Email !== "ecomm.nthames@aamail.org" && (
                  <Button size="sm" variant="outline-secondary" type="button" onClick={() => { props.reviewRow(user) }}>edit</Button>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5}></td>
          </tr>
        )}
      </tbody>
    </table>
  ));
}
export default PortalUsersTable;
