import { useState } from "react";
import { useGroupsContext } from "../../libs/context/groupsLib";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row'
import "bootstrap/dist/css/bootstrap.min.css";
import "./ReviewContactForm.css"

export default function AddGroupForm(props) {
    const { groupsContext } = useGroupsContext()
    const [newGroupName, setNewGroupName] = useState("")

    const varPK = "Status";
    const varSK = "Test";

    function validateForm() {
        return newGroupName.length > 0
    }

    return (
        <>
            <div className="ReviewContactForm">
                <Form
                    onSubmit={(event) => {
                        event.preventDefault();
                        const groupKeys = Object.keys(groupsContext.FullGroups)
                        let objGroups = ""
                        for (let key in groupsContext.GroupKeys) {
                            objGroups = objGroups + '"' + groupKeys[key] + '": {"Name": "' + groupsContext.FullGroups[groupKeys[key]].Name + '","Status":"' + groupsContext.FullGroups[groupKeys[key]].Status + '"},'
                        }

                        let newGroupId = groupsContext.GroupKeys.length + 1                       
                        objGroups = objGroups + '"' + newGroupId + '": {"Name": "' + newGroupName + '","Status":"Active"}'
                        let objFullGroups = '{' + objGroups + '}'

                        let sendData = {
                            PK: varPK,
                            SK: varSK,
                            FullGroups: objFullGroups
                        }


                        let newGroup = {
                            id: newGroupId,
                            Name: newGroupName,
                            Status: "Active"
                        }


                        props.addGroup(sendData, newGroup)
                    }}
                >
                    <h2>Add New Group</h2>
                    <Row>
                        <Form.Group as={Col} controlId="newGroupName" className="mb-2">
                            <Form.Control type="input" name="groupname" value={newGroupName} onChange={(e) => setNewGroupName(e.target.value)}/>
                        </Form.Group>
                        <Button size="lg" type="submit" variant="success" disabled={!validateForm()}>
                            add group
                        </Button>
                    </Row>
                </Form>
            </div>
        </>
    );
};

