/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNTIG = /* GraphQL */ `
  mutation CreateNTIG($input: CreateNTIGInput!) {
    createNTIG(input: $input) {
      PK
      SK
      Group
      Groups
      FullGroups
      Date
      Detail
      Donation
      Roles
      Period
      GSR
      Secretary
      Treasurer
      Submitter
      PaymentDate
      Officers
      Finance
    }
  }
`;
export const updateNTIG = /* GraphQL */ `
  mutation UpdateNTIG($input: UpdateNTIGInput!) {
    updateNTIG(input: $input) {
      PK
      SK
      Group
      Groups
      FullGroups
      Date
      Detail
      Donation
      Roles
      Period
      GSR
      Secretary
      Treasurer
      Submitter
      PaymentDate
      Officers
      Finance
    }
  }
`;
export const deleteNTIG = /* GraphQL */ `
  mutation DeleteNTIG($input: DeleteNTIGInput!) {
    deleteNTIG(input: $input) {
      PK
      SK
      Group
      Groups
      FullGroups
      Date
      Detail
      Donation
      Roles
      Period
      GSR
      Secretary
      Treasurer
      Submitter
      PaymentDate
      Officers
      Finance
    }
  }
`;
export const secNotify = /* GraphQL */ `
  mutation SecNotify($input: secNotifyInput!) {
    secNotify(input: $input) {
      Type
      GroupId
      Group
      Officer
      Date
      Detail
      Donation
      Period
      GSR
      Secretary
      Treasurer
      Submitter
      CopyReport
    }
  }
`;
export const tresNotify = /* GraphQL */ `
  mutation TresNotify($input: tresNotifyInput!) {
    tresNotify(input: $input) {
      GroupId
      Group
      PaymentDate
      Donation
      Submitter
    }
  }
`;
