import React from "react";
import { Route, Routes, useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "./libs/context/authenticatedLib";
import { useUserContext } from "./libs/context/userLib"
import Login from "./containers/unrestrictedPages/authPages/Login";
import Signup from "./containers/unrestrictedPages/authPages/Signup";
import ForgotPassword from "./containers/unrestrictedPages/authPages/ForgotPassword";
import ChangePassword from "./containers/accountManagement/ChangePassword";
import ManageProfile from "./containers/accountManagement/ManageProfile";
import SubmitSuccess from "./containers/unrestrictedPages/miscPages/SubmitSuccess";
import ContactsUpdate from "./containers/adminPages/integroupAdmin/ContactsUpdate"
import CreateUser from "./containers/adminPages/userManagement/CreateUser";
import ManageUsers from "./containers/adminPages/userManagement/ManageUsers";
import SecAdmin from "./containers/adminPages/integroupAdmin/SecAdmin";
import IgOfficers from "./containers/execPages/IgOfficers"
import OfficerQtrlyForm from "./containers/execPages/OfficerQtrlyReport"
//import OfficerReports from "./containers/execPages/OfficerReports"
import ActiveGroups from "./containers/execPages/ActiveGroups"
import GroupsMgt from "./containers/execPages/GroupsMgt"
//import GroupReports from "./containers/execPages/GroupReports"
import ReportsView from "./containers/groupsPages/ReportsView"
import Welcome from "./containers/homePage/Welcome";
import Contacts from "./containers/groupsPages/Contacts";
import Donation from "./containers/groupsPages/Donation";
import GroupQtrlyForm from "./containers/groupsPages/GroupQtrlyForm";
import NotFound from "./containers/unrestrictedPages/miscPages/NotFound";

export default function Routing() {
  const { isAuthenticated } = useAuthContext();
  const { userContext } = useUserContext();

  const AuthenticatedRoute = () => {
    const location = useLocation()
    //available for all authenticated users
    return isAuthenticated ? (
      <Outlet />
    ) : (
      <Navigate to="/login" replace state={{from: location}} />
    )
  }

  const AdminRoute= () => {
    const location = useLocation()

    //available for all authenticated users
    return userContext.userIsAdmin ? (
      <Outlet />
    ) : (
      <Navigate to="/login" replace state={{from: location}} />
    )
  }

  const ExecRoute= () => {
    const location = useLocation()

    //available for all authenticated users
    return userContext.userIsAdmin || userContext.userIsNtigExec ? (
      <Outlet />
    ) : (
      <Navigate to="/login" replace state={{from: location}} />
    )
  }

  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/forgotpassword" element={<ForgotPassword />} />
      <Route element={<AdminRoute />} >
        <Route exact path="/contactsupdate" element={<ContactsUpdate />} />
        <Route exact path="/createuser" element={<CreateUser />} />
        <Route exact path="/manageusers" element={<ManageUsers />} />
        <Route exact path="/secadmin" element={<SecAdmin />} />
      </Route>
      <Route element={<ExecRoute />} >
        <Route exact path="/activegroups" element={<ActiveGroups />} />
        <Route exact path="/groupsmgt" element={<GroupsMgt />} />
        <Route exact path="/igofficers" element={<IgOfficers />} />
        <Route exact path="/officerqrtrlyform" element={<OfficerQtrlyForm />} />
      </Route>
      <Route element={<AuthenticatedRoute />} >
        <Route exact path="/" element={<Welcome />} />
        <Route exact path="/ntigreports" element={<ReportsView />} />
        <Route exact path="/welcome" element={<Welcome />} />
        <Route exact path="/changepassword" element={<ChangePassword />} />
        <Route exact path="/manageprofile" element={<ManageProfile />} />
        <Route exact path="/donation" element={<Donation />} />
        <Route exact path="/contacts" element={<Contacts />} />
        <Route exact path="/grouptqrtlyform" element={<GroupQtrlyForm />} />
        <Route exact path="/submitsuccess" element={<SubmitSuccess />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );


}
