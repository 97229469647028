import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../libs/context/userLib";

export default function NavDrop(props) {
  const navigate = useNavigate();
  const { userContext } = useUserContext();

  const handleSelect = (eventKey) => {
    switch (eventKey) {
      case "3.0":
        navigate("/welcome");
        break;

      case "3.1":
        navigate("/donation");
        break;

      case "3.2":
        navigate("/contacts");
        break;

      case "3.3":
        navigate("/grouptqrtlyform");
        break;

      case "4.1":
        navigate("/createuser");
        break;

      case "4.2":
        navigate("/manageusers");
        break;
      
        case "4.3":
          navigate("/secadmin");
          break;

      case "5.1":
        navigate("/contactsupdate");
        break;

      case "5.2":
        navigate("/groupsmgt");
        break;

      case "5.3":
        navigate("/igofficers");
        break;

      case "5.5":
        navigate("/officerqrtrlyform");
        break;

      case "5.4":
        navigate("/ntigreports");
        break;

      case "8.1":
        navigate("/changepassword");
        break;

      case "8.2":
        navigate("manageprofile");
        break;

      case "9.1":
        props.handleLogout();
        break;

      default:
        navigate("/");
    }
  };

  return (
    <Navbar collapseOnSelect bg="light" expand="md" className="bg-light justify-content-between" onSelect={handleSelect}>
      <Navbar.Brand className="font-weight-bold text-muted">
        <NavDropdown title="NTIG Portal" id="nav-dropdown">
          <NavDropdown.Item eventKey="3.0">Home</NavDropdown.Item>
          {userContext.userIsAdmin && (
            <>
              <NavDropdown.Divider />
              <NavDropdown.Item eventKey="5.1">Admin: Groups' contacts - submissions</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.1">Admin: Create user</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2">Admin: Manage users</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.3">Admin: Secretary admin</NavDropdown.Item>
            </>
          )}
          {(userContext.userIsAdmin || userContext.userIsNtigExec) && (
            <>
              <NavDropdown.Divider />
              {/* <NavDropdown.Item eventKey="5.0">Exec: Active Groups</NavDropdown.Item> */}
              <NavDropdown.Item eventKey="5.3">Exec: Active Intergroup Officers</NavDropdown.Item>
              <NavDropdown.Item eventKey="5.2">Exec: Groups Management</NavDropdown.Item>
              <NavDropdown.Item eventKey="5.5">Exec: Officer Reports - Submit</NavDropdown.Item>
              <NavDropdown.Item eventKey="5.4">Exec: NTIG Reports</NavDropdown.Item>
            </>
          )}
          <NavDropdown.Divider />
          <NavDropdown.Item eventKey="3.2">Group: Contacts update</NavDropdown.Item>
          <NavDropdown.Item eventKey="3.1">Group: Donation notification</NavDropdown.Item>
          <NavDropdown.Item eventKey="3.3">Group: Quarterly report submission</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item eventKey="8.1">Account: Change password</NavDropdown.Item>
          <NavDropdown.Item eventKey="8.2">Account: Manage Profile</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item eventKey="9.1">Logout</NavDropdown.Item>
        </NavDropdown>
        {/* <Navbar.Text>{JSON.stringify(userContext)}</Navbar.Text> */}
      </Navbar.Brand>
    </Navbar>
  );
}