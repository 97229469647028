import API, { graphqlOperation } from "@aws-amplify/api"
import * as queries from "../../graphql/queries"


export default async function loadContext() {
  const apiGroups = await API.graphql(graphqlOperation(queries.getNTIG, { PK: "Status", SK: "Test" }))
  const fullGroups = JSON.parse(apiGroups.data.getNTIG.FullGroups)
  const groupKeys = Object.keys(JSON.parse(apiGroups.data.getNTIG.FullGroups))
  const activeGroups = {}
  const activeKeys = {}
  const inactiveGroups = {}
  const inactiveKeys = {}
  for (let i in groupKeys) {
    if (fullGroups[groupKeys[i]].Status === "Active" || fullGroups[groupKeys[i]].Status === "Temporarily Closed") {
      activeGroups[groupKeys[i]] = fullGroups[groupKeys[i]].Name
      activeKeys[fullGroups[groupKeys[i]].Name] = groupKeys[i]
    }
    if (fullGroups[groupKeys[i]].Status === "Closed") {
      inactiveGroups[groupKeys[i]] = fullGroups[groupKeys[i]].Name
      inactiveKeys[fullGroups[groupKeys[i]].Name] = groupKeys[i]
    }
    activeGroups["00"] = "-select your group-"
    activeKeys["-select your group-"] = "00"
  }
  //console.log(groupKeys)
  // const arrActiveGroups = Array.from(Object.values(groupsContext.ActiveGroups))
  // const arrInactiveGroups = Array.from(Object.values(groupsContext.InactiveGroups))
  return ({ GroupKeys: groupKeys, FullGroups: fullGroups, ActiveGroups: activeGroups, InactiveGroups: inactiveGroups, ActiveKeys: activeKeys, InactiveKeys: inactiveKeys })

}



