import { useState, useEffect } from "react";
import { useUserContext } from "../../../libs/context/userLib";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import "bootstrap/dist/css/bootstrap.min.css";
import LoaderButton from "../../../components/buttons/LoaderButton";
import "./CreateUser.css";
import UserEditForm from "../../../components/forms/UserEditForm"
import FadeIn from "react-fade-in";
import AdminCreateUser from "../../../API/Cognito/AdminCreateUser";
import { onSuccess } from "../../../libs/userFeedback/successLib";


export default function CreateUser() {
  const [isLoading, setIsLoading] = useState(false);
  const { userContext } = useUserContext();
  const initialFormState = { id: null, Email: "", NtigExec: "", NtigAdmin: "" };
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const [userCreated, setUserCreated] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format"),
  });

  useEffect(() => {
    if (!userCreated) formik.setFieldValue("email", "")
    // eslint-disable-next-line
  }, [userCreated]);
  
  function validateForm() {
    return formik.values.email.length > 0 && formik.isValid;
  }

  const formik = useFormik({
    initialValues: {
      email: ""
    },

    validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      let cognito_user_pool_groups = ["ntig-groups-users"];

      const contactSubmission = async () => {
        let response = await AdminCreateUser(formik.values.email, cognito_user_pool_groups)
            if (response === true) {
              setIsLoading(false)
              onSuccess("User added successfully.")
              setUserCreated(true)
              setCurrentUser({ id: 0, Email: formik.values.email, AccountStatus: false, NtigExec: false, NtigAdmin: false })
              
            } else {
              setIsLoading(false)
            }
      }

      contactSubmission();
    }
  })
  function renderCreateUserForm() {
    return (
      <>
        <h2>register a new portal user</h2>
        <FadeIn delay="800">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group size="lg" controlId="email">
              <Form.Label>email</Form.Label>
              <Form.Control name="email" type="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.email && formik.errors.email}></Form.Control>
              <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
            </Form.Group>

      
              {/* <>
                <ColouredLine color="grey" />
                <h3>additional portal access</h3>
                <Row>
                  {["checkbox"].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check type={type} id={`default-${type}`} checked={checkNtigExec} onChange={() => setCheckNtigExec((prev) => !prev)} label="user is a NTIG officer"></Form.Check>
                    </div>
                  ))}
                </Row>
                <Row>
                  {["checkbox"].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check type={type} id={`default-${type}`} checked={checkAdminUser} onChange={() => setCheckAdminUser((prev) => !prev)} label="user is portal admin"></Form.Check>
                    </div>
                  ))}
                </Row>
              </> */}
        

            <div className="divider"></div>
            <Row>
              <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={!validateForm()}>
                create
              </LoaderButton>
            </Row>
          </Form>
        </FadeIn>
      </>
    );
  }

  return (
    <div>
      {userContext.userIsAdmin && userCreated ? (<div className="NewUser"><UserEditForm setInEdit={setUserCreated} currentUser={currentUser} /> </div >) : (<div className="NewUser">{renderCreateUserForm()}</div>)}
    </div>
)}
