import React from "react";
import "./ReportsTable.css";
import Button from 'react-bootstrap/Button'

export default function GroupsReportsTable(props) {
  const isLoading = props.isLoading
  return (
    
    isLoading ? (
      <>
      <div className="divider"></div>
        <div>Loading data.....</div>
        </>
    ) : (
      <div className="ReportsTable">
        <table>
          <thead>
            <tr>
              <th>Group</th>
              {(props.userIsAdmin || props.userIsExec) && (<th>Submitted by</th>)}
              <th style={{ width: "600px" }}>Report</th>
              <th>Donation</th>
            </tr>
          </thead>
          <tbody>
            {props.reports.length > 0 ? (
              props.reports.map((report) => (
                <tr key={report.Id}>
                  <td style={{ textAlign: "centre" }}>
                    <Button variant="link" onClick={() => {
                      props.reviewGroupReportRow(report);
                    }} disabled={props.userIsAdmin !== true}>{report.Group.trim()}</Button>
                  </td>
                  {(props.userIsAdmin || props.userIsExec) && (<td>
                    {report.SubmitterName}
                    <br />
                    Date submitted: {report.Date}
                    {/* <br />
                {report.SubmitterEmail} */}
                  </td>)}
                  <td style={{ width: "450px" }}>{report.Detail.split("\n").map((value, index) => {
                    return (
                      <span key={index}>
                        {value}
                        <br />
                      </span>
                    )
                  })}</td>
                  <td>£ {report.Donation}</td>
                  {/* <td>
                <button className="button muted-button">Review</button>
              </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>There aren't group reports for the selected quarter</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    ))
}
