import React, { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../../../components/buttons/LoaderButton";
import Button from 'react-bootstrap/Button'
import { onError } from "../../../libs/userFeedback/errorLib";
import { useAuthContext } from "../../../libs/context/authenticatedLib";
import { useFormFields } from "../../../libs/hooksLib";
import "./Login.css";
import FadeIn from "react-fade-in";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { userHasAuthenticated } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [confCode, setConfCode] = useState("")
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    newPassword: "",
    confirmPassword: "",
    confirmationCode: ""
  });

  async function resendConfirmationCode() {
    try {
      await Auth.forgotPassword(fields.email)
      onError('code resent successfully');
    } catch (err) {
      onError('error resending code: ', err);
    }
    setConfCode("")
  }

  function handleChange(event) {
    setConfCode(event.target.value);
  }

  function validateNewPassForm() {
    return fields.confirmPassword.length > 0 && fields.newPassword.length > 0 && fields.confirmPassword === fields.newPassword && confCode.length > 0;
  }

  function validateForgotPassForm() {
    return fields.email.length > 0;
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    await Auth.forgotPasswordSubmit(fields.email, confCode, fields.newPassword)
      .then((user) => {
        setIsLoading(false);
        userHasAuthenticated(false);
        navigate("/");
      })
      .catch((err) => {
        onError(err);
        setIsLoading(false);
        console.log(err);
      });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    await Auth.forgotPassword(fields.email)
      .then((data) => {
        console.log(data);
        setIsLoading(false);
        setIsConfirm(true);
      })
      .catch((err) => {
        onError(err);
        setIsLoading(false);
        console.log(err);
      });
  }

  function renderForgotPassForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>email</Form.Label>
          <Form.Control autoFocus type="email" onChange={handleFieldChange} value={fields.email} />
        </Form.Group>
        <div className="divider"></div>
        <Row>
        <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={!validateForgotPassForm()}>
          confirm
        </LoaderButton>
        </Row>
      </Form>
    );
  }

  function renderConfirmationForm() {
    return (
      <FadeIn delay="800">
        <div className="signup">
          <Form onSubmit={handleConfirmationSubmit}>
            <Form.Group size="lg" controlId="newPassword">
              <Form.Label>enter a new password</Form.Label>
              <Form.Control type="password" value={fields.newPassword} onChange={handleFieldChange} />
            </Form.Group>
            <Form.Group size="lg" controlId="confirmPassword">
              <Form.Label>confirm password</Form.Label>
              <Form.Control type="password" value={fields.confirmPassword} onChange={handleFieldChange} />
            </Form.Group>
            <Form.Group controlId="confirmationCode" size="lg">
              <Form.Label>please check your email for the confirmation code.</Form.Label>
              <Form.Control autoFocus type="tel" onChange={handleChange} value={confCode} />
            </Form.Group>
            <Row>
            <Button variant="link" onClick={resendConfirmationCode}>need a new code? click here</Button>
            <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={!validateNewPassForm()}>
              verify
            </LoaderButton>
            </Row>
          </Form>
        </div>
      </FadeIn>
    );
  }

  return <div className="Login">{isConfirm === false ? renderForgotPassForm() : renderConfirmationForm()}</div>;
}
