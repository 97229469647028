import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";


createRoot(
  document.querySelector("#root"),
)
.render(
  <React.StrictMode>
   <Router>
      <App />
    </Router>
  </React.StrictMode>,
);


if (module.hot) {
  module.hot.accept();
}
