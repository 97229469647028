import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import RenderConfirmationForm from "../../../components/forms/ConfCodeForm"
import LoaderButton from "../../../components/buttons/LoaderButton";
import { useFormFields } from "../../../libs/hooksLib";
import { onError } from "../../../libs/userFeedback/errorLib";
import "./Signup.css";
import FadeIn from "react-fade-in";

export default function Signup() {
  const navigate = useNavigate();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
  });

  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);



  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0 && fields.name.length > 0 && fields.password === fields.confirmPassword;
  }



  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          name: fields.name
        }
      });
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      if (e.message === "An account with the given email already exists.") {
        navigate("/login");
      }
    }
    setIsLoading(false);
  }

  function renderForm() {
    return (
      <FadeIn delay="800">
        <div className="Confirmsignup">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email" size="lg">
              <Form.Label>email</Form.Label>
              <Form.Control autoFocus type="email" value={fields.email} onChange={handleFieldChange} />
            </Form.Group>
            <Form.Group controlId="password" size="lg">
              <Form.Label>password</Form.Label>
              <Form.Control type="password" value={fields.password} onChange={handleFieldChange} />
            </Form.Group>
            <Form.Group controlId="confirmPassword" size="lg">
              <Form.Label>confirm password</Form.Label>
              <Form.Control type="password" onChange={handleFieldChange} value={fields.confirmPassword} />
            </Form.Group>
            <Form.Group controlId="name" size="lg">
              <Form.Label>name</Form.Label>
              <Form.Control type="input" onChange={handleFieldChange} value={fields.name} />
            </Form.Group>
            <div className="divider"></div>
            <Row>
            <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={!validateForm()}>
              signup
            </LoaderButton>
            </Row>
          </Form>
        </div>
      </FadeIn>
    );
  }

  return <div className="Signup">{!newUser ? renderForm() : <RenderConfirmationForm email={fields.email} password={fields.password} />}</div>;
}
