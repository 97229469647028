import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import InputGroup from "react-bootstrap/InputGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState, } from "react";
import LoaderButton from "../../components/buttons/LoaderButton";
import Button from "react-bootstrap/Button";
import ColouredLine from "../shapes/ColouredLine"
import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";
import deleteNtig from "../../API/appSync/ntig/deleteNtig"
import { useGroupsContext } from "../../libs/context/groupsLib";
import FadeIn from "react-fade-in";
import { onError } from "../../libs/userFeedback/errorLib";

export default function GroupQuarterlyReportForm(props) {
    const { groupsContext } = useGroupsContext()
    const [checkDeleteEnable, setCheckDeleteEnable] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const title = "you are editing"
    const currentGroupReport = props.currentGroupReport

    const amountRegex = /^\d+(\.\d{1,2})?$/;
    const validationSchema = Yup.object().shape({
        reportText: Yup.string().required("Required"),
        donation: Yup.string().required("Required").matches(amountRegex, "Enter a valid amount"),
    });

    async function deleteReport() {
        await deleteNtig(props.PK, `Group#${groupsContext.ActiveKeys[currentGroupReport.Group]}`)
        .then ((response) => {
            if (response === true ) {
                onError("Report deleted successfully")
                props.setInGroupEdit(false)
                props.deletedReport(props.currentGroupReport.Id)
            } else {
                onError(response)
            }
        })
        .catch((error) => {
            onError(error.message)
        })
    }


    function completeSubmit() {
        setIsLoading(false);
        onError("Report updated successfully")
        props.setInGroupEdit(false)
        props.updateReports()
    }

    const formik = useFormik({
        initialValues: {
            reportText: props.currentGroupReport.Detail,
            donation: props.currentGroupReport.Donation
        },

        validationSchema,
        onSubmit: (values) => {
            setIsLoading(true);



            //create JSON for graphql createNtig submits group report
            var newGroupReport = {
                PK: props.PK,
                SK: `Group#${groupsContext.ActiveKeys[currentGroupReport.Group]}`,

                Detail: formik.values.reportText,
                Donation: formik.values.donation,
            };

            // submit group report with a #1 status
            const reportSubmission = async () => {
                await API.graphql(graphqlOperation(mutations.updateNTIG, { input: newGroupReport }))
                    .then((data) => {
                        completeSubmit()
                    })
                    .catch((error) => {
                        onError(error);
                        setIsLoading(false);
                    });
            };

            reportSubmission();
        }
    });

    function validateChanges() {
        if (formik.values.reportText === props.currentGroupReport.Detail && formik.values.donation === props.currentGroupReport.Donation) return true
        if (!formik.isValid) return true
        return false
    }
    

    return (
        <>
            <FadeIn transitionDuration="500">
                <div className="GroupQtrlyReport">
                    <h2>{title}</h2>
                    <div className="divider"></div>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group controlId="group">
                            <Row>
                                <Form.Label>Group</Form.Label>
                                <Form.Control name="name" type="input" value={currentGroupReport.Group} readOnly></Form.Control>
                            </Row>
                        </Form.Group>
                        <div className="divider"></div>
                        <Form.Group controlId="name">
                            <Row>
                                <Form.Label>Submitter details</Form.Label>
                                <Form.Control name="name" type="input" value={currentGroupReport.SubmitterName} readOnly></Form.Control>
                            </Row>
                            {/* <Row style={{ color: "red" }}>{formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}</Row> */}
                        </Form.Group>

                        <Form.Group controlId="email">
                            <Row>
                                <Form.Control name="email" type="input" value={currentGroupReport.SubmitterEmail} readOnly></Form.Control>
                            </Row>
                            {/* <Row style={{ color: "red" }}>{formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}</Row> */}
                        </Form.Group>
                        <div className="divider"></div>
                        <Form.Group controlId="reportText">
                            <Row>
                                <Form.Label>Group's report</Form.Label>
                                <Form.Control name="reportText" as="textarea" rows={8} value={formik.values.reportText} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.reportText && formik.errors.reportText}></Form.Control>
                                <Form.Control.Feedback type="invalid">{formik.errors.reportText}</Form.Control.Feedback>
                                <br />
                            </Row>
                            <Row>
                                <Button variant="outline-secondary" type="button" onClick={() => { formik.setFieldValue("reportText", props.currentGroupReport.Detail, false) }} disabled={formik.values.reportText === props.currentGroupReport.Detail}>revert</Button>
                            </Row>
                            {/* <Row style={{ color: "red" }}>{formik.touched.reportText && formik.errors.reportText ? <div>{formik.errors.reportText}</div> : null}</Row> */}
                        </Form.Group>
                        <div className="divider"></div>
                        <Form.Group controlId="donation">
                            
                            <Row>
                                <Form.Label>Group's donation in quarter</Form.Label>
                                <InputGroup>
                                        <InputGroup.Text id="basic-addon1">£</InputGroup.Text>
                                       
                                    <Form.Control name="donation" type="float" value={formik.values.donation} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={formik.touched.donation && formik.errors.donation}></Form.Control>
                                    <Form.Control.Feedback type="invalid">{formik.errors.donation}</Form.Control.Feedback>
                                    <InputGroup.Text id="basic-addon2">
                                            <Button size="sm" variant="outline-secondary" type="button" onClick={() => { formik.setFieldValue("donation", props.currentGroupReport.Donation, false) }} disabled={formik.values.donation === props.currentGroupReport.Donation}>revert</Button>
                                            </InputGroup.Text>
                                </InputGroup>
                            </Row>
                            {/* <Row style={{ color: "red" }}>{formik.touched.donation && formik.errors.donation ? <div>{formik.errors.donation}</div> : null}</Row> */}
                        </Form.Group>
                        <ColouredLine color="grey"/>
                        <Form.Group>
                        <Row>
                            <Button size="lg" onClick={() => props.setInGroupEdit(false)} variant="secondary">
                                cancel
                            </Button>
                            </Row>
                        <div className="divider"></div>
                        <Row>
                            <LoaderButton size="lg" type="submit" variant="success" isLoading={isLoading} disabled={validateChanges()}>
                                submit
                            </LoaderButton>
                            </Row>
                        </Form.Group>
                        <ColouredLine color="grey" />
                        <Row>
                        <LoaderButton size="lg" type="button" variant="danger" isLoading={isLoading} disabled={!checkDeleteEnable} onClick={() => deleteReport()}  >
                                delete
                            </LoaderButton>
                        </Row>
                        <Row>
				{["checkbox"].map((type) => (
					<div key={`default-${type}`} className="mb-3">
						<Form.Check
							type={type}
							id={`default-${type}`}
							checked={checkDeleteEnable}
							onChange={() => setCheckDeleteEnable((prev) => !prev)}
							label="tick for delete"></Form.Check>
					</div>
				))}
			</Row>
                    </Form>
                </div>
            </FadeIn>
        </>
    );
}

