import { useEffect, useState, useRef } from "react";
import GroupsReportsTable from "../../components/tables/GroupsReportsTable";
import EditGroupReportForm from "../../components/forms/EditGroupReportForm";
import OfficerReportsTable from "../../components/tables/OfficerReportsTable";
import EditOfficerReportForm from "../../components/forms/EditOfficerReportForm";
import listNtigs from "../../API/appSync/ntig/listNtigs";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ColouredLine from "../../components/shapes/ColouredLine";
import { useAuthContext } from "../../libs/context/authenticatedLib";
import { useUserContext } from "../../libs/context/userLib";
import { useGroupsContext } from "../../libs/context/groupsLib";

export default function ReportView() {
	const type = useRef("");
	const makeDatestamp = new Date();
	const currentMonth = makeDatestamp.getMonth() + 1; //months start at 0
	const quarters = ["Q1", "Q2", "Q3", "Q4"];

	let currentQuarter = "";
	if (currentMonth <= 3) {
		currentQuarter = "Q1";
	} else if (currentMonth <= 6) {
		currentQuarter = "Q2";
	} else if (currentMonth <= 9) {
		currentQuarter = "Q3";
	} else {
		currentQuarter = "Q4";
	}
	const [quarter, setQuarter] = useState(currentQuarter);
	const [year, setYear] = useState(new Date().getFullYear());

	const [filterPK, setFilterPK] = useState(
		"Report#" + year + "/" + quarter + "#2"
	);
	const { isAuthenticated } = useAuthContext();
	const { groupsContext } = useGroupsContext();
	const { userContext } = useUserContext();
	const [reports, setReports] = useState([]);
	const types = ["-select report type-", "Group Reports", "Officer Reports"];
	const [isLoading, setIsLoading] = useState(true);
	const [inGroupEdit, setInGroupEdit] = useState(false);
	const [inOfficerEdit, setInOfficerEdit] = useState(false);
	const initialGroupFormState = {
		Id: "",
		Group: "",
		Date: "",
		SubmitterName: "",
		SubmitterEmail: "",
		Detail: "",
		Donation: "",
	};
	const initialOfficerFormState = {
		Id: "",
		Officer: "",
		Date: "",
		SubmitterName: "",
		SubmitterEmail: "",
		IncomeTotal: "",
		ExpenseTotal: "",
		QuarterBalance: "",
		Detail: "",
	};
	const [currentGroupReport, setCurrentGroupReport] = useState(
		initialGroupFormState
	);
	const [currentOfficerReport, setCurrentOfficerReport] = useState(
		initialOfficerFormState
	);
	const [renderValue, setRenderValue] = useState(0);

	useEffect(() => {
		(async () => {
			//onError(filterPK + type.current)
			if (type.current !== "") {
				const data = await listNtigs(
					filterPK,
					type.current,
					groupsContext.FullGroups,
					isAuthenticated
				);
				setReports(data);
			}
			setIsLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterPK, type.current, quarter, year, isAuthenticated, renderValue]);

	var arryears = Array.from(Array(new Date().getFullYear() - 2019), (_, i) =>
		(i + 2020).toString()
	);
	let years = arryears.sort(function (a, b) {
		return b - a;
	});

	async function HandleYearChange(e) {
		setIsLoading(true);
		setYear(e.target.value);
		setFilterPK("Report#" + e.target.value + "/" + quarter + "#2");
		setInGroupEdit(false);
	}

	function HandleQuarterChange(e) {
		setIsLoading(true);
		setQuarter(e.target.value);
		setFilterPK("Report#" + year + "/" + e.target.value + "#2");
		setInGroupEdit(false);
	}
	function HandleTypeChange(e) {
		setIsLoading(true);
		type.current = e.target.value;
		setInGroupEdit(false);
	}

	function disabledCheck() {
		return isLoading || inGroupEdit || inOfficerEdit;
	}

	const reviewOfficerReportRow = (report) => {
		switch (report.Officer) {
			case "Treasurer":
				setCurrentOfficerReport({
					Id: report.Id,
					Officer: report.Officer,
					Date: report.Date,
					SubmitterName: report.SubmitterName,
					SubmitterEmail: report.SubmitterEmail,
					IncomeTotal: report.IncomeTotal,
					ExpenseTotal: report.ExpenseTotal,
					QuarterBalance: report.QuarterBalance,
					Detail: report.Detail,
				});
				break;
			default:
				setCurrentOfficerReport({
					Id: report.Id,
					Officer: report.Officer,
					Date: report.Date,
					SubmitterName: report.SubmitterName,
					SubmitterEmail: report.SubmitterEmail,
					Detail: report.Detail,
				});
				break;
		}
		setInOfficerEdit(true);
	};

	const reviewGroupReportRow = (report) => {
		//edit row
		setCurrentGroupReport({
			Id: report.Id,
			Group: report.Group,
			Date: report.Date,
			SubmitterName: report.SubmitterName,
			SubmitterEmail: report.SubmitterEmail,
			Detail: report.Detail,
			Donation: report.Donation,
		});
		setInGroupEdit(true);
	};

	const deletedReport = (id) => {
		setReports(reports.filter((newData) => newData.Id !== id));
	};

	const updateReports = () => {
		setRenderValue((renderValue) => renderValue + 1);
		window.scrollTo(0, 0);
	};

	function Reports() {
		if (type.current === "" || type.current === "-select report type-")
			return null;
		if (type.current === "Group Reports") {
			switch (inGroupEdit) {
				case true:
					return (
						<EditGroupReportForm
							PK={filterPK}
							currentGroupReport={currentGroupReport}
							setInGroupEdit={setInGroupEdit}
							updateReports={updateReports}
							deletedReport={deletedReport}
						/>
					);

				default:
					return (
						<GroupsReportsTable
							reports={reports}
							isLoading={isLoading}
							reviewGroupReportRow={reviewGroupReportRow}
							userIsAdmin={userContext.userIsAdmin}
						/>
					);
			}
		}

		if (type.current === "Officer Reports") {
			switch (inOfficerEdit) {
				case true:
					return (
						<EditOfficerReportForm
							PK={filterPK}
							currentOfficerReport={currentOfficerReport}
							setInOfficerEdit={setInOfficerEdit}
							updateReports={updateReports}
							deletedReport={deletedReport}
						/>
					);

				default:
					return (
						<OfficerReportsTable
							reports={reports}
							isLoading={isLoading}
							reviewOfficerReportRow={reviewOfficerReportRow}
							userIsAdmin={userContext.userIsAdmin}
						/>
					);
			}
		}
	}
	return (
		<>
			<div className='ReportsTable'>
				<div className='ntig'>
					<h2>NTIG Reports</h2>

					<h5>reporting period</h5>
				</div>
				<Form>
					<Row>
						<Form.Group className='mb-3' as={Col} controlId='year'>
							<Form.Label>Year</Form.Label>
							<Form.Control
								name='year'
								as='select'
								onChange={HandleYearChange}
								disabled={disabledCheck()}>
								{years.map((option) => {
									return (
										<option value={option} key={option}>
											{option}
										</option>
									);
								})}
							</Form.Control>
						</Form.Group>

						<Form.Group className='mb-3' as={Col} controlId='quarter'>
							<Form.Label>Quarter</Form.Label>
							<Form.Control
								name='quarter'
								as='select'
								onChange={HandleQuarterChange}
								disabled={disabledCheck()}
								value={quarter}>
								{quarters.map((option) => {
									return (
										<option value={option} key={option}>
											{option}
										</option>
									);
								})}
							</Form.Control>
						</Form.Group>
					</Row>

					<Form.Group className='mb-3' as={Col} controlId='type'>
						<Form.Label>Report type</Form.Label>
						<Form.Control
							name='type'
							as='select'
							onChange={HandleTypeChange}
							disabled={disabledCheck()}>
							{types.map((option) => {
								return (
									<option value={option} key={option}>
										{option}
									</option>
								);
							})}
						</Form.Control>
					</Form.Group>
				</Form>
			</div>
			{type.current === "" && type.current === "-select report type-" && (
				<ColouredLine color='grey' />
			)}
			<Reports />
		</>
	);
}
