import React, { useState, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import MainFooter from "./components/pageNav/footer";
import NavDrop from "./components/pageNav/NavDropdown";
import { Auth } from "@aws-amplify/auth";
import API from "@aws-amplify/api"
import awsconfig from "./aws-exports";
import Routing from "./routes";
import "./App.css";
import { AuthContext } from "./libs/context/authenticatedLib";
import { UserContext } from "./libs/context/userLib";
import { GroupsContext } from "./libs/context/groupsLib";
import { useNavigate } from "react-router-dom";
import { onError } from "./libs/userFeedback/errorLib";
import FadeIn from "react-fade-in";
import loadContext from "./components/functions/LoadContext"

Auth.configure(awsconfig)
API.configure(awsconfig)

function App() {
  const navigate = useNavigate();
  const [userContext, setUserContext] = useState(false);
  const [groupsContext, setGroupsContext] = useState(false);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    let user;
    try {
      user = await Auth.currentSession();
      userHasAuthenticated(true);
      const isAdmin = user.accessToken.payload['cognito:groups'].includes("ntig-admin-users");
      const isNtigExec = user.accessToken.payload['cognito:groups'].includes("ntig-exec-users");
      const isPortalUser = user.accessToken.payload['cognito:groups'].includes("ntig-groups-users");
      setUserContext({ user: user.idToken.payload.email, userName: user.idToken.payload.name, userIsAdmin: isAdmin, userIsNtigExec: isNtigExec, userIsPortalUser: isPortalUser });
      const loadGroupContext = await loadContext()
      setGroupsContext(loadGroupContext)
    } catch (e) {
      if (e.message !== "Refresh Token has expired" && e !== "No current user" && e !== "NotAuthorizedException: Refresh Token has expired") {
        onError(e);
      }
    }
    //console.log(JSON.stringify(apiGroups.data.getNTIG.FullGroups))
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    navigate("/"); //need to set this to the website homepage
  }

  return (
    !isAuthenticating && (
      <div className="App container py-3">
        {isAuthenticated ? (
          <UserContext.Provider value={{ userContext, setUserContext }}>
            <NavDrop handleLogout={handleLogout}></NavDrop>
          </UserContext.Provider>
        ) : (
          <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
            <LinkContainer to="/welcome">
              <Navbar.Brand className="font-weight-bold text-muted">NTIG Portal</Navbar.Brand>
            </LinkContainer>
            <Navbar.Collapse className="justify-content-end">
              <Nav activeKey={window.location.pathname}>
                <>
                  <LinkContainer to="/signup">
                    <Nav.Link>signup</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <Nav.Link>login</Nav.Link>
                  </LinkContainer>
                </>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}
        <AuthContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <UserContext.Provider value={{ userContext, setUserContext }}>
            <GroupsContext.Provider value={{ groupsContext, setGroupsContext }}>
              <FadeIn>
                <Routing />
              </FadeIn>
            </GroupsContext.Provider>
          </UserContext.Provider>
        </AuthContext.Provider>
        <MainFooter />
      </div>
    )
  );
}

export default App;