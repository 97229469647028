import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import ColouredLine from "../../components/shapes/ColouredLine";
import LoaderButton from "../../components/buttons/LoaderButton";
import getNtig from "../../API/appSync/ntig/getNtig";
import createNtig from "../../API/appSync/ntig/createNtig";
import notifySec from "../../API/appSync/ntig/notifySec";
import getDonations from "../../API/appSync/ntig/getDonations";
import IgTreasurerReportForm from "../../components/forms/IgTreasurerReport Form";
import EditIncomeForm from "../../components/forms/EditIncomeForm";
import EditExpenseForm from "../../components/forms/EditExpenseForm";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useRef, useState, useEffect } from "react";
import { useUserContext } from "../../libs/context/userLib";
import { useGroupsContext } from "../../libs/context/groupsLib";
import { useNavigate } from "react-router-dom";
import { onError } from "../../libs/userFeedback/errorLib";
//import LoaderButton from "../../components/buttons/LoaderButton";
import "./OfficerQtrlyReport.css";
import FadeIn from "react-fade-in";

export default function OfficerQtrlyReport() {
	const [startingBalance, setStartingBalance] = useState(0);
	const [editIncome, setEditIncome] = useState(false);
	const [incomeRenderValue, setIncomeRenderValue] = useState(0);
	const [savedIncomeRender, setSavedIncomeRender] = useState(false);
	const [incomeTotal, setIncomeTotal] = useState();
	const [editExpense, setEditExpense] = useState(false);
	var setExpenseRenderValue = 0;
	const [savedExpenseRender, setSavedExpenseRender] = useState(false);
	const [expenseTotal, setExpenseTotal] = useState(0);
	const navigate = useNavigate();
	const { userContext } = useUserContext();
	const { groupsContext } = useGroupsContext();
	const roles = [
		"-select your role-",
		"Test-role",
		"Chairperson",
		"Vice Chair",
		"Treasurer",
		"Secretary",
		"Public Information",
		"Probation",
		"Health",
		"Employment",
		"ECLO",
		"Telephone Liaison Officer",
		"YPLO",
		"Armed Forces",
		"Share Magazine",
		"Archivist",
		"Literature",
		"Regional Rep",
	];
	//const quarters = ["Q1", "Q2", "Q3", "Q4"];
	const makeDatestamp = new Date();
	const currentYear = makeDatestamp.getFullYear();
	const currentMonth = makeDatestamp.getMonth() + 1; //months start at 0
	const currentDay = makeDatestamp.getDate();
	const [isLoading, setIsLoading] = useState(false);
	const [reportDonations, setReportDonations] = useState([]); //income
	const [reportExpenses, setReportExpenses] = useState([]);
	const [reportYear, setReportYear] = useState(new Date().getFullYear());
	const reportingPeriod = useRef();

	useEffect(() => {
		(async () => {
			const data = await getNtig(
				"PortalAdmin#Secretary",
				"ReportingPeriod",
				"adminSecQtr"
			);
			reportingPeriod.current = data;
		})();
	}, []);

	const quarters = ["Q1", "Q2", "Q3", "Q4"];
	let currentQuarter = "";
	if (currentMonth <= 3) {
		currentQuarter = "Q1";
	} else if (currentMonth <= 6) {
		currentQuarter = "Q2";
	} else if (currentMonth <= 9) {
		currentQuarter = "Q3";
	} else {
		currentQuarter = "Q4";
	}

	const [reportQuarter, setReportQuarter] = useState(currentQuarter);
	const [filterVar, setFilterVar] = useState(reportYear + "/" + reportQuarter);

	var arryears = Array.from(Array(new Date().getFullYear() - 2019), (_, i) =>
		(i + 2020).toString()
	);
	var years = arryears.sort(function (a, b) {
		return b - a;
	});

	function sumIncome(data) {
		let donationsTotal = 0;
		for (let i in data) {
			donationsTotal += data[i].Donation;
		}
		setIncomeTotal(donationsTotal);
	}

	useEffect(() => {
		(async () => {
			const data = await getDonations(filterVar, groupsContext.FullGroups);
			setReportDonations(data);
			sumIncome(data);
			setIsLoading(false);
		})();
	}, [filterVar, groupsContext.FullGroups, incomeRenderValue]);

	useEffect(() => {
		var reIdIncome = reportDonations;
		for (let i in reIdIncome) {
			reIdIncome[i].Id = i;
		}
		setReportDonations(reIdIncome);
	}, [reportDonations]);

	useEffect(() => {
		var reIdExpense = reportExpenses;
		for (let i in reIdExpense) {
			reIdExpense[i].Id = i;
		}
		setReportExpenses(reIdExpense);
	}, [reportExpenses]);

	const reRenderIncome = (donation) => {
		setReportDonations(
			reportDonations.filter((newData) => newData.Id !== donation.Id)
		);
		setIncomeTotal((incomeTotal) => (incomeTotal -= donation.Donation));
	};

	const editIncomeCanx = () => {
		if (!savedIncomeRender) {
			setIncomeRenderValue((incomeRenderValue) => incomeRenderValue + 1);
		} else {
			setReportDonations(savedIncomeRender);
			let donationsTotal = 0;
			for (let i in savedIncomeRender) {
				donationsTotal += parseFloat(savedIncomeRender[i].Donation);
			}
			setIncomeTotal(donationsTotal);
		}
		setEditIncome(false);
		window.scrollTo(0, 0);
	};

	const reRenderExpense = (expense) => {
		setReportExpenses(
			reportExpenses.filter((newData) => newData.Id !== expense.Id)
		);
		setExpenseTotal((expenseTotal) => (expenseTotal -= expense.Donation));
	};

	const editExpenseCanx = () => {
		if (!savedExpenseRender) {
			setExpenseRenderValue((expenseRenderValue) => expenseRenderValue + 1);
		} else {
			setReportExpenses(savedExpenseRender);
			let expensesTotal = 0;
			for (let i in savedExpenseRender) {
				expensesTotal += parseFloat(savedExpenseRender[i].Expense);
			}
			setExpenseTotal(expensesTotal);
		}
		setEditExpense(false);
		window.scrollTo(0, 0);
	};

	async function HandleYearChange(e) {
		//setIsLoading(true);
		setReportYear(e.target.value);
		setFilterVar(e.target.value + "/" + reportQuarter);
	}

	function HandleQuarterChange(e) {
		//setIsLoading(true);
		setReportQuarter(e.target.value);
		setFilterVar(reportYear + "/" + e.target.value);
	}

	function validateNotEmpty() {
		return (
			formik.values.reportText.length > 0 &&
			formik.values.officer !== "-select your role-"
		);
	}

	//const amountRegex = /^\d+(\.\d{1,2})?$/;

	const validationSchema = object().shape({
		officer: string().test(
			"officerTest",
			"You must select a role",
			(officer) => officer !== "-select your role-"
		),
		reportText: string().required("Required"),
		// balance: string().when('officer', {
		//         is: 'Treasurer',
		//         then: string().required("Required").matches(amountRegex, "Enter a valid amount"),
		//         otherwise: string()
		// })
	});

	// reportText: string().required("Required"),
	//balance: string().required("Required").matches(amountRegex, "Enter a valid amount")
	// balance: string().when('officer', {
	//     is: 'Treasurer',
	//     then: string().required("Required").matches(amountRegex, "Enter a valid amount"),
	//     otherwise: string()
	// })

	const formik = useFormik({
		initialValues: {
			officer: "-select your role-",
			reportText: "",
			balance: "",
		},

		validationSchema,
		onSubmit: (values) => {
			setIsLoading(true);

			var datestamp = currentDay + "/" + currentMonth + "/" + currentYear;
			var submitter =
				'{"Email": "' +
				userContext.user +
				'","Name": "' +
				userContext.userName +
				'"}';

			var data = {
				PK: `Report#${reportingPeriod.current}#2`, //currentYear + "/" + currentQuarter + "#2",
				SK: "Officer#" + formik.values.officer,
				Date: datestamp,
				Submitter: submitter,
				Detail: formik.values.reportText,
			};
			if (formik.values.officer === "Treasurer") {
				var sendBal =
					parseFloat(startingBalance) +
					parseFloat(incomeTotal) -
					parseFloat(expenseTotal);
				data["Finance"] =
					'{"IncomeTotal":' +
					incomeTotal +
					', "ExpenseTotal": ' +
					expenseTotal +
					', "QuarterBalance": ' +
					sendBal +
					"}";
			}

			var secNotifyJson = {
				Type: "officerqtrcall",
				Officer: formik.values.officer,
				Date: datestamp,
				Period: reportingPeriod.current,
				Detail: formik.values.reportText,
				Submitter: submitter,
				CopyReport: true,
			};

			//notify secretary
			const sendToSec = async () => {
				await notifySec(secNotifyJson).then((notSec) => {
					if (notSec === true) {
						onError("Report submitted successfully");
						navigate("/");
					} else {
						onError(JSON.stringify(notSec.errors[0].errorType));
					}
				});
				//.catch((e) => { })
			};
			const reportSubmission = async () => {
				await createNtig(data).then((reportSub) => {
					if (reportSub === true) {
						sendToSec();
					} else {
						onError(JSON.stringify(reportSub.errors[0].errorType));
					}
				});
				//.catch((e) => { })
			};
			reportSubmission();
		},
	});
	useEffect(() => {
		if (formik.values.balance.isNaN) {
			setStartingBalance(0);
		} else {
			setStartingBalance(formik.values.balance);
		}
	}, [formik.values.balance]);

	function render() {
		return (
			<FadeIn transitionDuration="500">
				<div className="OfficerQtrlyReport">
					<h2>Your report</h2>
					<Form onSubmit={formik.handleSubmit}>
						<Form.Group controlId="officer">
							<Row>
								<Form.Label>Your Role</Form.Label>
								<Form.Control
									name="officer"
									as="select"
									value={formik.values.officer}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={formik.touched.officer && formik.errors.officer}>
									{roles.map((option) => {
										return (
											<option value={option} key={option}>
												{option}
											</option>
										);
									})}
								</Form.Control>
								<Form.Control.Feedback type="invalid">
									{formik.errors.officer}
								</Form.Control.Feedback>
							</Row>
						</Form.Group>

						<Form.Group controlId="name">
							<Row>
								<Form.Label>Your details</Form.Label>
								<Form.Control
									name="name"
									type="input"
									value={userContext.userName}
									readOnly></Form.Control>
							</Row>
						</Form.Group>

						<Form.Group controlId="email">
							<Row>
								<Form.Control
									name="email"
									type="input"
									value={userContext.user}
									readOnly></Form.Control>
							</Row>
						</Form.Group>

						<Form.Group controlId="reportText">
							<Row>
								<Form.Label>Your report</Form.Label>
								<Form.Control
									name="reportText"
									as="textarea"
									rows={8}
									placeholder="type the report"
									value={formik.values.reportText}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.reportText && formik.errors.reportText
									}></Form.Control>
								<Form.Control.Feedback type="invalid">
									{formik.errors.reportText}
								</Form.Control.Feedback>
							</Row>
						</Form.Group>
						<>
							{formik.values.officer === "Treasurer" && (
								<>
									<h2>finance reporting quarter</h2>
									<Row>
										<Form.Group as={Col} controlId="year">
											<Form.Label>Year</Form.Label>
											<Form.Control
												name="year"
												as="select"
												value={reportYear}
												onChange={HandleYearChange}
												disabled={isLoading}>
												{years.map((option) => {
													return (
														<option value={option} key={option}>
															{option}
														</option>
													);
												})}
											</Form.Control>
										</Form.Group>

										<Form.Group as={Col} controlId="quarter">
											<Form.Label>Quarter</Form.Label>
											<Form.Control
												name="quarter"
												as="select"
												value={reportQuarter}
												onChange={HandleQuarterChange}
												disabled={isLoading}>
												{quarters.map((option) => {
													return (
														<option value={option} key={option}>
															{option}
														</option>
													);
												})}
											</Form.Control>
										</Form.Group>
									</Row>
									<br />
									<h5>Starting Balance</h5>
									<Row>
										<InputGroup>
											<InputGroup.Text id="basic-addon1">£</InputGroup.Text>
											<Form.Control
												name="balance"
												type="float"
												placeholder="amount"
												value={formik.values.balance}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												isInvalid={
													formik.touched.balance && formik.errors.balance
												}></Form.Control>
											<Form.Control.Feedback type="invalid">
												{formik.errors.balance}
											</Form.Control.Feedback>
										</InputGroup>
									</Row>
									<IgTreasurerReportForm
										reportPeriod={filterVar}
										reportDonations={reportDonations}
										setReportDonations={setReportDonations}
										editIncome={editIncome}
										setEditIncome={setEditIncome}
										incomeTotal={incomeTotal}
										reRenderIncome={reRenderIncome}
										reportExpenses={reportExpenses}
										setReportExpenses={setReportExpenses}
										setSavedExpenseRender={setSavedExpenseRender}
										reRenderExpense={reRenderExpense}
										editExpense={editExpense}
										setEditExpense={setEditExpense}
										expenseTotal={expenseTotal}
										setExpenseTotal={setExpenseTotal}
										editExpenseCanx={editExpenseCanx}
										startingBalance={startingBalance}
									/>
								</>
							)}
							<ColouredLine />
							<Row>
								<LoaderButton
									size="lg"
									type="submit"
									variant="success"
									isLoading={isLoading}
									disabled={!validateNotEmpty()}>
									{/* <LoaderButton size="lg" type="submit" variant="secondary" isLoading={isLoading} disabled> */}
									submit report
								</LoaderButton>
							</Row>
						</>
					</Form>
				</div>
			</FadeIn>
		);
	}
	return (
		<>
			{editIncome && (
				<EditIncomeForm
					reportDonations={reportDonations}
					setReportDonations={setReportDonations}
					setSavedIncomeRender={setSavedIncomeRender}
					reRenderIncome={reRenderIncome}
					editIncome={editIncome}
					setEditIncome={setEditIncome}
					incomeTotal={incomeTotal}
					setIncomeTotal={setIncomeTotal}
					editIncomeCanx={editIncomeCanx}
				/>
			)}
			{editExpense && (
				<EditExpenseForm
					reportExpenses={reportExpenses}
					setReportExpenses={setReportExpenses}
					setSavedExpenseRender={setSavedExpenseRender}
					reRenderExpense={reRenderExpense}
					editExpense={editExpense}
					setEditExpense={setEditExpense}
					expenseTotal={expenseTotal}
					setExpenseTotal={setExpenseTotal}
					editExpenseCanx={editExpenseCanx}
				/>
			)}
			{!editIncome && !editExpense && render()}
		</>
	);
}
